import { getQueryVariable } from "@utils/";
import {
  authenticateCafOperator,
  redirectCafClientAfterAuthRedirect,
  validateCafClientAccess,
} from "@utils/auth/authentication";
import { authenticationUtils } from "@utils/auth/utils";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

const { AUTHENTICATION_COOKIES_OPTIONS } = authenticationUtils;

function CustomAuthRouter({ pathname = "", children }) {
  const historyCustomRouter = useHistory();

  const [templateId, tenantId] = ["templateId", "tenantId"].map((param) => getQueryVariable(param));
  // Bifrost token utilities
  const bifrostCode = getQueryVariable("code");
  const bifrostToken = Cookies.get(AUTHENTICATION_COOKIES_OPTIONS.BIFROST_ID_TOKEN);

  // Auth token utilities
  const authToken = Cookies.get(AUTHENTICATION_COOKIES_OPTIONS.AUTH_ID_TOKEN);
  const authUrl = `${process.env.REACT_APP_BASE_URL_AUTH_API}users/me`;

  if (!!authToken && !bifrostToken && !!tenantId) {
    validateCafClientAccess({ authToken, tenantId, history: historyCustomRouter });
  }

  if (!!bifrostCode && pathname === "auth/login") {
    authenticateCafOperator({ code: bifrostCode, history: historyCustomRouter });
    return;
  }

  if (!tenantId && !templateId && !!authToken && !bifrostToken && pathname === "auth/login") {
    redirectCafClientAfterAuthRedirect({ authToken, authUrl, history: historyCustomRouter });
    return;
  }

  return <>{children}</>;
}

export default CustomAuthRouter;
