import { LoadingOutlined } from "@ant-design/icons";
import { Button as CafButton, Icon, Tag } from "@combateafraude/react";
import ConditionalLink from "@components/ConditionalLink";
import Loader from "@components/Loader";
import { TemplateCreateOnboardingBody, TemplateImportExportBody, TemplatePropertiesBody } from "@components/Modal";
import { useAnalytics } from "@contexts/Analytics";
import { useAPIContext } from "@contexts/API";
import { useLoader } from "@contexts/Loader";
import { useModal } from "@contexts/Modal";
import useAPI, { requestMethods } from "@hooks/useAPI";
import { getQueryVariable, scrollToElement } from "@utils/";
import { onboardingTemplatesAvailableLanguages } from "@utils/internationalization";
import { tagsInfo } from "@utils/tags";
import { Col, Empty, Input, Pagination, Row, Select, Spin, Tooltip } from "antd";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const I18N_BASE_PATH = "src.pages.onboarding.templateList";

const { Option } = Select;

const TemplateElement = ({ tenantId, template, onDuplicateTemplate, onDeleteTemplate, setTemplatesShouldUpdate }) => {
  const { t, i18n } = useTranslation();

  const [updateTemplateRequest] = useAPI();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const openPropertiesModal = useModal();
  const openTemplateImportExportModal = useModal();

  const onboardingLanguage = useMemo(
    () => onboardingTemplatesAvailableLanguages?.find((language) => language?.id === template?.language),
    [template?.language],
  );

  const duplicateTemplate = useCallback(
    () => onDuplicateTemplate(template._id, i18n?.language),
    [template, onDuplicateTemplate],
  );
  const deleteTemplate = useCallback(() => onDeleteTemplate(template._id), [template, onDeleteTemplate]);

  const updateTemplate = async (values) => {
    if (values) {
      let res = await updateTemplateRequest(
        `/onboarding-builder/templates/${template._id}?tenantId=${tenantId}`,
        requestMethods.PATCH,
        values,
      );

      if (res) setTemplatesShouldUpdate(true);

      return res;
    }
  };

  return (
    <div key={template._id} className="relative w-full cursor-pointer group" onMouseLeave={() => setIsMenuOpen(false)}>
      {/* TODO: Swap for "FloatingMenu" component */}
      <span
        className={classNames(
          "w-8 h-8 bg-gray-100 rounded-full flex items-center justify-center absolute top-3 right-3 transition-all opacity-0 group-hover:opacity-100 hover:bg-gray-300 z-10",
          { "bg-gray-300": isMenuOpen },
        )}
        onClick={(e) => setIsMenuOpen((old) => !old)}
      >
        <Icon icon="more-horizontal" />
      </span>
      <div
        className={classNames(
          "modal absolute top-12 right-3 flex flex-col py-3 space-y-2 bg-white rounded-lg transition-all opacity-0 cursor-auto shadow-md z-10",
          { "pointer-events-none": !isMenuOpen },
          { "opacity-100": isMenuOpen },
        )}
      >
        <ConditionalLink to={`?tenantId=${tenantId}&templateId=${template._id}`} isExternal>
          <CafButton type="text" block className="!text-left !bg-transparent !shadow-none">
            <Icon icon="news" /> {t(`${I18N_BASE_PATH}.templateElement.components.options.design`, "Design")}
          </CafButton>
        </ConditionalLink>
        <CafButton
          type="text"
          block
          className="!text-left !bg-transparent !shadow-none"
          onClick={() =>
            openPropertiesModal(
              t("general.label.properties", "Propriedades"),
              <TemplatePropertiesBody
                tenantId={tenantId}
                defaultValues={{ ...template }}
                handleFinish={updateTemplate}
              />,
            )
          }
        >
          <Icon icon="settings" /> {t("general.label.properties", "Propriedades")}
        </CafButton>
        <CafButton
          type="text"
          block
          className="!text-left !bg-transparent !shadow-none"
          onClick={() =>
            openTemplateImportExportModal(
              t("general.label.templateImportExportModalText", "Importar/exportar configurações"),
              <TemplateImportExportBody
                atTemplateList
                _templateId={template?._id}
                setTemplatesShouldUpdate={setTemplatesShouldUpdate}
              />,
            )
          }
        >
          <Icon icon="download" />{" "}
          {t(`${I18N_BASE_PATH}.templateElement.components.options.importExportSettings`, "Importar/Exportar")}
        </CafButton>
        <hr />
        <CafButton type="text" block className="!text-left !bg-transparent !shadow-none" onClick={duplicateTemplate}>
          <Icon icon="duplicate" /> {t("general.label.duplicate", "Duplicar")}
        </CafButton>
        <hr />
        <CafButton type="text" block danger className="!text-left !shadow-none" onClick={deleteTemplate}>
          <Icon icon="trash" /> {t("general.label.remove", "Excluir")}
        </CafButton>
      </div>
      <ConditionalLink to={`?tenantId=${tenantId}&templateId=${template._id}`} isExternal>
        <div className="flex flex-col w-full space-y-2">
          <div className="relative flex justify-center items-center w-full h-64 bg-transparent rounded-md border-2 border-gray-200 transition-all group-hover:border-caf-primary overflow-hidden">
            {template?.theme?.image && (
              <img
                className="w-full h-full object-cover p-2"
                src={template?.theme?.image}
                alt={template?.theme?.name}
              />
            )}
          </div>

          <div className="flex items-center">
            <img
              src={template.favicon || "favicon.png"}
              className="h-6 w-6 object-fill mr-2 rounded-md"
              alt={template.name}
            />
            <span className="font-bold mr-2">{template.name}</span>
            <div className="!ml-auto flex flex-row items-center justify-center gap-2">
              <img
                title={`${t("general.label.language", "Idioma")} ${t(
                  onboardingLanguage?.name,
                  onboardingLanguage?.name,
                )}`}
                className="w-6 rounded-full"
                src={onboardingLanguage?.flag}
                alt={`${t("general.label.language", "Idioma")} ${t(
                  onboardingLanguage?.name,
                  onboardingLanguage?.name,
                )}`}
              />
              {template.type && (
                <Tooltip title={t(`onboardingProps.tags.${template.type}`, template.type)}>
                  <Tag
                    key={`${template.name}}_TAG_${template.type}`}
                    color={tagsInfo[template.type]?.color}
                    icon={tagsInfo[template.type]?.icon}
                    className="whitespace-nowrap"
                  />
                </Tooltip>
              )}
            </div>
          </div>
          <span className="text-sm">{template.description}</span>
        </div>
      </ConditionalLink>
    </div>
  );
};

const TemplateList = ({ tenantId }) => {
  const { t } = useTranslation();

  const { language, featureFlags } = useAPIContext();
  const { logAnalyticsTracking, analyticsEvents, analytics } = useAnalytics();

  const [startLoader, stopLoader, isLoaderLoading] = useLoader();
  const [getTemplatesRequest, getTemplatesLoading] = useAPI();
  const [getThemeRequest] = useAPI();
  const [createTemplateRequest, createTemplateLoading] = useAPI();
  const [duplicateTemplateRequest, duplicateTemplateLoading] = useAPI();
  const [deleteTemplateRequest, deleteTemplateLoading] = useAPI();

  const [templates, setTemplates] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 12, total: 0 });
  const [templatesShouldUpdate, setTemplatesShouldUpdate] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [typeFilter, setTypeFilter] = useState("ALL");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const debounceDelay = 500; // Tempo de espera para o debounce

  // Debounce: Aguarda o tempo definido para atualizar o termo de busca
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, debounceDelay);

    return () => {
      clearTimeout(handler); // Limpa o timeout ao mudar o searchTerm
    };
  }, [searchTerm]);

  // Atualiza a URL e redefine a página ao buscar
  useEffect(() => {
    setPagination((oldState) => ({
      ...oldState,
      current: 1, // Volta para a página 1
    }));

    setTemplatesShouldUpdate(true); // Dispara a atualização da lista
  }, [debouncedSearchTerm, typeFilter]);

  // Carrega os templates da página atual
  const fetchOnboardingTemplates = useCallback(
    async (current, hasToScrollToTop) => {
      if (hasToScrollToTop) scrollToElement("onboarding-templates-list");

      const pageSize = pagination?.pageSize || 10;

      const params = new URLSearchParams({
        tenantId,
        _limit: pageSize,
        _offset: pageSize * (current - 1),
      });

      if (debouncedSearchTerm) {
        params.append("_search", debouncedSearchTerm);
      }

      if (typeFilter !== "ALL") {
        params.append("_type", typeFilter);
      }

      const url = `/onboarding-builder/templates?${params.toString()}`;

      let newTemplates = await getTemplatesRequest(url);

      if (newTemplates?.data) setTemplates(newTemplates.data);

      setPagination((oldState) => ({
        ...oldState,
        current,
        pageSize,
        total: newTemplates?.totalItems,
      }));
    },
    [pagination, tenantId, getTemplatesRequest, debouncedSearchTerm, typeFilter],
  );

  useEffect(() => {
    logAnalyticsTracking(analyticsEvents.LIST_TEMPLATES);
  }, [analytics]);

  useEffect(() => {
    if (templatesShouldUpdate) {
      fetchOnboardingTemplates(pagination?.current || 1, false);
      setTemplatesShouldUpdate(false);
    }
  }, [templatesShouldUpdate]);

  // Gerencia o estado do Loader
  useEffect(() => {
    if (getTemplatesLoading && !searchTerm && !typeFilter) startLoader();
    else if (createTemplateLoading) startLoader(t("general.label.creatingTemplate", "Criando template..."));
    else if (duplicateTemplateLoading) startLoader(t("general.label.duplicatingTemplate", "Duplicando template..."));
    else if (deleteTemplateLoading) startLoader(t("general.label.removingTemplate", "Deletando template..."));
    else stopLoader(500);
  }, [getTemplatesLoading, createTemplateLoading, duplicateTemplateLoading, deleteTemplateLoading]);

  // HANDLERS
  const onCreateTemplate = async (onboardingData) => {
    const { theme } = onboardingData;

    let resultTheme = await getThemeRequest(
      `/onboarding-builder/themes/${theme}${featureFlags?.iProovPFL ? "?useIproovSteps=true" : ""}`,
      requestMethods.GET,
    );

    if (onboardingData?.name) resultTheme.theme.templateDefault.name = onboardingData?.name?.trim();

    let result = await createTemplateRequest(
      `/onboarding-builder/templates?tenantId=${tenantId}`,
      requestMethods.POST,
      resultTheme?.theme?.templateDefault,
    );

    if (result) {
      if (!!getQueryVariable("noMargin")) {
        setTemplatesShouldUpdate(true);
      } else {
        window.open(`/onboarding?tenantId=${tenantId}&templateId=${result?.templateId}`, "_blank");
        window.location.reload(true);
      }
    }
  };

  const onDuplicateTemplate = async (templateId, language = "pt-BR") => {
    let result = await duplicateTemplateRequest(
      `/onboarding-builder/templates/${templateId}?tenantId=${tenantId}&language=${language}`,
      requestMethods.POST,
    );

    if (result) setTemplatesShouldUpdate(true);
  };

  const onDeleteTemplate = async (templateId) => {
    let result = await deleteTemplateRequest(
      `/onboarding-builder/templates/${templateId}?tenantId=${tenantId}`,
      requestMethods.DELETE,
    );

    if (result) setTemplatesShouldUpdate(true);
  };

  const openTemplateTypeModal = useModal();

  if (!language) {
    return <Loader isHidden={false} />;
  }

  return (
    <div
      className={`caf-components relative flex flex-col w-full h-full min-h-screen ${
        getQueryVariable("noMargin") ? "" : "bg-transparent p-8"
      }`}
      id="onboarding-templates-list"
    >
      <div className="flex justify-between w-full mb-8">
        <div className="flex justify-between w-full mb-8">
          <Col className="flex flex-col mr-4">
            <h1 className="text-3xl font-black">
              {t(`${I18N_BASE_PATH}.templateList.components.heading`, "Templates de Onboarding")}
            </h1>
            <span>
              {t(
                `${I18N_BASE_PATH}.templateList.components.paragraph`,
                "Crie templates para seus links de compartilhamento",
              )}
            </span>
          </Col>
          <Row>
            <Col className="mr-4 mt-2">
              <Select
                defaultValue={"ALL"}
                // bordas removidas para manter o padrão do design
                bordered={false}
                className="!w-32 !h-10 border border-gray-300 !rounded-lg hover:border-caf-primary content-center"
                size="middle"
                onChange={(value) => {
                  setTypeFilter(value);
                }}
              >
                {["ALL", "PJ", "PF"].map((opt) => (
                  <Option key={opt} value={opt}>
                    {t(`general.label.typeFilter.${opt}`, opt)}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col className="mr-4 mt-2">
              <Input
                prefix={<Icon icon="search" size="sm" />}
                placeholder={t(`general.label.buttonSearch`, "Buscar templates")}
                size="large"
                className="!w-64 !h-10"
                allowClear
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Col>
            <Col className="mt-2">
              <CafButton
                className="!h-10"
                type="primary"
                outlined
                loading={isLoaderLoading}
                onClick={() =>
                  openTemplateTypeModal(
                    undefined,
                    <TemplateCreateOnboardingBody handleFinish={onCreateTemplate} />,
                    false,
                    "!max-w-modal-create-onboarding !w-modal-create-onboarding",
                  )
                }
                icon={<Icon icon="add" />}
              >
                {t("general.label.buttonCreateTemplate", "Criar template")}
              </CafButton>
            </Col>
          </Row>
        </div>
      </div>
      <Spin spinning={getTemplatesLoading} fullscreen indicator={<LoadingOutlined style={{ fontSize: 80 }} spin />}>
        {templates?.length === 0 ? (
          <Empty
            description={t("general.label.noData", "Não encontramos resultados")}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="!w-100 !h-100"
          />
        ) : (
          <div
            className={`relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-12 min-h-56 w-full ${
              pagination?.total > 12 && "mb-12"
            }`}
          >
            {templates?.map((template, i) => (
              <TemplateElement
                key={i}
                {...{
                  tenantId,
                  template,
                  onDuplicateTemplate,
                  onDeleteTemplate,
                  setTemplatesShouldUpdate,
                }}
              />
            ))}
          </div>
        )}
      </Spin>
      {pagination?.total > 12 && (
        <div className="absolute bottom-4 self-center">
          <Pagination
            total={pagination?.total}
            pageSize={pagination?.pageSize}
            current={pagination?.current}
            onChange={(page) => fetchOnboardingTemplates(page, true)}
            showSizeChanger={false}
            disabled={getTemplatesLoading}
          />
        </div>
      )}
    </div>
  );
};

export default TemplateList;
