import classNames from "classnames";

export const SuspenseMenu = ({ isOpen, onClose, children }) => (
  <div className="relative w-full cursor-pointer group" onMouseLeave={onClose}>
    <div
      className={classNames(
        "modal absolute right-1 flex flex-col py-3 space-y-2 bg-white rounded-lg transition-all opacity-0 cursor-auto shadow-md z-10",
        { "pointer-events-none": !isOpen },
        { "opacity-100": isOpen },
      )}
    >
      {children}
    </div>
  </div>
);
