import { Icon } from "@combateafraude/react";
import { useModalContext } from "@contexts/Modal";
import { Button as AntButton } from "antd";
import React, { useEffect, useState } from "react";

const Modal = ({ title, children, showCloseButton = true, modalClassName = undefined }) => {
  // Used to show and hide the modal with a fade
  const [isHidden, setIsHidden] = useState(true);
  const { setOpenModals } = useModalContext();

  useEffect(() => {
    setTimeout(() => setIsHidden(false), 1);
  }, []);

  useEffect(() => {
    if (!isHidden) document.body.classList.add("openModal");
    else document.body.classList.remove("openModal");
  }, [isHidden]);

  const close = () => {
    setIsHidden(true);
    setTimeout(() => setOpenModals((old) => old.filter((openModal) => openModal.title !== title)), [150]);
  };

  return (
    <div className="z-1100 modal fixed left-0 top-0 w-screen h-screen flex items-center justify-center">
      {/* BACKDROP */}
      <span
        className={`z-1100 absolute w-screen h-screen bg-black transition-all ${isHidden ? "opacity-0" : "opacity-30"}`}
        onClick={close}
      />
      {/* MODAL */}
      <div
        className={`z-1100 transition-all w-modal max-w-modal ${modalClassName} bg-white rounded-md p-6 transform overflow-y-auto max-h-19/20 ${
          isHidden ? "opacity-0 scale-75" : "opacity-100 scale-100"
        } `}
      >
        {(showCloseButton || !!title) && (
          <div className="flex justify-between w-full pb-4">
            {!!title && <h1 className="text-xl font-bold">{title}</h1>}
            <AntButton
              className="ml-auto"
              type="text"
              onClick={close}
              shape="circle"
              icon={<Icon icon="close" size="sm" />}
            />
          </div>
        )}

        <div className="relative flex-grow overflow-y-auto overflow-x-hidden">
          {React.cloneElement(children, { close })}
        </div>
      </div>
    </div>
  );
};
export default Modal;
