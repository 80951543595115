import { createContext, useContext, useState } from "react";

import defaultValues from "./defaultValues";

export const OnboardingPropsContext = createContext(defaultValues);

export const useOnboardingPropsContext = () => useContext(OnboardingPropsContext);

export const OnboardingPropsProvider = ({ children }) => {
  const [activeError, setActiveError] = useState(defaultValues.activeError);
  const [mode, setMode] = useState(defaultValues.mode);
  const [zoom, setZoom] = useState(defaultValues.zoom);

  return (
    <OnboardingPropsContext.Provider
      value={{
        activeError,
        setActiveError,
        mode,
        setMode,
        zoom,
        setZoom,
      }}
    >
      {children}
    </OnboardingPropsContext.Provider>
  );
};
