import { Select } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const I18N_BASE_PATH = "src.components.inputs.selectMultipleInput";

/** Multiple select with a required option */
const SelectMultipleInput = ({
  inputValue,
  onInputChange,
  valueKey,
  labelKey,
  options,
  // Will filter for itens with a "required" attribute
  filterRequired,
}) => {
  const { t } = useTranslation();

  return (
    <Select
      mode="multiple"
      value={inputValue?.filter((v) => !filterRequired || v["required"]).map((v) => (valueKey ? v[valueKey] : v))}
      onChange={(v) => {
        if (!valueKey) {
          onInputChange(v);
        } else {
          if (!filterRequired) {
            onInputChange(v.map((v) => ({ ...inputValue?.find((inputV) => inputV[valueKey] === v), [valueKey]: v })));
          } else {
            let newValue = inputValue?.map((inputV) =>
              v.includes(inputV[valueKey]) ? { ...inputV, required: true } : { ...inputV, required: false },
            );
            // Adds new fields added in required
            newValue.push(
              ...v
                .filter((_v) => !inputValue.map((inputV) => inputV[valueKey]).includes(_v))
                .map((_v) => ({ [valueKey]: _v, required: true })),
            );
            onInputChange(newValue);
          }
        }
      }}
      className="min-w-80"
      placeholder={t(`${I18N_BASE_PATH}.components.placeholder`, "Adicione campos...")}
    >
      {options?.map((option, i) => (
        <Option
          value={valueKey ? option[valueKey] : option}
          key={`OPTION_${valueKey ? option[valueKey] : option}_${i}`}
        >
          {labelKey
            ? t(String(option[labelKey]), option[labelKey])
            : valueKey
            ? t(String(option[valueKey]), option[valueKey])
            : t(String(option), option)}
        </Option>
      ))}
    </Select>
  );
};

export default SelectMultipleInput;
