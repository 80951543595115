import OnboardingInput from "@components/Inputs/OnboardingInput";
import { Collapse, Form } from "antd";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

export const DropDown = ({ name, options, stepIndex }) => {
  const { t } = useTranslation();

  return (
    <Form layout="vertical">
      <Collapse expandIconPosition="right" ghost className="!bg-transparent !border-0">
        <Panel className="text-base font-bold !border-0" header={t(name, name)} key={name}>
          <div className="flex flex-col">
            {options?.map((option, index) => (
              <Form.Item label={t(option.label, option.label)} tooltip={t(option.tooltip, option.tooltip)}>
                <OnboardingInput.TextArea key={index} stepIndex={stepIndex} {...option} />
              </Form.Item>
            ))}
          </div>
        </Panel>
      </Collapse>
    </Form>
  );
};
