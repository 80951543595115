const defaultEmailTemplate = {
  name: "New email template",
  description: "Template used in the onboarding link that will be sent by email.",
  template: `<html lang="en-US">

<head>
  <meta charset="UTF-8">
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;900&amp;display=swap">
  <style type="text/css">
    h1 {
      font-size: 32px;
      margin: 20px 0 0 0;
    }

    p {
      font-size: 16px;
      margin: 26px 0;
    }

    a.button {
      text-decoration: none;
      color: white;
      background: #004af7;
      padding: 16px 20px;
      border-radius: 5px;
      font-weight: bold;
      font-size: 18px;
      transition: opacity 200ms ease;
    }

    a.button:hover {
      opacity: 0.75;
    }
  </style>
  <title></title>
</head>

<body style="font-family: Roboto; background: white; color: black; line-height: 20px;">
  <div style="width: 100%; max-width: 500px; height: auto; margin: 0 auto; text-align: center;">
    <img src="https://s3.amazonaws.com/repo.combateafraude.com/web-components-assets/images/logo_caf.png" height="50px" style="margin: 20px auto;" />
    <div style="border: 1px solid #ddd; border-radius: 12px; overflow: hidden; padding: 20px 20px 40px 20px;">
      <h1>Verify your identity</h1>
      <p>To proceed with your onboarding, we need you to provide a few personal information. It will be super fast!</p>
      <div style="margin-top: 40px;">
        <a class="button" href="{{onboardingLink}}" title=Proceed>Proceed</a>
      </div>
    </div>
    <div style="margin-top: 20px;">
      <small style="color: #999; line-height: 18px;">2022 © CAF<br>
        <a href="https://www.caf.io/" target="_blank">caf.io</a>
      </small>
    </div>
  </div>
</body>

</html>`,
};

// gif retirado <img src="https://s3.amazonaws.com/repo.combateafraude.com/web-components-assets/gifs/verify-identity.gif" width="260px">
export default defaultEmailTemplate;
