const defaultValues = {
  unselectAllElements: () => {},
  selectCustomizableElement: (customizableElementName) => {},
  selectStep: () => {},
  selectedCustomizableElementName: undefined,
  selectedCustomizableElementPath: undefined,
  selectedCustomizableElement: undefined,
  selectedStep: undefined,
  selectedStepIndex: undefined,
  selectedError: undefined,
  focusedCustomizableElement: undefined,
  setFocusedCustomizableElement: (customizableElementName) => {},
  inputTypeSelected: undefined,
  setInputTypeSelected: (value) => {},
};
Object.freeze(defaultValues);

export default defaultValues;
