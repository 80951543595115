const defaultValues = {
  isFetching: false,
  templateId: "",
  setTemplateId: (templateId = "") => {},
  tenantId: "",
  setTenantId: (tenantId = "") => {},
  language: "",
  setLanguage: (language = "") => {},
  getTemplates: () => {},
  createTemplate: (template) => {},
  duplicateTemplate: (templateId = "") => {},
  deleteTemplate: (templateId = "") => {},
  updateTemplate: (templateId = "", template) => {},
  getTemplate: (templateId = "") => {},
  featureFlags: {},
};
Object.freeze(defaultValues);

export default defaultValues;
