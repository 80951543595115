import { onboardingInputTypes } from "@components/Inputs/OnboardingInput";
import { previewModes } from "@components/ObjectListEditor";
import Onboarding from "@package/steps";
import { onboardingPropsParserTranslator } from "@utils/translator";

const { defaultOnboardingValues } = Onboarding;

const I18N_BASE_PATH = "src.components.elementEditor.customizableComponents";

const renderCustomizableComponents = (language) => {
  const customizableComponents = {
    COMPONENT_TEXT: {
      name: `${I18N_BASE_PATH}.general.text`,
      // => Texto
      customizables: [
        {
          property: "props.default.text",
          label: `${I18N_BASE_PATH}.general.text`,
          // => Texto
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
        },
        {
          property: "styles.default.color",
          label: `${I18N_BASE_PATH}.general.color`,
          // => Cor
          defaultValue: "#FFFFFF",
          type: onboardingInputTypes.COLOR,
        },
      ],
    },
    COMPONENT_TITLE: {
      name: `${I18N_BASE_PATH}.general.title`,
      // => Título
      customizables: [
        {
          property: "props.default.text",
          label: `${I18N_BASE_PATH}.general.title`,
          // => Título
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
        },
        {
          property: "styles.default.color",
          label: `${I18N_BASE_PATH}.general.color`,
          // => Cor
          defaultValue: "#FFFFFF",
          type: onboardingInputTypes.COLOR,
        },
      ],
    },
    COMPONENT_IMAGE: {
      name: `${I18N_BASE_PATH}.general.image`,
      // => Imagem
      customizables: [
        {
          property: "props.default.src",
          label: `${I18N_BASE_PATH}.general.image`,
          type: onboardingInputTypes.IMAGE,
        },
      ],
    },
    COMPONENT_ICON: {
      name: `${I18N_BASE_PATH}.general.icon`,
      // => Ícone
      customizables: [
        {
          property: "props.default.icon",
          label: `${I18N_BASE_PATH}.general.icon`,
          // => Ícone,
          type: onboardingInputTypes.SELECT_ICONS,
        },
      ],
    },
    COMPONENT_BUTTON: {
      name: `${I18N_BASE_PATH}.COMPONENT_BUTTON.name`,
      // => Botão
      customizables: [
        {
          property: "props.default.text",
          label: `${I18N_BASE_PATH}.general.text`,
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
        },
        {
          property: "styles.default.color",
          label: `${I18N_BASE_PATH}.general.textColor`,
          // => Cor do texto
          defaultValue: "#FFFFFF",
          type: onboardingInputTypes.COLOR,
        },
        {
          property: "styles.default.backgroundColor",
          label: `${I18N_BASE_PATH}.COMPONENT_BUTTON.customizables.backgroundColor.label`,
          // => Cor de fundo
          defaultValue: "#FFFFFF",
          type: onboardingInputTypes.COLOR,
        },
      ],
    },
    "COMPONENT_BUTTON-ICON": {
      name: `${I18N_BASE_PATH}.COMPONENT_BUTTON-ICON.name`,
      // => Botão com texto e ícone
      customizables: [
        {
          property: "props.default.text",
          label: `${I18N_BASE_PATH}.general.text`,
          // => Texto
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
        },
        {
          property: "props.default.textColor",
          label: `${I18N_BASE_PATH}.general.textColor`,
          // => Cor do texto
          defaultValue: "#FFFFFF",
          type: onboardingInputTypes.COLOR,
        },
        {
          property: "props.default.iconColor",
          label: `${I18N_BASE_PATH}.general.iconColor`,
          // => Cor do ícone
          defaultValue: "#FFFFFF",
          type: onboardingInputTypes.COLOR,
        },
        {
          property: "props.default.backgroundColor",
          label: `${I18N_BASE_PATH}.general.iconBackgroundColor`,
          // => Cor de fundo do ícone
          defaultValue: "#000000",
          type: onboardingInputTypes.COLOR,
        },
      ],
    },
    COMPONENT_CARD: {
      name: `${I18N_BASE_PATH}.COMPONENT_CARD.name`,
      // => Card
      customizables: [
        {
          property: "props.default.header.text",
          label: `${I18N_BASE_PATH}.COMPONENT_CARD.customizables.textHeader.label`,
          // => Texto do cabeçalho
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
        },
        {
          property: "props.default.header.backgroundColor",
          label: `${I18N_BASE_PATH}.COMPONENT_CARD.customizables.backgroundHeaderColor.label`,
          // => Cor de fundo do cabeçalho
          type: onboardingInputTypes.COLOR,
        },
        {
          property: "props.default.header.color",
          label: `${I18N_BASE_PATH}.COMPONENT_CARD.customizables.textHeaderColor.label`,
          // => Cor do texto do cabeçalho
          defaultValue: "#FFFFFF",
          type: onboardingInputTypes.COLOR,
        },
      ],
    },
    COMPONENT_ERRORS: {
      name: `${I18N_BASE_PATH}.COMPONENT_ERRORS.name`,
      // => Erros
      customizables: [
        {
          type: onboardingInputTypes.ERRORS_LIST,
          fields: [
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              label: `${I18N_BASE_PATH}.general.message`,
              // => Mensagem
              property: "text",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              label: `${I18N_BASE_PATH}.general.title`,
              // => Título
              property: "heading",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              label: `${I18N_BASE_PATH}.general.subtitle`,
              // => Subtítulo
              property: "subheading",
            },
            {
              type: onboardingInputTypes.IMAGE,
              label: `${I18N_BASE_PATH}.general.illustration`,
              // => Ilustração
              property: "illustration",
            },
          ],
        },
      ],
    },
    COMPONENT_POPUP: {
      name: `${I18N_BASE_PATH}.COMPONENT_POPUP.name`,
      // => Popup
      customizables: [
        {
          property: "props.default.buttonBackgroundColor",
          label: `${I18N_BASE_PATH}.COMPONENT_POPUP.customizables.buttonBackgroundColor.label`,
          // => Cor de fundo do botão
          type: onboardingInputTypes.COLOR,
        },
      ],
    },
    COMPONENT_LIST: {
      name: `${I18N_BASE_PATH}.COMPONENT_LIST.name`,
      // => Lista
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          property: "props.default.list",
          objectName: `${I18N_BASE_PATH}.COMPONENT_LIST.customizables.list.objectName`,
          // => item
          fields: [
            {
              type: onboardingInputTypes.SELECT_ICONS,
              previewMode: previewModes.ICON,
              label: `${I18N_BASE_PATH}.general.icon`,
              // => Ícone
              property: "icon",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.text`,
              // => Texto
              property: "text",
            },
          ],
        },
        {
          property: "props.default.textColor",
          label: `${I18N_BASE_PATH}.general.textColor`,
          // => Cor do texto
          defaultValue: "#FFFFFF",
          type: onboardingInputTypes.COLOR,
        },
        {
          property: "props.default.iconColor",
          label: `${I18N_BASE_PATH}.general.iconColor`,
          // => Cor do ícone
          defaultValue: "#00AE85",
          type: onboardingInputTypes.COLOR,
        },
        {
          property: "props.default.iconBackgroundColor",
          label: `${I18N_BASE_PATH}.general.iconBackgroundColor`,
          // => Cor de fundo do ícone
          defaultValue: "#FFFFFF",
          type: onboardingInputTypes.COLOR,
        },
      ],
    },
    COMPONENT_CAROUSEL: {
      name: `${I18N_BASE_PATH}.COMPONENT_CAROUSEL.name`,
      // => Carousel
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          property: "props.default.items",
          objectName: `${I18N_BASE_PATH}.COMPONENT_CAROUSEL.customizables.items.objectName`,
          // => slide
          fields: [
            {
              property: "image",
              label: `${I18N_BASE_PATH}.general.image`,
              // => Imagem
              type: onboardingInputTypes.IMAGE,
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.title`,
              // => Título
              property: "title",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              label: `${I18N_BASE_PATH}.general.subtitle`,
              // => Subtitulo
              property: "subtitle",
            },
          ],
        },
        {
          property: "props.default.textColor",
          label: `${I18N_BASE_PATH}.general.textColor`,
          // => Cor do texto
          defaultValue: "#FFFFFF",
          type: onboardingInputTypes.COLOR,
        },
      ],
    },
    "COMPONENT_TERMS-OF-USE": {
      name: `${I18N_BASE_PATH}.COMPONENT_TERMS-OF-USE.name`,
      // => Termos de uso
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          property: "props.default.terms",
          objectName: `${I18N_BASE_PATH}.COMPONENT_TERMS-OF-USE.customizables.terms.objectName`,
          // => termo
          fields: [
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.LABEL,
              label: `${I18N_BASE_PATH}.COMPONENT_TERMS-OF-USE.customizables.terms.fields.name.label`,
              // => Nome
              property: "name",
            },
            {
              type: onboardingInputTypes.TEXTAREA_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.label`,
              // => Label
              property: "label",
              tooltip: `${I18N_BASE_PATH}.COMPONENT_TERMS-OF-USE.customizables.terms.fields.label.tooltip`,
              // => É importante demarcar onde o usuário clicará para exibir o termo. Para isso, demarque parte do texto com "[descrição](link)" para um link externo ou "[descricao]<texto-do-modal>" para abrir uma modal.
            },
            {
              type: onboardingInputTypes.SWITCH,
              label: `${I18N_BASE_PATH}.general.required`,
              // => Obrigatório
              property: "required",
              defaultValue: true,
            },
          ],
        },
        {
          property: "props.default.title",
          label: `${I18N_BASE_PATH}.general.title`,
          // => Título
          type: onboardingInputTypes.TEXT_WITH_VARIABLE,
        },
        {
          property: "props.default.colorTextBackward",
          label: `${I18N_BASE_PATH}.COMPONENT_TERMS-OF-USE.customizables.colorTextBackward.label`,
          // => Cor do texto do botão de voltar
          defaultValue: "#FFFFFF",
          type: onboardingInputTypes.COLOR,
        },
      ],
    },
    "COMPONENT_DOCUMENT-TYPE": {
      name: `${I18N_BASE_PATH}.COMPONENT_DOCUMENT-TYPE.name`,
      // => Tipos de documento
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          property: "props.default.types",
          objectName: `${I18N_BASE_PATH}.COMPONENT_DOCUMENT-TYPE.customizables.types.objectName`,
          // => documento
          isRootProperty: true,
          limitOptions: true,
          optionsValueKey: "type",
          options: Object.values(
            onboardingPropsParserTranslator(defaultOnboardingValues?.defaultDocumentTypesOptions, language),
          ),
          fields: [
            {
              type: onboardingInputTypes.SELECT,
              label: `${I18N_BASE_PATH}.general.type`,
              // => Tipo
              property: "type",
              valueKey: "type",
              labelKey: "type",
            },
            {
              type: onboardingInputTypes.SELECT_ICONS,
              previewMode: previewModes.ICON,
              label: `${I18N_BASE_PATH}.general.icon`,
              // Ícone
              property: "icon",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.LABEL,
              label: `${I18N_BASE_PATH}.general.title`,
              // Título
              property: "title",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.general.description`,
              // => Descrição
              property: "description",
            },
          ],
        },
      ],
    },
    COMPONENT_INCOME: {
      name: `${I18N_BASE_PATH}.COMPONENT_INCOME.name`,
      // Faturamento
      customizables: [
        {
          type: onboardingInputTypes.MULTISELECT_TAGS,
          label: `${I18N_BASE_PATH}.general.options`,
          // => Opções
          property: "props.default.options",
        },
        {
          label: `${I18N_BASE_PATH}.COMPONENT_INCOME.customizables.frequency.label`,
          // => Frequência
          type: onboardingInputTypes.SELECT,
          property: "props.default.frequency",
          options: [
            {
              label: `${I18N_BASE_PATH}.COMPONENT_INCOME.customizables.frequency.options.monthly.label`,
              // => Mensal
              value: "monthly",
            },
            {
              label: `${I18N_BASE_PATH}.COMPONENT_INCOME.customizables.frequency.options.annually.label`,
              // => Anual
              value: "annually",
            },
          ],
          defaultValue: "monthly",
          labelKey: "label",
          valueKey: "value",
        },
      ],
    },
    COMPONENT_OCCUPATION: {
      name: `${I18N_BASE_PATH}.COMPONENT_OCCUPATION.name`,
      // => Profissão
      customizables: [
        {
          type: onboardingInputTypes.MULTISELECT_TAGS,
          label: `${I18N_BASE_PATH}.general.options`,
          // => Opções
          property: "props.default.options",
          allowImportCSV: true,
        },
      ],
    },
    "COMPONENT_DATA-CONFIRMATION": {
      name: `${I18N_BASE_PATH}.COMPONENT_DATA-CONFIRMATION.name`,
      // => Confirmação dos dados
      customizables: [
        {
          type: onboardingInputTypes.OBJECT_LIST_EDITOR,
          property: "props.default.fields",
          objectName: `${I18N_BASE_PATH}.COMPONENT_DATA-CONFIRMATION.customizables.fields.objectName`,
          // => campo
          limitOptions: true,
          optionsValueKey: "field",
          options: [
            { field: "name", description: "Nome", label: "Nome", placeholder: "Digite seu nome", icon: "person" },
            { field: "cpf", description: "CPF", label: "CPF", placeholder: "Digite seu CPF", icon: "person" },
            { field: "rg", description: "RG", label: "RG", placeholder: "Digite seu RG", icon: "person" },
            {
              field: "birthdate",
              description: "Aniversário",
              label: "Data de nascimento",
              placeholder: "Digite sua data de nascimento",
              icon: "calendar",
            },
            {
              field: "mother_name",
              description: "Nome da mãe",
              label: "Nome da mãe",
              placeholder: "Digite o nome da sua mãe",
              icon: "person",
            },
            {
              field: "father_name",
              description: "Nome do pai",
              label: "Nome do pai",
              placeholder: "Digite o nome do seu pai",
              icon: "person",
            },
            { field: "email", description: "Email", label: "Email", placeholder: "Digite o seu email", icon: "person" },
            {
              field: "phone_number",
              description: "Telefone",
              label: "Telefone",
              placeholder: "Digite o seu telefone",
              icon: "person",
            },
            {
              field: "rg_issue_date",
              description: "Data de emissão",
              label: "Data de emissão",
              placeholder: "Digite a data de emissão",
              icon: "person",
            },
            {
              field: "rg_issuing_authority",
              description: "Órgão emissor do RG",
              label: "Órgão emissor (RG)",
              placeholder: "Digite o órgão emissor do seu RG",
              icon: "person",
            },
            {
              field: "rg_issue_state",
              description: "Estado emissor do RG",
              label: "Estado emissor (RG)",
              placeholder: "Digite o estado emissor do seu RG",
              icon: "person",
            },
            {
              field: "nit",
              description: "NIT",
              label: "NIT",
              placeholder: "Digite o seu NIT",
              icon: "person",
            },
            {
              field: "cep",
              description: "Digite o seu CEP",
              label: "CEP",
              placeholder: "Digite o seu CEP",
              icon: "home",
            },
            {
              field: "street",
              description: "Digite a rua",
              label: "Rua",
              placeholder: "Digite a rua",
              icon: "home",
            },
            {
              field: "neighborhood",
              description: "Digite o bairro",
              label: "Bairro",
              placeholder: "Digite o bairro",
              icon: "home",
            },
          ],
          fields: [
            {
              type: onboardingInputTypes.SELECT,
              label: `${I18N_BASE_PATH}.COMPONENT_DATA-CONFIRMATION.customizables.fields.fields.field.label`,
              // => Campo
              valueKey: "field",
              labelKey: "description",
              property: "field",
            },
            {
              type: onboardingInputTypes.SELECT_ICONS,
              previewMode: previewModes.ICON,
              label: `${I18N_BASE_PATH}.general.icon`,
              // => Ícone
              property: "icon",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.LABEL,
              label: `${I18N_BASE_PATH}.COMPONENT_DATA-CONFIRMATION.customizables.fields.fields.label.label`,
              // => Label
              property: "label",
            },
            {
              type: onboardingInputTypes.TEXT_WITH_VARIABLE,
              previewMode: previewModes.TEXT,
              label: `${I18N_BASE_PATH}.COMPONENT_DATA-CONFIRMATION.customizables.fields.fields.placeholder.label`,
              // => Placeholder
              tooltip: `${I18N_BASE_PATH}.COMPONENT_DATA-CONFIRMATION.customizables.fields.fields.placeholder.tooltip`,
              // => Texto quando o campo está vazio
              property: "placeholder",
            },
          ],
        },
      ],
    },
    "COMPONENT_DD_CUSTOM-OPTIONS": {
      name: `${I18N_BASE_PATH}.COMPONENT_DD_CUSTOM-OPTIONS.name`,
      // => Personalizações
      customizables: [
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.general.sdk.customMessage.loadingMessage`,
          // => Mensagem de carregamento
          property: "props.default.sdkOptions.initialize.textSettings.messages.processMessage",
          tooltip: `${I18N_BASE_PATH}.COMPONENT_DD_CUSTOM-OPTIONS.customizables.sdkProcessMessage.tooltip`,
          // => Mensagem de carregamento enquanto o DD processa o documento
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.COMPONENT_DD_CUSTOM-OPTIONS.customizables.sdkWrongDocumentMessage.label`,
          // => Erro no tipo de documento
          property: "props.default.sdkOptions.initialize.textSettings.messages.wrongDocumentMessage",
          tooltip: `${I18N_BASE_PATH}.COMPONENT_DD_CUSTOM-OPTIONS.customizables.sdkWrongDocumentMessage.tooltip`,
          // => Mensagem de erro caso o usuário esteja tentando capturar um documento que não foi o selecionado na tela 'Tipo de documento'
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.COMPONENT_DD_CUSTOM-OPTIONS.customizables.sdkBothWrongSideMessage.label`,
          // => Captura de documento aberto
          property: "props.default.sdkOptions.initialize.textSettings.messages.bothWrongSideMessage",
          tooltip: `${I18N_BASE_PATH}.COMPONENT_DD_CUSTOM-OPTIONS.customizables.sdkBothWrongSideMessage.tooltip`,
          // => Mensagem de erro caso o usuário esteja tentando capturar o documento aberto
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.COMPONENT_DD_CUSTOM-OPTIONS.customizables.sdkWrongSideMessage.label`,
          // => Lado incorreto do documento
          property: "props.default.sdkOptions.initialize.textSettings.messages.wrongSideMessage",
          tooltip: `${I18N_BASE_PATH}.COMPONENT_DD_CUSTOM-OPTIONS.customizables.sdkWrongSideMessage.tooltip`,
          // => Mensagem de erro caso o usuário esteja tentando capturar o lado invertido do documento
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.COMPONENT_DD_CUSTOM-OPTIONS.customizables.sdkLowQualityMessage.label`,
          // => Baixa qualidade na captura
          property: "props.default.sdkOptions.initialize.textSettings.messages.lowQualityMessage",
          tooltip: `${I18N_BASE_PATH}.COMPONENT_DD_CUSTOM-OPTIONS.customizables.sdkLowQualityMessage.tooltip`,
          // => Mensagem de erro caso a captura do documento não fique em boa qualidade
          rows: 3,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.general.sdk.customMessage.genericErrorLabel`,
          // => Erro genérico
          property: "props.default.sdkOptions.initialize.textSettings.messages.captureFailedMessage",
          tooltip: `${I18N_BASE_PATH}.general.sdk.customMessage.genericErrorTooltip`,
          // => Mensagem de erro caso aconteça algum erro não mapeado
          rows: 2,
        },
        {
          type: onboardingInputTypes.SELECT,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.label`,
          // => Captura automática
          property: "props.default.sdkOptions.capture.captureSettings.mode",
          options: [
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.options.manual`,
              // => Manual
              value: "manual",
            },
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.options.automatic`,
              // => Automática
              value: "automatic",
            },
          ],
          defaultValue: "manual",
          labelKey: "label",
          valueKey: "value",
        },
        {
          type: onboardingInputTypes.NUMBER,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.automaticCaptureTimeout.label`,
          // => Tempo de captura automática
          property: "props.default.sdkOptions.capture.captureSettings.automaticCaptureTimeoutInSeconds",
          tooltip: `${I18N_BASE_PATH}.general.sdk.customSettings.automaticCaptureTimeout.tooltip`,
          // => Tempo de espera, em segundos, para habilitar a captura manual
        },
        {
          type: onboardingInputTypes.SWITCH,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.playAudioDescription.label`,
          // => Mostrar Opção do sdk DD playAudioDescription
          property: "props.default.sdkOptions.playAudioDescription",
          tooltip: `${I18N_BASE_PATH}.general.sdk.customSettings.playAudioDescription.tooltip`,
        },
      ],
    },
    "COMPONENT_PFL_CUSTOM-OPTIONS": {
      name: `${I18N_BASE_PATH}.COMPONENT_PFL_CUSTOM-OPTIONS.name`,
      // => Personalizações
      customizables: [
        {
          type: onboardingInputTypes.TEXT,
          property: "props.default.sdkOptions.initialize.textSettings.title",
          label: `${I18N_BASE_PATH}.general.title`,
          // => Título
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.general.sdk.customMessage.loadingMessage`,
          // => Mensagem de carregamento
          property: "props.default.sdkOptions.initialize.textSettings.messages.processMessage",
          tooltip: `${I18N_BASE_PATH}.COMPONENT_PFL_CUSTOM-OPTIONS.customizables.sdkProcessMessage.tooltip`,
          // => Mensagem de carregamento enquanto o PFL captura o rosto e realiza a prova de vida
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.COMPONENT_PFL_CUSTOM-OPTIONS.customizables.sdkIsNotAliveMessage.label`,
          // => Erro no Liveness
          property: "props.default.sdkOptions.initialize.textSettings.messages.isNotAliveMessage",
          tooltip: `${I18N_BASE_PATH}.COMPONENT_PFL_CUSTOM-OPTIONS.customizables.sdkIsNotAliveMessage.tooltip`,
          // => Mensagem de erro caso o PFL não consiga capturar o rosto. Utilize a tag <br/> para quebrar uma linha
          rows: 2,
        },
        {
          type: onboardingInputTypes.TEXT_AREA,
          label: `${I18N_BASE_PATH}.general.sdk.customMessage.genericErrorLabel`,
          // => Erro genérico
          property: "props.default.sdkOptions.initialize.textSettings.messages.captureFailedMessage",
          tooltip: `${I18N_BASE_PATH}.general.sdk.customMessage.genericErrorTooltip`,
          // => Mensagem de erro caso aconteça algum erro não mapeado
          rows: 2,
        },
        {
          type: onboardingInputTypes.SELECT,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.label`,
          // => Captura automática
          property: "props.default.sdkOptions.capture.captureSettings.mode",
          options: [
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.options.manual`,
              // => Manual
              value: "manual",
            },
            {
              label: `${I18N_BASE_PATH}.general.sdk.customSettings.captureMode.options.automatic`,
              // => Automática
              value: "automatic",
            },
          ],
          defaultValue: "manual",
          labelKey: "label",
          valueKey: "value",
        },
        {
          type: onboardingInputTypes.NUMBER,
          label: `${I18N_BASE_PATH}.general.sdk.customSettings.automaticCaptureTimeout.label`,
          // => Tempo de captura automática
          property: "props.default.sdkOptions.capture.captureSettings.automaticCaptureTimeoutInSeconds",
          tooltip: `${I18N_BASE_PATH}.general.sdk.customSettings.automaticCaptureTimeout.tooltip`,
          // => Tempo de espera, em segundos, para habilitar a captura manual
        },
      ],
    },
  };
  Object.freeze(customizableComponents);

  return customizableComponents;
};

export default renderCustomizableComponents;
