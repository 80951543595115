import {
  templateValidationsMessages,
  validateTemplateMessages,
} from "@utils/template-validations/templateValidationsMessages";

export const cpfValidationStep = (
  cpfValidationFlowsStepsIndexes,
  IProovFlowsStepsIndexes,
  IProovFAFlowsStepsIndexes,
  PFLFlowsStepsIndexes,
  FAFlowsStepsIndexes,
  ClearSaleFlowsStepsIndexes,
  templateValidationNotificationsToAdd = () => [],
) => {
  const cpfValidationStepIndex = cpfValidationFlowsStepsIndexes?.[0]?.stepIndex;
  const iproovStepIndex = IProovFlowsStepsIndexes?.[0]?.stepIndex;
  const iproovFAStepIndex = IProovFAFlowsStepsIndexes?.[0]?.stepIndex;
  const pflStepIndex = PFLFlowsStepsIndexes?.[0]?.stepIndex;
  const faStepIndex = FAFlowsStepsIndexes?.[0]?.stepIndex;
  const clearSaleStepIndex = ClearSaleFlowsStepsIndexes?.[0]?.stepIndex;

  const isCpfValidationStepValidLiveness =
    cpfValidationStepIndex >= iproovStepIndex || cpfValidationStepIndex >= pflStepIndex;
  const isCpfValidationStepValidFa =
    cpfValidationStepIndex >= iproovFAStepIndex || cpfValidationStepIndex >= faStepIndex;
  const isCpfValidationStepValidClearSale = cpfValidationStepIndex >= clearSaleStepIndex;

  if (isCpfValidationStepValidLiveness || isCpfValidationStepValidClearSale) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.CPF_VALIDATION_STEP_LIVENESS),
    );
  }

  if (isCpfValidationStepValidFa) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.CPF_VALIDATION_STEP_FA),
    );
  }
};
