import { onboardingPropsParserTranslator } from "@utils/translator";

/* 
We use this function to add default properties into template, when user add the SEND-DOCUMENT-TYPES step, however it didn't updated or changed anything.
When we're refactoring the response from default template, this code'll be removed. 
*/

export const addDefaultPropertiesWhenUserHasNotDone = ({ steps, defaultOnboardingValues, language }) => {
  const newSteps = steps.map((step) => {
    const itHasSendDocumentTypeStep = step.customizables.find(
      (customizable) => customizable === "STEP_SEND-DOCUMENT-TYPES",
    );
    if (itHasSendDocumentTypeStep) {
      if (!step?.sendDocumentTypes || step?.sendDocumentTypes.length === 0) {
        const sendDocumentTypes = Object.values(
          onboardingPropsParserTranslator(defaultOnboardingValues?.defaultSendDocumentTypesOptions, language),
        );
        step = {
          ...step,
          sendDocumentTypes,
        };
      }
      if (!step?.sendDocumentTypeModal || step?.sendDocumentTypeModal.length === 0) {
        const sendDocumentTypeModal = Object.values(
          onboardingPropsParserTranslator(defaultOnboardingValues?.defaultSendDocumentTypesModal, language),
        );
        step = {
          ...step,
          sendDocumentTypeModal,
        };
      }
      return step;
    }
    return step;
  });

  return newSteps;
};
