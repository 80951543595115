import {
  listContainsDuplicatedValues,
  validateDomain,
  validateDuplicatedTemplateVariablesNames,
  validateHelpButtonLink,
  validateTemplateVariablesNames,
  validURL,
} from "@utils/template-validations/general-validations";
import stepsValidations from "@utils/template-validations/steps-validations";

const templateValidations = {
  GENERAL_TEMPLATE_VALIDATIONS: {
    validateDuplicatedTemplateVariablesNames,
    validateTemplateVariablesNames,
    validateDomain,
    validateHelpButtonLink,
    validURL,
    listContainsDuplicatedValues,
  },
  STEPS_VALIDATIONS: stepsValidations,
};

export default templateValidations;
