/** Supported objectListInput types */
const objectListInputTypes = {
  INPUT: "INPUT",
  AUTOCOMPLETE: "AUTOCOMPLETE",
  SELECT_ICONS: "SELECT_ICONS",
  SELECT_OPTIONS: "SELECT_OPTIONS",
  MULTISELECT_EXTENSIONS: "MULTISELECT_EXTENSIONS",
  MULTISELECT_TAGS: "MULTISELECT_TAGS",
  SWITCH: "SWITCH",
};
Object.freeze(objectListInputTypes);

export default objectListInputTypes;
