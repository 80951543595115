import { LoaderProvider } from "@contexts/Loader";
import { ModalProvider } from "@contexts/Modal";
import HttpInterceptor from "@utils/auth/httpInterceptor";

const TemplateListProviders = ({ children }) => {
  return (
    <LoaderProvider>
      <ModalProvider>
        {children}
        <HttpInterceptor />
      </ModalProvider>
    </LoaderProvider>
  );
};

export default TemplateListProviders;
