import closeIcon from "@assets/icons/close.svg";
import greenDotIcon from "@assets/icons/green_dot.svg";
import { Button, Icon } from "@combateafraude/react";
import CustomizableElementInput from "@components/CustomizableElementsEditor/CustomizableElementInput";
import FloatingMenu, { FloatingMenuButton } from "@components/FloatingMenu";
import FloatingSidebar from "@components/FloatingSidebar";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import useFloatingSidebar from "@hooks/useFloatingSidebar";
import { isEmpty } from "@utils/";
import { toTitleCase } from "@utils/formatting";
import { onboardingPropsParserTranslator } from "@utils/translator";
import { Divider, Form, Row } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export const previewModes = {
  LABEL: "label",
  ICON: "icon",
  TEXT: "text",
  LIST: "list",
};

const I18N_BASE_PATH = "src.components.objectListEditor.objectPreview";

/** Preview to an object and menu to edit or delete it */
const ObjectPreview = ({
  property,
  isRootProperty,
  stepIndex,
  fields,
  objectName,
  object,
  objectIndex,
  lastAddedObjectIndex,
  clearLastAddedObjectIndex,
  setEditObjectIndex,
  removeObject,
  options,
  canRemoveField = true,
  isDefaultRequired,
  canEdit = true,
  saveButtonLabel,
  draggable = true,
  fixedLabel,
  validate,
  ...rest
}) => {
  const [error, setError] = useState();

  const { t } = useTranslation();
  const { onboardingLanguage } = useOnboardingOptionsContext();

  const labelFields = useMemo(
    () => fields.filter((field) => field.previewMode === previewModes.LABEL).map((field) => field.property),
    [fields],
  );
  const iconFields = useMemo(
    () => fields.filter((field) => field.previewMode === previewModes.ICON).map((field) => field.property),
    [fields],
  );
  const textFields = useMemo(() => fields.filter((field) => field.previewMode === previewModes.TEXT), [fields]);
  const listFields = useMemo(
    () => fields.filter((field) => field.previewMode === previewModes.LIST).map((field) => field.property),
    [fields],
  );

  const [showSidebar, hideSidebar, isSidebarVisible] = useFloatingSidebar();

  // If this object was just added, open the sidebar for editing
  useEffect(() => {
    if (objectIndex === lastAddedObjectIndex) {
      setTimeout(() => showSidebar(), 1);
      clearLastAddedObjectIndex();
      setEditObjectIndex(objectIndex);
    }
  }, [objectIndex, lastAddedObjectIndex]);

  const onClickEdit = () => {
    showSidebar();
    setEditObjectIndex(objectIndex);
  };

  useEffect(() => {
    setError(undefined);
  }, [object]);

  const handleCloseSidebar = useCallback(() => {
    if (!!saveButtonLabel) {
      removeObject();
    }
    hideSidebar();
  }, [saveButtonLabel, hideSidebar, removeObject]);

  const handleSidebarSave = useCallback(() => {
    if (validate) {
      const error = validate(object);
      if (error) {
        setError(error);
        return;
      }
    }
    hideSidebar();
  }, [hideSidebar, validate, object]);

  return (
    <>
      {!isEmpty(object) && (
        <div className="flex w-full flex-col items-stretch">
          {fixedLabel ? (
            <span className="text-xs font-light mb-1">{fixedLabel}</span>
          ) : (
            labelFields?.map((field, i) => (
              <span key={`PREVIEW_LABEL_${field || i}`} className="text-xs font-light mb-1">
                {onboardingPropsParserTranslator(object?.[field], onboardingLanguage)}
              </span>
            ))
          )}
          <div className="flex space-x-2 items-center">
            <div
              className={`${
                draggable && "dragger-handle cursor-grab"
              } flex-1 border border-gray-300 p-3 rounded-md font-normal flex flex-col justify-start`}
            >
              {iconFields?.map((field, i) => (
                <Icon key={`PREVIEW_ICON_${field || i}`} icon={object?.[field]} />
              ))}
              <div className={`flex flex-col gap-y-2 ${rest?.small ? "text-xs" : ""}`}>
                {textFields?.map((field, i) => (
                  <span
                    className={
                      onboardingPropsParserTranslator(object?.[field.property], onboardingLanguage)?.length >= 300
                        ? "truncate max-w-text-truncate"
                        : ""
                    }
                    key={`PREVIEW_SHOW_${field.property || i}`}
                  >
                    <p className="flex items-center !m-0">
                      {!!field.previewTextIcon && (
                        <img src={field.previewTextIcon} alt="" className={`mr-1 ${rest?.small ? "w-4" : ""}`} />
                      )}
                      {onboardingPropsParserTranslator(object?.[field.property], onboardingLanguage)}
                      {!!field.previewTextSuffix ? ` ${field.previewTextSuffix}` : ""}
                    </p>
                  </span>
                ))}
              </div>
              {listFields && listFields.length > 0 && (
                <>
                  <Divider className="!my-2" />
                  <div className={`flex w-full justify-start ${rest?.small ? "text-xs" : ""}`}>
                    {listFields?.map((field, i) => (
                      <div key={`PREVIEW_LIST_${field || i}`} className="flex">
                        {object?.[field]?.map((item, j) => (
                          <span key={`PREVIEW_LIST_ITEM_${field || i}_${j}`} className="flex mr-2 items-center">
                            <img src={greenDotIcon} alt="" className="mr-1" />
                            {item}
                          </span>
                        ))}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            <FloatingMenu>
              {(hide) => (
                <>
                  {canEdit && (
                    <FloatingMenuButton icon="edit" onClick={onClickEdit}>
                      {t(`${I18N_BASE_PATH}.components.editField`, "Editar campo")}
                    </FloatingMenuButton>
                  )}
                  {canEdit && canRemoveField && !isDefaultRequired && <hr />}
                  {canRemoveField && !isDefaultRequired && (
                    <FloatingMenuButton
                      icon="trash"
                      danger
                      onClick={() => {
                        removeObject();
                        hide();
                      }}
                    >
                      {t("general.label.remove", "Excluir")}
                    </FloatingMenuButton>
                  )}
                </>
              )}
            </FloatingMenu>
          </div>
        </div>
      )}
      <FloatingSidebar
        {...{ isVisible: isSidebarVisible, hide: handleCloseSidebar }}
        title={objectName && !isNaN(objectIndex) ? `${toTitleCase(objectName)} ${objectIndex + 1}` : ""}
      >
        <Form layout="vertical" className="font-bold space-y-5 !p-2">
          {fields?.map((field, i) => (
            <CustomizableElementInput
              key={`PREVIEW_INPUT_${i}`}
              stepIndex={stepIndex}
              customizable={{
                ...field,
                property: `${property}[${objectIndex}].${field.property}`,
                isRootProperty,
                options: options ?? field.options,
              }}
            />
          ))}
          {!!error && (
            <Row>
              <div className="flex justify-center p-1 w-full bg-red-200 border-red-500 border rounded shadow-sm">
                <img src={closeIcon} alt={"Error"} className="w-5 m-2" />
                <p className="!m-1">{error}</p>
              </div>
            </Row>
          )}
          {!!saveButtonLabel && (
            <Row align="center">
              <Button type="primary" onClick={handleSidebarSave}>
                {saveButtonLabel}
              </Button>
            </Row>
          )}
        </Form>
      </FloatingSidebar>
    </>
  );
};

export default ObjectPreview;
