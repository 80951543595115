import { Button as CafButton, Icon } from "@combateafraude/react";
import ConditionalLink from "@components/ConditionalLink";
import Loader from "@components/Loader";
import { TemplateCreateEmailBody } from "@components/Modal";
import EmailTemplatePropertiesBody from "@components/Modal/Bodies/EmailTemplatePropertiesBody";
import { useAnalytics } from "@contexts/Analytics";
import { useAPIContext } from "@contexts/API";
import { useLoader } from "@contexts/Loader";
import { useModal } from "@contexts/Modal";
import useAPI, { requestMethods } from "@hooks/useAPI";
import useTempURL from "@hooks/useTempURL";
import { getQueryVariable, getTempKey, scrollToElement } from "@utils/";
import { onboardingTemplatesAvailableLanguages } from "@utils/internationalization";
import { Pagination } from "antd";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { defaultEmailTemplate } from "../DefaultEmailTemplate";

const I18N_BASE_PATH = "src.pages.email.templateList";

const TemplateElement = ({ tenantId, template, onDuplicateTemplate, onDeleteTemplate, setTemplatesShouldUpdate }) => {
  const { t } = useTranslation();

  const openPropertiesModal = useModal();
  const [updateTemplateRequest] = useAPI();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const emailTemplate = useMemo(
    () => onboardingTemplatesAvailableLanguages?.find((language) => language?.id === template?.language),
    [template?.language],
  );

  const duplicateTemplate = useCallback(() => onDuplicateTemplate(template._id), [template, onDuplicateTemplate]);
  const deleteTemplate = useCallback(() => onDeleteTemplate(template._id), [template, onDeleteTemplate]);

  const updateTemplate = async (values) => {
    if (values) {
      let res = await updateTemplateRequest(
        `/accounts/${tenantId}/email-templates/${template._id}`,
        requestMethods.PATCH,
        values,
      );
      if (res) setTemplatesShouldUpdate(true);
      return res;
    }
  };

  return (
    <div key={template._id} className="relative w-full cursor-pointer group" onMouseLeave={() => setIsMenuOpen(false)}>
      {/* TODO: Swap for "FloatingMenu" component */}
      <span
        className={classNames(
          "w-8 h-8 bg-gray-100 rounded-full flex items-center justify-center absolute top-3 right-3 transition-all opacity-0 group-hover:opacity-100 hover:bg-gray-300 z-10",
          { "bg-gray-300": isMenuOpen },
        )}
        onClick={(e) => setIsMenuOpen((old) => !old)}
      >
        <Icon icon="more-horizontal" />
      </span>
      <div
        className={classNames(
          "modal absolute top-12 right-3 flex flex-col py-3 space-y-2 bg-white rounded-lg transition-all opacity-0 cursor-auto shadow-md z-10",
          { "pointer-events-none": !isMenuOpen },
          { "opacity-100": isMenuOpen },
        )}
      >
        <ConditionalLink to={`?tenantId=${tenantId}&templateId=${template._id}`} isExternal>
          <CafButton type="text" block className="!text-left !bg-transparent !shadow-none">
            <Icon icon="news" /> {t("general.label.edit", "Editar")}
          </CafButton>
        </ConditionalLink>
        <CafButton
          type="text"
          block
          className="!text-left !bg-transparent !shadow-none"
          onClick={() =>
            openPropertiesModal(
              t("general.label.properties", "Propriedades"),
              <EmailTemplatePropertiesBody defaultValues={{ ...template }} handleFinish={updateTemplate} />,
            )
          }
        >
          <Icon icon="settings" /> {t("general.label.properties", "Propriedades")}
        </CafButton>
        <hr />
        <CafButton type="text" block className="!text-left !bg-transparent !shadow-none" onClick={duplicateTemplate}>
          <Icon icon="duplicate" /> {t("general.label.duplicate", "Duplicar")}
        </CafButton>
        <hr />
        <CafButton type="text" block danger className="!text-left !shadow-none" onClick={deleteTemplate}>
          <Icon icon="trash" /> {t("general.label.remove", "Excluir")}
        </CafButton>
      </div>
      <ConditionalLink to={`?tenantId=${tenantId}&templateId=${template._id}`} isExternal>
        <div className="flex flex-col w-full space-y-2">
          <div className="relative flex justify-center items-center w-full h-64 bg-gray-300 rounded-md border-2 border-gray-200 transition-all group-hover:border-caf-primary overflow-hidden">
            {template.template ? (
              <iframe title={template.name} src={template.template} width="100%" height="100%" />
            ) : (
              <span className="absolute left-0 top-0 w-full h-full bg-gradient-to-b from-transparent to-black opacity-20" />
            )}
          </div>

          <div className="flex items-center mr-1">
            <span className="font-bold mr-2">{template.name}</span>
            <img
              title={`${t("general.label.language", "Idioma")} ${t(emailTemplate?.name, emailTemplate?.name)}`}
              className="w-6 rounded-full !ml-auto"
              src={emailTemplate?.flag}
              alt={`${t("general.label.language", "Idioma")} ${t(emailTemplate?.name, emailTemplate?.name)}`}
            />
          </div>
          <span className="text-sm">{template.description}</span>
        </div>
      </ConditionalLink>
    </div>
  );
};

const TemplateList = ({ tenantId }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const openCreateEmailTemplateModal = useModal();
  const { language } = useAPIContext();
  const { logAnalyticsTracking, analyticsEvents, analytics } = useAnalytics();

  const [startLoader, stopLoader, isLoaderLoading] = useLoader();
  const [htmlUploadRequest, htmlUploadLoading] = useTempURL();
  const [getTemplatesRequest, getTemplatesLoading] = useAPI();
  const [createTemplateRequest, createTemplateLoading] = useAPI();
  const [duplicateTemplateRequest, duplicateTemplateLoading] = useAPI();
  const [deleteTemplateRequest, deleteTemplateLoading] = useAPI();

  useEffect(() => {
    logAnalyticsTracking(analyticsEvents.LIST_EMAIL_TEMPLATES);
  }, [analytics]);

  // Manages the Loader
  useEffect(() => {
    if (getTemplatesLoading) startLoader();
    else if (createTemplateLoading) startLoader(t("general.label.creatingTemplate", "Criando template..."));
    else if (duplicateTemplateLoading) startLoader(t("general.label.duplicatingTemplate", "Duplicando template..."));
    else if (deleteTemplateLoading) startLoader(t("general.label.removingTemplate", "Deletando template..."));
    else if (htmlUploadLoading) startLoader(t("general.label.sendingHTML", "Enviando HTML..."));
    else stopLoader(500);
  }, [getTemplatesLoading, createTemplateLoading, duplicateTemplateLoading, deleteTemplateLoading, htmlUploadLoading]);

  const [templates, setTemplates] = useState([]);
  const [templatesShouldUpdate, setTemplatesShouldUpdate] = useState(true);
  const [pagination, setPagination] = useState({
    current: Number(getQueryVariable("page")) || 1,
    pageSize: 12,
    total: 0,
  });

  const fetchEmailTemplates = useCallback(
    async (current, hasToScrollToTop) => {
      if (hasToScrollToTop) scrollToElement("email-templates-list");

      const pageSize = pagination?.pageSize || 10;

      let newTemplates = await getTemplatesRequest(
        `/accounts/${tenantId}/email-templates?_limit=${pageSize}&_offset=${pageSize * (current - 1)}`,
      );

      if (newTemplates?.data) setTemplates(newTemplates.data);

      setPagination((oldState) => ({
        ...oldState,
        current,
        pageSize,
        total: newTemplates?.totalItems,
      }));
    },
    [pagination, tenantId, getTemplatesRequest],
  );

  // Get templates every time they change
  useEffect(async () => {
    if (templatesShouldUpdate) {
      fetchEmailTemplates(pagination?.current || 1, false);
      setTemplatesShouldUpdate(false);
    }
  }, [templatesShouldUpdate]);

  // Update templates list based on pagination
  const updateEmailTemplatesList = useCallback(async (page) => {
    setPagination((state) => ({ ...state, current: page }));
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", page);
    const newRelativePathQuery = window.location.pathname + "?" + searchParams.toString();

    window.history.pushState(null, "", newRelativePathQuery);

    await fetchEmailTemplates(page || 1, true);
  }, []);

  // API HANDLERS
  const onCreateTemplate = async (language) => {
    const _language = ["en-GB"]?.includes(language) ? "en-US" : language;

    let htmlUrls = await htmlUploadRequest(defaultEmailTemplate?.[_language].template, "text/html; charset='UTF-8'");
    if (htmlUrls) {
      let result = await createTemplateRequest(`/accounts/${tenantId}/email-templates`, requestMethods.POST, {
        name: defaultEmailTemplate?.[_language].name,
        description: defaultEmailTemplate?.[_language].description,
        language,
        templateKey: getTempKey(htmlUrls.getUrl),
      });

      if (result) history.push(`/email?tenantId=${tenantId}&templateId=${result?.templateId}`);
    }
  };

  const onDuplicateTemplate = async (templateId) => {
    let result = await duplicateTemplateRequest(
      `/accounts/${tenantId}/email-templates/${templateId}`,
      requestMethods.POST,
    );
    if (result) setTemplatesShouldUpdate(true);
  };

  const onDeleteTemplate = async (templateId) => {
    let result = await deleteTemplateRequest(
      `/accounts/${tenantId}/email-templates/${templateId}`,
      requestMethods.DELETE,
    );

    if (result) setTemplatesShouldUpdate(true);
  };

  if (!language) {
    return <Loader isHidden={false} />;
  }

  return (
    <div
      className={`caf-components relative flex flex-col w-full h-full ${
        getQueryVariable("noMargin") ? "" : "bg-transparent p-8"
      }`}
      id="email-templates-list"
    >
      <div className="flex justify-between w-full mb-8">
        <div className="flex flex-col">
          <h1 className="text-3xl font-black">{t(`${I18N_BASE_PATH}.components.heading`, "Templates de email")}</h1>
          <span>{t(`${I18N_BASE_PATH}.components.paragraph`, "Crie templates para seus emails personalizados")}</span>
        </div>
        <div className="flex flex-row-reverse items-end">
          <CafButton
            className="!h-10"
            type="primary"
            outlined
            loading={isLoaderLoading}
            onClick={() =>
              openCreateEmailTemplateModal(
                undefined,
                <TemplateCreateEmailBody handleFinish={onCreateTemplate} />,
                false,
                "!max-w-modal-create-onboarding !w-modal-create-onboarding",
              )
            }
            icon={<Icon icon="add" />}
          >
            {t("general.label.buttonCreateTemplate", "Criar template")}
          </CafButton>
        </div>
      </div>
      <div
        className={`relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-12 min-h-56 w-full ${
          pagination?.total > 12 && "mb-12"
        }`}
      >
        {templates.map((template, i) => (
          <TemplateElement
            key={i}
            {...{
              tenantId,
              template,
              onDuplicateTemplate,
              onDeleteTemplate,
              setTemplatesShouldUpdate,
            }}
          />
        ))}
      </div>
      {pagination?.total > 12 && (
        <div className="absolute bottom-4 self-center">
          <Pagination
            total={pagination?.total}
            pageSize={pagination?.pageSize}
            current={pagination?.current}
            onChange={(page) => updateEmailTemplatesList(page)}
            showSizeChanger={false}
            disabled={getTemplatesLoading}
          />
        </div>
      )}
    </div>
  );
};

export default TemplateList;
