import { Button as AntButton } from "antd";
import { Trans, useTranslation } from "react-i18next";

const I18N_BASE_PATH = "src.components.modal.bodies.templateVariableGuideBody";

/** Modal body to inform the user how variables work */
const TemplateVariableGuideBody = ({ close }) => {
  const { t } = useTranslation();

  const codeElementStyle = "bg-yellow-100 px-1";

  return (
    <div className="flex flex-col gap-2">
      <section>
        <h2 className="text-lg mb-1">
          {t(`${I18N_BASE_PATH}.components.firstSection.heading`, "O que são variáveis?")}
        </h2>
        <p className="mb-0">
          <Trans i18nKey={`${I18N_BASE_PATH}.components.firstSection.paragraph`}>
            Uma <strong>variável</strong> é utilizada para armazenar um valor que poderá ser modificado* no decorrer do{" "}
            <strong>Onboarding</strong>. A variável pode ser utilizada em diferentes etapas e momentos ao longo desse
            processo.
          </Trans>
        </p>
        <span className="text-xs">
          <Trans i18nKey={`${I18N_BASE_PATH}.components.firstSection.footer`}>
            * Algumas variáveis padrões não permitem ser modificadas, exemplo: a variável{" "}
            <strong>
              <code>personCpf</code>
            </strong>{" "}
            não pode ser modificada quando o link de onboarding está atrelado a um CPF.
          </Trans>
        </span>
      </section>
      <section>
        <h2 className="text-lg mb-1">{t(`${I18N_BASE_PATH}.components.secondSection.heading`, "Como criá-las?")}</h2>
        <p className="mb-0">
          <Trans i18nKey={`${I18N_BASE_PATH}.components.secondSection.paragraph`}>
            Para criar uma nova variável, basta se dirigir ao menu lateral esquerdo, na aba{" "}
            <strong>Configurações</strong> e abrir a seção <strong>Variáveis</strong>, clique no botão{" "}
            <strong>Novo</strong>.<br />
            Para inserir o nome da variável, são aceitos apenas letras (sem acentos) e números, <strong>não</strong> são
            aceitos caracteres especiais.
            <br />
            Você pode digitar o nome de uma variável padrão e sobrescrevê-la ou criar um novo nome.
            <br />
            Por fim, insira o valor corresponde ao nome da variável e pronto, você acabou de criar uma nova variável
            para ser utilizada.
          </Trans>
        </p>
      </section>
      <section>
        <h2 className="text-lg mb-1">{t(`${I18N_BASE_PATH}.components.thirdSection.heading`, "Como utilizá-las?")}</h2>
        <p className="mb-0">
          <Trans i18nKey={`${I18N_BASE_PATH}.components.thirdSection.firstParagraph`}>
            Para fazer uso de uma variável padrão ou que você mesmo criou, basta inserir{" "}
            <strong>
              <code className={codeElementStyle}>{`{{`}</code>
            </strong>{" "}
            que um seletor contendo as variáveis padrões e que você criou irá abrir, selecione a que você deseja e
            pronto, esse trecho será substituído pelo valor da variável selecionada. Você ainda pode utilizar os
            modificadores, inserindo{" "}
            <strong>
              <code className={codeElementStyle}>::</code>
            </strong>{" "}
            após a variável e escolhendo qual modificador deseja utilizar, confira a lista de modificadores abaixo.
            Também é possível encadear modificadores.
          </Trans>
        </p>
        <p className="mb-0 mt-1">
          <Trans i18nKey={`${I18N_BASE_PATH}.components.thirdSection.secondParagraph`}>
            <strong>Lista de modificadores:</strong> <code className={codeElementStyle}>upper</code> (transforma o texto
            para caixa alta), <code className={codeElementStyle}>lower</code> (transforma o texto para caixa baixa),{" "}
            <code className={codeElementStyle}>first-word</code> (seleciona apenas a primeira palavra) e{" "}
            <code className={codeElementStyle}>title</code> (transforma a primeira letra de cada palavra em maiúscula e
            as demais em minúsculas)
          </Trans>
        </p>
        <p className="mb-0 mt-1">
          <Trans i18nKey={`${I18N_BASE_PATH}.components.thirdSection.thirdParagraph`}>
            <strong>Exemplo de uso:</strong>{" "}
            <code className={codeElementStyle}>{`{{variavelExemplo}}::first-word::upper`}</code>
          </Trans>
        </p>
        <p className="mb-0 mt-1">
          <Trans i18nKey={`${I18N_BASE_PATH}.components.thirdSection.fourthParagraph`}>
            <strong>Links:</strong> para inserir um link em um campo de uma etapa, basta usar a sintaxe{" "}
            <code className={codeElementStyle}>[descrição](url)</code> para incluí-lo. Você ainda pode incluir variáveis
            tanto na <strong>descrição</strong> como na <strong>url</strong> do link. <br />
          </Trans>
        </p>
        <p className="mb-0 mt-1">
          <Trans i18nKey={`${I18N_BASE_PATH}.components.thirdSection.fifthParagraph`}>
            <strong>Exemplos de links:</strong> <br /> Sem variável:{" "}
            <code className={codeElementStyle}>{`[clique aqui](https://seu_site.com.br)`}</code> <br />
            Com variável: <code className={codeElementStyle}>{`[{{descricaoLink}}]({{urlLink}})`}</code>
          </Trans>
        </p>
      </section>
      <section>
        <h2 className="text-lg mb-1">{t(`${I18N_BASE_PATH}.components.fourthSection.heading`, "Informação:")}</h2>
        <p className="mb-0 mt-1">
          <Trans i18nKey={`${I18N_BASE_PATH}.components.fourthSection.firstParagraph`}>
            A criação de variável com o nome <strong>token</strong> não é permitida; <strong>token</strong> é uma
            palavra reservada para uso interno do sistema.
          </Trans>
        </p>
      </section>
      <AntButton className="self-center w-1/3 mt-2" type="primary" onClick={() => close()}>
        {t(`${I18N_BASE_PATH}.components.okButton`, "Ok, entendido!")}
      </AntButton>
    </div>
  );
};

export default TemplateVariableGuideBody;
