import CustomizableElementInput from "@components/CustomizableElementsEditor/CustomizableElementInput";
import FloatingMenu, { FloatingMenuButton } from "@components/FloatingMenu";
import FloatingSidebar from "@components/FloatingSidebar";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import { useOnboardingPropsContext } from "@contexts/OnboardingProps";
import useFloatingSidebar from "@hooks/useFloatingSidebar";
import { onboardingPropsParserTranslator } from "@utils/translator";
import { Form } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

/** Lists the errors of a step */
const ErrorsList = ({ errors, stepIndex, fields }) => {
  const { t } = useTranslation();

  const { activeError, setActiveError } = useOnboardingPropsContext();
  const { onboardingLanguage } = useOnboardingOptionsContext();
  const [showSidebar, hideSidebar, isSidebarVisible] = useFloatingSidebar();

  const editHandler = (error) => {
    showSidebar();
    setActiveError((old) => (old === error ? "" : error));
  };

  useEffect(() => {
    if (!isSidebarVisible) {
      setActiveError(() => "");
    }
  }, [isSidebarVisible]);

  const errorFields = fields.filter((f) => errors?.[activeError]?.[f.property] !== undefined);

  return (
    <>
      {errors &&
        Object.keys(errors)?.map((error) => {
          return (
            <div key={error} className="flex w-full flex-col items-stretch mb-3">
              <div className="flex space-x-2 items-center">
                <div className="flex-1 border border-dashed border-gray-300 p-2 font-normal flex items-center">
                  <div className="flex flex-col gap-y-2">
                    <p className="mb-0">
                      <b>{onboardingPropsParserTranslator(errors?.[error]?.description, onboardingLanguage)}</b>
                    </p>
                  </div>
                </div>
                <FloatingMenu>
                  {() => (
                    <>
                      <FloatingMenuButton icon="edit" onClick={() => editHandler(error)}>
                        {t("general.label.edit", "Editar")}
                      </FloatingMenuButton>
                    </>
                  )}
                </FloatingMenu>
              </div>
            </div>
          );
        })}
      <FloatingSidebar
        {...{ isVisible: isSidebarVisible, hide: hideSidebar }}
        title={
          activeError ? onboardingPropsParserTranslator(errors?.[activeError]?.description, onboardingLanguage) : ""
        }
      >
        <div className="px-2">
          <div className="p-2 border bg-gray-50 border-gray-500 rounded-md mb-3">
            <p className="text-sm mb-0 font-semibold">
              {activeError
                ? onboardingPropsParserTranslator(errors?.[activeError]?.description, onboardingLanguage)
                : ""}
            </p>
          </div>
        </div>
        <Form layout="vertical" className="font-bold space-y-5 !p-2">
          {errorFields.map((field, index) => (
            <CustomizableElementInput
              key={index}
              stepIndex={stepIndex}
              customizable={{
                property: `errors.${activeError}.${field.property}`,
                label: field.label,
                type: field.type,
              }}
            />
          ))}
        </Form>
      </FloatingSidebar>
    </>
  );
};

export default ErrorsList;
