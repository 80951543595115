const I18N_BASE_PATH = "src.utils.inputTypes";

export const inputTypes = [
  { value: "text", label: `${I18N_BASE_PATH}.text` },
  // => Texto
  { value: "email", label: `${I18N_BASE_PATH}.email` },
  // => Email
  { value: "password", label: `${I18N_BASE_PATH}.password` },
  // => Senha
  { value: "number", label: `${I18N_BASE_PATH}.number` },
  // => Número
  { value: "phone", label: `${I18N_BASE_PATH}.phone` },
  // => Telefone
  { value: "cpf", label: `${I18N_BASE_PATH}.cpf` },
  // => CPF
  { value: "cnpj", label: `${I18N_BASE_PATH}.cnpj` },
  // => CNPJ
  { value: "free", label: `${I18N_BASE_PATH}.free` },
  // => CNPJ
];

export const inputTypesAvailable = {
  text: {
    label: `${I18N_BASE_PATH}.inputTypesAvailable.text.label`,
    // => Texto
    placeholder: `${I18N_BASE_PATH}.inputTypesAvailable.text.placeholder`,
    // => Digite o seu texto"
    icon: "person",
  },
  email: {
    label: `${I18N_BASE_PATH}.inputTypesAvailable.email.label`,
    // => Email
    placeholder: `${I18N_BASE_PATH}.inputTypesAvailable.email.placeholder`,
    // => Digite o seu email
    icon: "mail",
  },
  password: {
    label: `${I18N_BASE_PATH}.inputTypesAvailable.password.label`,
    // => Texto
    placeholder: `${I18N_BASE_PATH}.inputTypesAvailable.password.placeholder`,
    // => Digite a sua senha
    icon: "lock_valid",
  },
  phone: {
    label: `${I18N_BASE_PATH}.inputTypesAvailable.phone.label`,
    // => Texto
    placeholder: `${I18N_BASE_PATH}.inputTypesAvailable.phone.placeholder`,
    // => Digite o texto
    icon: "person",
  },
  number: {
    label: `${I18N_BASE_PATH}.inputTypesAvailable.number.label`,
    // => Número
    placeholder: `${I18N_BASE_PATH}.inputTypesAvailable.number.placeholder`,
    // => Digite o número
    icon: "person",
  },
  cpf: {
    label: `${I18N_BASE_PATH}.inputTypesAvailable.cpf.label`,
    // => CPF
    placeholder: `${I18N_BASE_PATH}.inputTypesAvailable.cpf.placeholder`,
    // => Digite o CPF
    icon: "person",
  },
  cnpj: {
    label: `${I18N_BASE_PATH}.inputTypesAvailable.cnpj.label`,
    // => CNPJ
    placeholder: `${I18N_BASE_PATH}.inputTypesAvailable.cnpj.placeholder`,
    // => Digite o CNPJ
    icon: "company",
  },
  free: {
    label: `${I18N_BASE_PATH}.inputTypesAvailable.free.label`,
    // => Livre
    placeholder: `${I18N_BASE_PATH}.inputTypesAvailable.free.placeholder`,
    // => Digite...
    icon: "person",
  },
};
