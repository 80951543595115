import { Icon } from "@combateafraude/react";
import StepIcon from "@components/StepIcon";
import { useAPIContext } from "@contexts/API";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import { useOnboardingStepsContext } from "@contexts/OnboardingSteps";
import useSupportedSteps from "@contexts/OnboardingSteps/SupportedSteps";
import { cleanStep, indexStep } from "@utils/onboarding";
import { tagsInfo } from "@utils/tags";
import { Input, Tag, Tooltip } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const I18N_BASE_PATH = "src.components.stepsList";

const categoriesWithoutTags = ["FACE_AUTHENTICATOR"];

const StepsList = () => {
  const { t } = useTranslation();

  const { supportedPFSteps, supportedPJSteps, supportedSteps, supportedFASteps } = useSupportedSteps();
  const { onboardingSteps } = useOnboardingStepsContext();
  const { onboardingOptions } = useOnboardingOptionsContext();
  const { featureFlags } = useAPIContext();

  const [onboardingStepsFiltered, setOnboardingStepsFiltered] = useState(supportedSteps);

  const hasSmartChoice = useMemo(
    () => onboardingSteps.some((step) => step?.name?.includes("SMART_CHOICE")) || featureFlags?.isWorkflowBuilder,
    [onboardingSteps],
  );

  const _onboardingStepsFiltered = useMemo(() => {
    if (hasSmartChoice) {
      return onboardingStepsFiltered.filter((step) => {
        return !step?.name?.includes("SMART_CHOICE");
      });
    }
    return onboardingStepsFiltered;
  }, [hasSmartChoice, onboardingStepsFiltered]);

  useEffect(() => {
    if (onboardingOptions && !!onboardingOptions?.type) {
      if (onboardingOptions.category === "FACE_AUTHENTICATOR") {
        setOnboardingStepsFiltered(supportedFASteps);
      } else if (onboardingOptions?.type === "PF" && !!supportedPFSteps?.length) {
        setOnboardingStepsFiltered(supportedPFSteps);
      } else if (onboardingOptions?.type === "PJ" && !!supportedPJSteps?.length) {
        setOnboardingStepsFiltered(supportedPJSteps);
      }
    }
  }, [onboardingOptions?.type, supportedPFSteps, supportedPJSteps]);

  const [filter, setFilter] = useState();
  const [lowerCaseFilter, setLowerCaseFilter] = useState();

  useEffect(() => setLowerCaseFilter(filter?.toLowerCase()), [filter]);

  const onDragStart = useCallback(
    (event, step) => {
      let newStep = { ...cleanStep(step), name: indexStep(onboardingSteps, step?.name) };
      event.dataTransfer.setData("application/reactflow", JSON.stringify(newStep));
      event.dataTransfer.effectAllowed = "move";
    },
    [onboardingSteps],
  );

  return (
    <div className="px-4">
      <div className="flex flex-col items-stretch gap-3 mb-2">
        <Input
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          bordered={false}
          placeholder={t(`${I18N_BASE_PATH}.components.stepsInput.placeholder`, "O que deseja adicionar?")}
          suffix={<Icon icon="search" size="sm" />}
          className="mb-2 text-base"
        />

        {Object.values(_onboardingStepsFiltered)
          .filter((step) => {
            if (!lowerCaseFilter) return true;
            if (step?.description?.toLowerCase().includes(lowerCaseFilter)) return true;
            if (step?.longDescription?.toLowerCase().includes(lowerCaseFilter)) return true;
            if (step?.name?.toLowerCase().includes(lowerCaseFilter)) return true;
            return false;
          })
          .sort((a, b) => (a?.description < b?.description ? -1 : a?.description > b?.description ? 1 : 0))
          .map((step) => (
            <div
              key={`STEP_DRAGGABLE_${step?.name}`}
              className="border border-gray-200 rounded-md p-3 cursor-grab"
              onDragStart={(e) => onDragStart(e, step)}
              draggable
            >
              <div className="flex items-center gap-2">
                <StepIcon Src={step?.emoji} className="text-2xl min-w-7" />
                <div className="flex flex-col gap-1">
                  <div className="flex flex-row gap-1">
                    <h1 className="m-0 font-bold text-base">{step?.description}</h1>
                    {!!step?.tooltipDescription && (
                      <div className="transform scale-75">
                        <Tooltip title={step?.tooltipDescription} placement="topLeft" className="font-normal text-xs">
                          <span className="pl-1 cursor-pointer">
                            <Icon icon="tooltip" size="sm" />
                          </span>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <span className="text-xs">{step?.longDescription}</span>
                  {!!step?.tags?.length && !categoriesWithoutTags.includes(onboardingOptions?.category) && (
                    <div className="mt-1">
                      {step?.tags?.map((tag) => (
                        <Tooltip title={tag?.description} placement="bottom">
                          <Tag
                            key={`${step?.name}}_TAG_${tag?.name}`}
                            color={tagsInfo?.[tag?.name]?.color}
                            icon={tagsInfo?.[tag?.name]?.icon}
                            className="!rounded-lg !mb-1"
                          />
                        </Tooltip>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default StepsList;
