// TODO: Import IIcons from caf-react
export const icons = [
  "activity",
  "add",
  "admin",
  "alert-triangle",
  "anchor",
  "archive",
  "arrow-down-right",
  "arrow-right",
  "arrow-up-right",
  "arrow_down",
  "arrow_left",
  "arrow_right",
  "arrow_up",
  "attach",
  "awards",
  "backward",
  "bolt",
  "bus",
  "c_download",
  "camera_in",
  "calendar",
  "camera_switch",
  "camera",
  "car",
  "car_plate",
  "chassi",
  "check_d",
  "checkmark",
  "circle_false",
  "circle_hold",
  "circle_true",
  "clock",
  "close",
  "cloud",
  "combateafraude",
  "company",
  "contact_",
  "corner-up-right",
  "crash",
  "criminal",
  "documentation",
  "dollar_sign",
  "download",
  "drag",
  "driver",
  "driver_open",
  "duplicate",
  "edit",
  "edit_2",
  "edit_3",
  "export",
  "eye",
  "face",
  "faceID",
  "file",
  "filter",
  "first",
  "forward",
  "git-branch",
  "glasses",
  "hash",
  "hold",
  "home",
  "image",
  "import",
  "key",
  "law",
  "lawier",
  "link",
  "lock",
  "lock_valid",
  "mail",
  "map_pin",
  "match",
  "maximize",
  "message-circle",
  "minimize",
  "more-horizontal",
  "more-vertical",
  "motor",
  "news",
  "notification",
  "onboardind_big",
  "onboarding",
  "open_full",
  "pdf-file",
  "pdf_icon",
  "person",
  "plus",
  "processing",
  "processing_line",
  "reorder",
  "rotate-ccw",
  "rotate-cw",
  "search",
  "settings",
  "sidebar",
  "smartphone",
  "star_filled",
  "star_outline",
  "sun",
  "tag",
  "trash",
  "upload",
  "user_active",
  "user_inactive",
  "user-plus",
  "users",
  "weight",
  "x_close",
  "zoom-in",
  "zoom-out",
  /* rebranding icons */
  "welcome",
  "document_type",
  "shipping_method",
  "camera_permission",
  "document_detector",
  "pfl_selfie",
  "personal_data",
  "home_address",
  "business_data",
  "company_address",
  "employee_data",
  "related_person_data",
  "corporate_structure",
  "upload_file",
  "invoicing",
  "validate_phone",
  "validate_email",
  "end_flag",
  "instructions",
  "information",
  "decision",
  "form",
  "face_auth",
  "worldIcon",
];
// .reduce((result, item) => {
//   result[item] = item;
//   return result;
// }, {});
