import Button from "@components/Button";
import { useAPIContext } from "@contexts/API";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const I18N_BASE_PATH = "src.components.modal.bodies.templateCreateOnboardingBody.steps.model";

export const templateModels = [
  {
    id: "PF",
    title: "general.label.person",
    icon: "person",
  },
  {
    id: "PJ",
    title: "general.label.company",
    icon: "company",
  },
  {
    id: "FA",
    title: "general.label.fa",
    icon: "faceID",
  },
];

const Model = ({ onboardingData, setOnboardingData, setCanGoForward, setNextStepButtonTitle }) => {
  const { t } = useTranslation();
  const { featureFlags } = useAPIContext();

  useEffect(() => setCanGoForward(!!onboardingData?.templateModel), [onboardingData]);
  useEffect(
    () =>
      setNextStepButtonTitle(
        !onboardingData?.templateModel &&
          t(`${I18N_BASE_PATH}.nextStepButtonTitle`, "Selecione um modelo de template para continuar"),
      ),
    [onboardingData],
  );

  return (
    <div className="flex flex-row items-center justify-center gap-8">
      {templateModels?.map((templateModel) =>
        templateModel.id === "FA" && featureFlags.isWorkflowBuilder ? null : (
          <Button.Square
            key={templateModel?.id}
            icon={templateModel?.icon}
            title={t(templateModel?.title)}
            isSelected={onboardingData?.templateModel === templateModel?.id}
            customization={{
              size: "44",
              title: {
                fontSize: "sm",
              },
            }}
            onSelect={() => setOnboardingData((old) => ({ ...old, templateModel: templateModel?.id }))}
          />
        ),
      )}
    </div>
  );
};

export default Model;
