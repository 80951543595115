import Onboarding from "@package/steps";
import { parseStep } from "@utils/onboarding";
import {
  formatListContainsOnlyOneItem,
  templateStepsOrderValidationsRules,
  validateTemplateStepsOrderBasedOnEachFlow,
} from "@utils/template-validations/steps-validations/utils";
import {
  templateValidationsMessages,
  validateTemplateMessages,
} from "@utils/template-validations/templateValidationsMessages";
import isEqual from "lodash.isequal";

const { steps } = Onboarding;

// The "COMPANY_DATA" step must be before "COMPANY_ADDRESS" step
// The step has renamed to BUSINESS_ADDRESS
export const companyDataStepMustBeBeforeCompanyAddressStep = (
  companyDataFlowsStepsIndexes = [],
  companyAddressFlowsStepsIndexes = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  const companyDataIsInFrontOfCompanyAddressValidations = validateTemplateStepsOrderBasedOnEachFlow(
    [companyDataFlowsStepsIndexes, companyAddressFlowsStepsIndexes],
    templateStepsOrderValidationsRules.BETWEEN_TWO_STEPS,
  );

  if (!companyDataIsInFrontOfCompanyAddressValidations?.length) {
    return;
  }

  let companyDataIsInFrontOfCompanyAddressFlows = companyDataIsInFrontOfCompanyAddressValidations?.map(
    (validation) => validation?.flow,
  );

  // add a new template validation if the company data step is in front of company address step based of each flow
  if (
    !!companyDataIsInFrontOfCompanyAddressFlows?.length &&
    companyDataIsInFrontOfCompanyAddressFlows?.every((flowName) => !!flowName)
  ) {
    companyDataIsInFrontOfCompanyAddressFlows = formatListContainsOnlyOneItem(
      companyDataIsInFrontOfCompanyAddressFlows,
    );

    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.COMPANY_DATA_IS_IN_FRONT_OF_BUSINESS_ADDRESS)(
        companyDataIsInFrontOfCompanyAddressFlows,
      ),
    );
  }
};

export const companyDataStepMustExist = (onboardingOptions = {}, templateValidationNotificationsToAdd = () => []) => {
  if (onboardingOptions?.type === "PJ") {
    const templateHasNotOneCompanyData = onboardingOptions?.steps?.some((_step) => {
      return (
        isEqual(parseStep(_step?.name)[0], steps.COMPANY_DATA.name) ||
        isEqual(parseStep(_step?.name)[0], steps.COMPANY_SEARCH.name)
      );
    });

    if (!templateHasNotOneCompanyData) {
      templateValidationNotificationsToAdd.push(
        validateTemplateMessages(templateValidationsMessages.COMPANY_DATA_STEP_DOES_NOT_EXIST),
      );
    }
  }
};
