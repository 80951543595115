import HelpIcon from "@assets/icons/help.svg";
import { renderCustomizableComponents, renderCustomizableElements } from "@components/ElementEditor";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import { useOnboardingStepsContext } from "@contexts/OnboardingSteps";
import { Collapse, Form, Tooltip } from "antd";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import CustomizableElementInput from "./CustomizableElementInput";

const { Panel } = Collapse;

const CustomizableElementsEditor = ({
  step,
  stepIndex,
  errorName,
  customizableElement,
  customizableElementName,
  customizableElementPath,
}) => {
  const { t } = useTranslation();
  const { onboardingSteps } = useOnboardingStepsContext();
  const { onboardingLanguage, templateTheme } = useOnboardingOptionsContext();

  const customizableElements = useMemo(() => renderCustomizableElements(onboardingLanguage), [onboardingLanguage]);
  const customizableComponents = useMemo(() => renderCustomizableComponents(onboardingLanguage), [onboardingLanguage]);

  const stepCustomizables = useMemo(() => step?.customizables, [step?.customizables]);

  const runAllValidations = useCallback(
    (rules) => {
      const results = rules?.map(({ onValid }) => onValid({ steps: onboardingSteps, templateTheme }));
      const resultsHavePassed = results?.every((eachResult) => !!eachResult);

      return resultsHavePassed;
    },
    [onboardingSteps],
  );

  const renderCustomizableElementsInputs = ({
    customizableHasRules,
    elementHasRules,
    customizableName,
    customizable,
    customizableIndex,
    customizables,
  }) => {
    if (!customizableHasRules && !elementHasRules) {
      return (
        <CustomizableElementInput
          key={`STEP_${step?.name}_CUSTOMIZABLE_INPUT_${customizableIndex}`}
          {...{
            customizableName,
            customizable,
            step,
            stepIndex,
            errorName,
          }}
        />
      );
    }

    if (customizableHasRules && !elementHasRules) {
      return (
        runAllValidations(customizables[customizableName]?.rules) && (
          <CustomizableElementInput
            key={`STEP_${step?.name}_CUSTOMIZABLE_INPUT_${customizableIndex}`}
            {...{
              customizableName,
              customizable,
              step,
              stepIndex,
              errorName,
            }}
          />
        )
      );
    }

    if (!customizableHasRules && elementHasRules) {
      return (
        runAllValidations(customizable?.rules) && (
          <CustomizableElementInput
            key={`STEP_${step?.name}_CUSTOMIZABLE_INPUT_${customizableIndex}`}
            {...{
              customizableName,
              customizable,
              step,
              stepIndex,
              errorName,
            }}
          />
        )
      );
    }

    if (customizableHasRules && elementHasRules) {
      return (
        runAllValidations(customizables[customizableName]?.rules) &&
        runAllValidations(customizable?.rules) && (
          <CustomizableElementInput
            key={`STEP_${step?.name}_CUSTOMIZABLE_INPUT_${customizableIndex}`}
            {...{
              customizableName,
              customizable,
              step,
              stepIndex,
              errorName,
            }}
          />
        )
      );
    }
  };

  const customizableElementsInputs = useCallback(
    ({ customizableName }) => {
      const isComponent = customizableName.split("_")[0] === "COMPONENT";
      const customizables = isComponent ? customizableComponents : customizableElements;
      const thereAreRules = customizables[customizableName]?.rules;

      return (
        <>
          {customizables[customizableName]?.customizables?.map((customizable, customizableIndex) =>
            renderCustomizableElementsInputs({
              customizableHasRules: thereAreRules,
              elementHasRules: customizable?.rules,
              customizable,
              customizableName,
              customizables,
              customizableIndex,
            }),
          )}
        </>
      );
    },
    [customizableElements, customizableComponents, step, stepIndex, errorName, onboardingSteps],
  );

  return (
    <Form layout="vertical" className="font-bold space-y-5 !p-2">
      {stepCustomizables?.length
        ? stepCustomizables.map((customizableName, i) => (
            <React.Fragment key={`STEP_${step?.name}_CUSTOMIZABLES_${i}`}>
              {customizableElements[customizableName]?.name && !customizableElements[customizableName]?.panel && (
                <>
                  {i > 0 ? <hr className="-mx-4 !mt-7" /> : null}
                  <div className="flex items-center space-x-2 text-sm opacity-50">
                    <h1 className="font-light -mt-2 mb-3">
                      {t(customizableElements[customizableName]?.name, customizableElements[customizableName]?.name)}
                    </h1>
                    {customizableElements[customizableName].help && (
                      <Tooltip
                        title={t(
                          customizableElements[customizableName].help,
                          customizableElements[customizableName].help,
                        )}
                        className="-mt-5"
                      >
                        <img src={HelpIcon} alt={customizableElements[customizableName].help} />
                      </Tooltip>
                    )}
                  </div>
                </>
              )}
              <Collapse expandIconPosition="right" ghost className="!bg-transparent !border-0">
                {customizableElements[customizableName]?.panel && !customizableElements[customizableName]?.rules ? (
                  <Panel
                    className="text-lg font-bold !border-0 !-ml-4"
                    header={t(
                      customizableElements[customizableName]?.name,
                      customizableElements[customizableName]?.name,
                    )}
                    key="0"
                  >
                    {customizableElementsInputs({ customizableName })}
                  </Panel>
                ) : customizableElements[customizableName]?.panel &&
                  runAllValidations(customizableElements[customizableName]?.rules) ? (
                  <Panel
                    className="text-lg font-bold !border-0 !-ml-4"
                    header={t(
                      customizableElements[customizableName]?.name,
                      customizableElements[customizableName]?.name,
                    )}
                    key="0"
                  >
                    {customizableElementsInputs({ customizableName })}
                  </Panel>
                ) : (
                  <>{customizableElementsInputs({ customizableName })}</>
                )}
              </Collapse>
            </React.Fragment>
          ))
        : customizableElement?.customizables?.map((customizable, i) => {
            return (
              <CustomizableElementInput
                key={`CUSTOMIZABLE_${customizableElementName}_INPUT_${i}`}
                {...{
                  customizableName: customizableElementName,
                  customizablePath: customizableElementPath,
                  customizable,
                  step,
                  stepIndex,
                  errorName,
                }}
              />
            );
          })}
    </Form>
  );
};
export default CustomizableElementsEditor;
