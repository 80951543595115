import {
  templateValidationsMessages,
  validateTemplateMessages,
} from "@utils/template-validations/templateValidationsMessages";

/* Validations that are not directly related to steps */

// Check for duplicated values in a list, return if there are duplicates values (true or false)
export const listContainsDuplicatedValues = (valuesList = []) => {
  return new Set(valuesList)?.size !== valuesList?.length;
};

// Check if the url is valid
export const validURL = (str = "") => {
  let url;
  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

// Check for identical variable names when creating a new variable on Sidebar
export const validateDuplicatedTemplateVariablesNames = (
  variables = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  let hasDuplicatedVariableNames;

  if (!variables?.length) {
    return;
  }

  // merge all variable names into just one array
  const variableNamesList = variables
    ?.map((variable) => variable?.variableName)
    ?.filter((variableName) => !!variableName);

  if (variableNamesList?.length > 0) {
    hasDuplicatedVariableNames = listContainsDuplicatedValues(variableNamesList);
  }

  // if has duplicated variables names, add a template notification
  if (hasDuplicatedVariableNames) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.VARIABLE_NAMES_DUPLICATED),
    );
  }
};

export const validateTemplateVariablesNames = (variables = [], templateValidationNotificationsToAdd = () => []) => {
  if (!variables?.length) {
    return;
  }

  // Check if there is a variable with the name "token"
  const variableNamesListNotAllowed = variables
    ?.map((variable) => variable?.variableName)
    ?.filter((variableName) => variableName?.toLowerCase() === "token");

  // If there is a variable with the name "token", add a template notification
  if (variableNamesListNotAllowed?.length > 0) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.VARIABLE_NAMES_NOT_ALLOWED),
    );
  }
};

// Validate if the domain is a valid URL
export const validateDomain = (domain = "", templateValidationNotificationsToAdd = () => []) => {
  const domainIsNotValidUrl =
    !!domain && !validURL(`https://${domain}.${process.env.REACT_APP_ONBOARDING_DEFAULT_DOMAIN}`);

  // If the domain is not a valid URL, add a template notification
  if (domainIsNotValidUrl) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.DOMAIN_IS_NOT_VALID),
    );
  }
};

// Validate if the help button link is a valid URL
export const validateHelpButtonLink = (url = "", templateValidationNotificationsToAdd = () => []) => {
  const helpButtonLinkIsValidUrl = !!url && !validURL(`https://${url}`);

  // If the help button link is not a valid URL, add a template notification
  if (helpButtonLinkIsValidUrl) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.HELP_BUTTON_URL_IS_NOT_VALID),
    );
  }
};
