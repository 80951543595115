import Onboarding from "@package/steps";
import { parseStep } from "@utils/onboarding";
import {
  templateValidationsMessages,
  validateTemplateMessages,
} from "@utils/template-validations/templateValidationsMessages";
import isEqual from "lodash.isequal";

const { steps } = Onboarding;

export const qsaStepHasBothFlagsOn = (onboardingSteps = [], templateValidationNotificationsToAdd = () => []) => {
  const qsaSteps = onboardingSteps.filter((_step) => isEqual(parseStep(_step?.name)[0], steps.QSA.name));

  const hasBothFlagsOn = qsaSteps.some((step) => step.manual === true && step.disableManualInsertOption === true);

  if (hasBothFlagsOn) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.QSA_STEP_HAS_BOTH_FLAGS_ON),
    );
  }
};
