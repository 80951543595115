import { ReactSortable } from "react-sortablejs";

import ObjectPreview from "./ObjectPreview";

/** Edits an array of objects */
const SmartOptionsListEditor = ({ inputValue, fields }) => {
  return (
    <div className="flex flex-col space-y-2">
      <ReactSortable
        list={inputValue || []}
        setList={() => {}}
        animation={150}
        className={"space-y-2"}
        draggable={false}
      >
        {inputValue?.map?.((object, i) => {
          return <ObjectPreview fields={[...fields, ...(object?.fields || [])]} object={inputValue?.[i]} />;
        })}
      </ReactSortable>
    </div>
  );
};

export default SmartOptionsListEditor;
