/** Supported input types */
const onboardingInputTypes = {
  TEXT: "TEXT",
  TEXT_ID: "TEXT_ID",
  TEXT_WITH_VARIABLE: "TEXT_WITH_VARIABLE",
  TEXTAREA_WITH_VARIABLE: "TEXTAREA_WITH_VARIABLE",
  AUTOCOMPLETE: "AUTOCOMPLETE",
  TEXT_AREA: "TEXT_AREA",
  IMAGE: "IMAGE",
  COLOR: "COLOR",
  SELECT: "SELECT",
  SELECT_MULTIPLE: "SELECT_MULTIPLE",
  SELECT_ICONS: "SELECT_ICONS",
  MULTISELECT_TAGS: "MULTISELECT_TAGS",
  MULTISELECT_EXTENSIONS: "MULTISELECT_EXTENSIONS",
  SWITCH: "SWITCH",
  SWITCH_OPTION: "SWITCH_OPTION",
  OBJECT_LIST_EDITOR: "OBJECT_LIST_EDITOR",
  SMART_LIST: "SMART_LIST",
  OBJECT_LIST_EDITOR_DEFAULT_FIELDS: "OBJECT_LIST_EDITOR_DEFAULT_FIELDS",
  RULES_LIST: "RULES_LIST",
  NUMBER: "NUMBER",
  ERRORS_LIST: "ERRORS_LIST",
  MAX_LENGTH: "MAX_LENGTH",
  SELECT_INPUT_TYPES: "SELECT_INPUT_TYPES",
  MULTIPLE_CHECKBOX: "MULTIPLE_CHECKBOX",
  DROP_DOWN: "DROP_DOWN",
};
Object.freeze(onboardingInputTypes);

export default onboardingInputTypes;
