import {
  formatListContainsOnlyOneItem,
  templateStepsOrderValidationsRules,
  validateTemplateStepsOrderBasedOnEachFlow,
} from "@utils/template-validations/steps-validations/utils";
import {
  templateValidationsMessages,
  validateTemplateMessages,
} from "@utils/template-validations/templateValidationsMessages";
import isEqual from "lodash.isequal";

// If template hasn't DD or PFL steps, camera access should not be enabled
export const templateHasNotDDorPFLStepCameraAccessShouldNotBeEnabled = (
  requiredStepsIndexes = [],
  cameraAccessFlowsStepsIndexes = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  let cameraAccessFlowNames = [];

  // if the template hasn't at least one DD or PFL step, but has at least one camera access step
  if (requiredStepsIndexes.every((stepIndexes) => !stepIndexes?.length) && !!cameraAccessFlowsStepsIndexes?.length) {
    cameraAccessFlowsStepsIndexes?.forEach((cameraAccessFlowStepIndex) =>
      cameraAccessFlowNames.push(cameraAccessFlowStepIndex?.flow),
    );
  }

  // validate based on each flow, if it has camera access step but doesn't have DD or PFL step
  if (!!cameraAccessFlowsStepsIndexes?.length && requiredStepsIndexes.some((stepIndexes) => !!stepIndexes?.length)) {
    const cameraAccessFlows = cameraAccessFlowsStepsIndexes?.map(
      (_cameraAccessFlowStepIndex) => _cameraAccessFlowStepIndex?.flow,
    );

    if (!!cameraAccessFlows?.length) {
      cameraAccessFlows?.forEach((cameraAccessFlow) => {
        if (
          requiredStepsIndexes.every((stepIndexes) => {
            const flow = stepIndexes
              ?.map((stepIndex) => stepIndex?.flow)
              ?.find((flow) => isEqual(flow.flow, cameraAccessFlow?.flow));
            return !flow;
          })
        ) {
          cameraAccessFlowNames.push(cameraAccessFlow?.flow);
        }
      });
    }
  }

  if (!!cameraAccessFlowNames?.length && cameraAccessFlowNames?.every((flowName) => !!flowName)) {
    cameraAccessFlowNames = formatListContainsOnlyOneItem(cameraAccessFlowNames);

    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.TEMPLATE_MUST_HAVE_DD_OR_PFL_TO_ENABLE_CAMERA_ACCESS)(
        cameraAccessFlowNames,
      ),
    );
  }
};

// If template has DD and/or PFL steps, it should has CAMERA_ACCESS step
export const templateShouldHasCameraAccessIfDDorPFL = (
  flowsStepsIndexes,
  cameraAccessFlowsStepsIndexes = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  let DDPFLFAFlowsNames = [];

  const flowsIndexes = flowsStepsIndexes.map((flowStepsIndexes) =>
    flowStepsIndexes.map((flowStepIndex) => flowStepIndex?.flow),
  );

  // if the template has at least one DD or PFL step into a flow, but doesn't have at least one camera access step
  if (
    flowsStepsIndexes.some((flowStepsIndexes) => !!flowStepsIndexes?.length) &&
    !cameraAccessFlowsStepsIndexes?.length
  ) {
    // merge DD and PFL flows names, removing duplicate flows names, if it has, to add a new template validation

    flowsIndexes.forEach((flows) => {
      flows.forEach((flow) => {
        if (!DDPFLFAFlowsNames?.includes(flow)) {
          DDPFLFAFlowsNames.push(flow);
        }
      });
    });
  }

  // if the flow has DD and/or PFL steps but doesn't have camera access step
  if (
    !!cameraAccessFlowsStepsIndexes?.length &&
    flowsStepsIndexes.some((flowStepsIndexes) => !!flowStepsIndexes?.length)
  ) {
    let DDPFLFAFlows = [];

    const cameraAccessFlows = cameraAccessFlowsStepsIndexes?.map(
      (cameraAccessFlowStepIndex) => cameraAccessFlowStepIndex?.flow,
    );

    flowsIndexes.forEach((flows) => {
      flows.forEach((flow) => {
        if (!DDPFLFAFlows?.includes(flow)) {
          DDPFLFAFlows.push(flow);
        }
      });
    });

    if (!!DDPFLFAFlows?.length) {
      DDPFLFAFlows?.forEach((DDPFLFlow) => {
        const cameraAccessFlow = cameraAccessFlows?.find((cameraAccessFlow) => isEqual(cameraAccessFlow, DDPFLFlow));

        // validate in each flow, if it has camera access step but doesn't have DD or PFL step
        if (!cameraAccessFlow) {
          DDPFLFAFlowsNames.push(DDPFLFlow?.flow);
        }
      });
    }
  }

  if (!!DDPFLFAFlowsNames?.length && DDPFLFAFlowsNames?.every((flowName) => !!flowName)) {
    DDPFLFAFlowsNames = formatListContainsOnlyOneItem(DDPFLFAFlowsNames);

    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.TEMPLATE_SHOULD_HAS_CAMERA_ACCESS_DD_PFL_ENABLED)(
        DDPFLFAFlowsNames,
      ),
    );
  }
};

// The "CAMERA_ACCESS" step must be before "DD" and/or "PFL" step
export const cameraAccessStepMustBeBeforeDDandPFLSteps = (
  cameraAccessFlowsStepsIndexes = [],
  DDFlowsStepsIndexes = [],
  LivenessStepIndexes = [], // array of liveness steps indexes
  templateValidationNotificationsToAdd = () => [],
) => {
  let livenessIndexes = [];

  if (!!LivenessStepIndexes?.length) {
    LivenessStepIndexes.forEach((stepIndexes) => {
      if (!!stepIndexes?.length) {
        livenessIndexes = stepIndexes;
      }
    });
  }

  const cameraAccessIsInFrontOfDDorPFLValidations = validateTemplateStepsOrderBasedOnEachFlow(
    [cameraAccessFlowsStepsIndexes, DDFlowsStepsIndexes, livenessIndexes],
    templateStepsOrderValidationsRules.BETWEEN_THREE_STEPS,
  );

  if (!cameraAccessIsInFrontOfDDorPFLValidations?.length) {
    return;
  }

  let cameraAccessIsInFrontOfDDorPFLFlows = cameraAccessIsInFrontOfDDorPFLValidations?.map(
    (validation) => validation?.flow,
  );

  // add a new template validation if the camera access step is in front of DD and/or PFL steps based of each flow
  if (
    !!cameraAccessIsInFrontOfDDorPFLFlows?.length &&
    cameraAccessIsInFrontOfDDorPFLFlows?.every((flowName) => !!flowName)
  ) {
    cameraAccessIsInFrontOfDDorPFLFlows = formatListContainsOnlyOneItem(cameraAccessIsInFrontOfDDorPFLFlows);

    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.CAMERA_ACCESS_IS_IN_FRONT_OF_DD_OR_PFL)(
        cameraAccessIsInFrontOfDDorPFLFlows,
      ),
    );
  }
};
