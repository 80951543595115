import { Icon } from "@combateafraude/react";
import { Alert, message } from "antd";
import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const I18N_BASE_PATH = "src.components.modal.bodies.templateImportFieldsFromCSVFileBody";

const TemplateImportFieldsFromCSVFileBody = ({
  close,
  objects,
  setObjects,
  objectIndex,
  objectFieldValueKey,
  setCSVFilesNames,
}) => {
  const { t } = useTranslation();

  // States for import fields from CSV file
  const [isUploadingCSVFile, setIsUploadingCSVFile] = useState(false);
  const [errorOnImportCSVFile, setErrorOnImportCSVFile] = useState(undefined);

  const acceptedFileTypes = [
    "csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  // Messages if an error occurs when importing the file
  const errorsOnImportCSVFile = {
    FILE_TYPE_IS_NOT_ACCEPTED: {
      type: "error",
      description: t(
        `${I18N_BASE_PATH}.constants.errorsOnImportCSVFile.error`,
        "O tipo de arquivo importado não é aceito, por favor, importe um arquivo com a extensão .csv",
      ),
    },
    FILE_IS_TOO_BIG: {
      type: "error",
      description: t(
        `${I18N_BASE_PATH}.constants.errorsOnImportCSVFile.fileIsTooBig`,
        "O arquivo importado contém mais de 8.000 linhas, excedendo o limite suportável",
      ),
    },
    FILE_IS_EMPTY: {
      type: "warning",
      description: t(
        `${I18N_BASE_PATH}.constants.errorsOnImportCSVFile.fileIsEmpty`,
        "O arquivo deve conter pelo menos uma cédula com valor para adicionar às opções",
      ),
    },
  };

  // Read CSV file and convert its values(string) into an array
  const importFieldsFromCSVFile = useCallback(
    (e) => {
      e.preventDefault();
      setIsUploadingCSVFile(true);
      setErrorOnImportCSVFile(undefined);

      const CSVFileInput = document.getElementById("importFieldsFromCSV");
      const CSVFile = CSVFileInput.files[0];
      const CSVFileTypeIsAccepted = acceptedFileTypes.some((type) => CSVFile.type.toLowerCase().includes(type));

      if (!CSVFileTypeIsAccepted) {
        setIsUploadingCSVFile(false);

        setErrorOnImportCSVFile(errorsOnImportCSVFile.FILE_TYPE_IS_NOT_ACCEPTED);

        return;
      }
      const reader = new FileReader();

      reader.onloadend = function (e) {
        const text = e.target.result;
        // Converting the string of the CSV file to array
        const textToArrayFormattedAndSorted = text
          .split("\n")
          ?.filter((option) => option)
          ?.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));

        if (textToArrayFormattedAndSorted?.length > 8000) {
          setIsUploadingCSVFile(false);

          setErrorOnImportCSVFile(errorsOnImportCSVFile.FILE_IS_TOO_BIG);

          return;
        }

        if (textToArrayFormattedAndSorted?.length === 0) {
          setIsUploadingCSVFile(false);

          setErrorOnImportCSVFile(errorsOnImportCSVFile.FILE_IS_EMPTY);
        } else {
          setObjects(textToArrayFormattedAndSorted);
          setCSVFilesNames((old) => [...old, { id: objectIndex, name: CSVFile.name }]);

          message.success(
            t(
              `${I18N_BASE_PATH}.methods.importFieldsFromCSVFile.fileUploadSuccessfully`,
              "As opções importadas do arquivo foram adicionadas com sucesso",
            ),
          );

          setIsUploadingCSVFile(false);
          close();
        }
      };

      reader.readAsText(CSVFile);
    },
    [
      objects,
      acceptedFileTypes,
      objectIndex,
      objectFieldValueKey,
      errorsOnImportCSVFile,
      close,
      setObjects,
      setCSVFilesNames,
      t,
    ],
  );

  return (
    <>
      <Alert
        description={t(
          `${I18N_BASE_PATH}.components.alert.description`,
          "O arquivo CSV deve conter apenas uma coluna onde o conteúdo de cada cédula representará uma opção a ser escolhida pelo usuário. Recomenda-se um arquivo com até 3.000 linhas, arquivos maiores podem causar lentidão na aplicação",
        )}
        type="info"
        showIcon
        style={{ display: "flex", alignItems: "center", borderColor: "#91d5ff", backgroundColor: "#e6f7ff" }}
      />
      <p className="my-4 text-sm w-11/12 text-center">
        <Trans i18nKey={`${I18N_BASE_PATH}.components.description`}>
          Clique no botão abaixo para importar um arquivo <strong>CSV</strong> contendo as opções que poderão ser
          selecionadas pelo usuário
        </Trans>
      </p>
      {errorOnImportCSVFile && (
        <div className="mb-4">
          <Alert
            description={errorOnImportCSVFile.description}
            type={errorOnImportCSVFile.type}
            showIcon
            style={{ display: "flex", alignItems: "center" }}
          />
        </div>
      )}
      <div className="flex items-center justify-center my-2">
        <input
          id="importFieldsFromCSV"
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={importFieldsFromCSVFile}
          style={{ display: "none" }}
        />
        <label
          htmlFor="importFieldsFromCSV"
          className="w-2/6 h-full border-dashed border rounded-md flex items-center justify-center text-sm p-1 cursor-pointer hover:text-caf-primary transition duration-75 hover:border-caf-primary font-semibold"
        >
          <Icon
            icon="upload"
            size="lg"
            className={`mr-1 ${isUploadingCSVFile ? "transition-all animate-pulse text-blue-300" : ""}`}
          />
          {isUploadingCSVFile
            ? t("general.label.uploading", "Fazendo upload...")
            : t(`${I18N_BASE_PATH}.components.importCSVButton`, "Importar CSV")}
        </label>
      </div>
    </>
  );
};

export default TemplateImportFieldsFromCSVFileBody;
