import { Icon } from "@combateafraude/react";
import classNames from "classnames";

const StepIcon = ({ Src, className }) => {
  if (!Src) return <></>;

  if (typeof Src === "string") {
    return <Icon icon={Src} className={className} />;
  }

  return <Src className={classNames(className, "text-2xl")} />;
};

export default StepIcon;
