import { defaultOnboardingOptions } from "./defaultOnboardingOptions";

const defaultValues = {
  realOnboardingOptions: undefined,
  onboardingOptions: defaultOnboardingOptions?.["pt-BR"]?.defaultPFOnboardingOptions,
  setOnboardingOptions: (newOnboardingOptions, instant = false, initial = false) => {},
  templateTheme: undefined,
  setTemplateTheme: (newValue = "") => {},
  isTemplateDefault: undefined,
  setIsTemplateDefault: (newValue = "") => {},
  isUpdating: false,
  isSaved: true,
  onboardingLanguage: undefined,
};
Object.freeze(defaultValues);

export default defaultValues;
