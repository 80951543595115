import { Icon } from "@combateafraude/react";
import { TemplateImportFieldsFromCSVFileBody } from "@components/Modal";
import { useModal } from "@contexts/Modal";
import { useOnboardingStepsContext } from "@contexts/OnboardingSteps";
import { Button as AntButton, message } from "antd";
import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const I18N_BASE_PATH = "src.components.uploadFieldsFromCSVFile";

/** Upload fields from a CSV file and set its values */
const UploadFieldsFromCSVFile = ({ stepIndex, objectFieldValueKey }) => {
  const { t } = useTranslation();

  const { onboardingSteps, setOnboardingSteps } = useOnboardingStepsContext();
  const openImportFieldsFromCSVFile = useModal();
  const [CSVFilesNames, setCSVFilesNames] = useState([]);

  const CSVFileName = useMemo(() => {
    return CSVFilesNames?.find((file) => file?.id === stepIndex)?.name;
  }, [CSVFilesNames]);

  const setObjects = (objects) => {
    let _onboardingSteps = cloneDeep(onboardingSteps);
    set(_onboardingSteps[stepIndex], objectFieldValueKey, objects);
    setOnboardingSteps(_onboardingSteps, true);
  };

  return (
    <>
      {!CSVFilesNames?.some((file) => file?.id === stepIndex && file?.name) ? (
        <>
          <AntButton
            size="small"
            title={t(`${I18N_BASE_PATH}.components.button.importFile.title`, "Importar opções via CSV")}
            type="dashed"
            onClick={() =>
              openImportFieldsFromCSVFile(
                t(
                  `${I18N_BASE_PATH}.components.button.importFile.importFieldsFromCSVFile`,
                  "Importar opções através de um arquivo CSV",
                ),
                <TemplateImportFieldsFromCSVFileBody
                  objectIndex={stepIndex}
                  objectFieldValueKey={objectFieldValueKey}
                  setObjects={setObjects}
                  setCSVFilesNames={setCSVFilesNames}
                />,
              )
            }
            className="!p-0 !mt-1"
          >
            {t(`${I18N_BASE_PATH}.components.button.importFile.content`, "Importar CSV")}
          </AntButton>
        </>
      ) : (
        <div className="flex items-center gap-1 !mt-0">
          <span title={CSVFileName} className="truncate">
            {CSVFileName}
          </span>
          <AntButton
            onClick={(e) => {
              e.preventDefault();

              const CSVFilesNamesUpdated = CSVFilesNames?.filter((file) => file?.id !== stepIndex);
              setCSVFilesNames(CSVFilesNamesUpdated);

              let _onboardingSteps = cloneDeep(onboardingSteps);
              set(_onboardingSteps[stepIndex], objectFieldValueKey, []);
              setOnboardingSteps(_onboardingSteps, true);

              message.success(
                t(
                  `${I18N_BASE_PATH}.components.button.fileImported.fileRemoved`,
                  "As opções importadas do arquivo foram removidas com sucesso",
                ),
              );
            }}
            title={t(`${I18N_BASE_PATH}.components.button.fileImported.title`, "Remover arquivo")}
            icon={
              <AntButton
                type="default"
                danger
                size="small"
                shape="circle"
                icon={<Icon icon="close" size="sm" />}
                className="!mb-1"
              />
            }
            className="!p-0"
          />
        </div>
      )}
    </>
  );
};

export default UploadFieldsFromCSVFile;
