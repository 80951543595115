import Loader from "@components/Loader";
import useAPI, { requestMethods } from "@hooks/useAPI";
import { message } from "antd";
import isEqual from "lodash.isequal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const I18N_BASE_PATH = "src.components.modal.bodies.templateCreateOnboardingBody.steps.theme";

const ThemeCardItem = ({ theme, themeSelected, onSelectTheme }) => {
  const { t } = useTranslation();

  return (
    <button
      className={`flex flex-col gap-1 items-center relative justify-center p-4 border-gray-200 border-2 rounded-lg focus:outline-none duration-200 transition-all ease-in-out delay-75 ${
        isEqual(themeSelected, theme?._id) ? "!border-caf-primary bg-blue-50 shadow-xl" : "border"
      }`}
      onClick={onSelectTheme}
    >
      <img
        src={theme?.image}
        alt={t(`${I18N_BASE_PATH}.themeCardAltImage`, `Tema de Onboarding ${theme?.name}`, {
          themeTitle: theme?.name,
        })}
        className="w-72"
      />
      <span className="font-bold text-base leading-4">{theme?.name}</span>
    </button>
  );
};

const Theme = ({ onboardingData, setOnboardingData, setCanGoForward, setNextStepButtonTitle }) => {
  const { t } = useTranslation();
  const [getThemesListRequest, isLoadingThemesListRequest] = useAPI();
  const [themes, setThemes] = useState([]);

  useEffect(() => setCanGoForward(!!onboardingData?.theme), [onboardingData]);
  useEffect(
    () =>
      setNextStepButtonTitle(
        !onboardingData?.theme && t(`${I18N_BASE_PATH}.nextStepButtonTitle`, "Selecione um tema para continuar"),
      ),
    [onboardingData],
  );

  useEffect(async () => {
    try {
      let url = `/onboarding-builder/themes?language=${onboardingData?.language}`;
      if (onboardingData?.templateModel === "FA") {
        url += "&category=FACE_AUTHENTICATOR";
        url += "&type=PF";
      } else {
        url += `&type=${onboardingData?.templateModel}`;
      }
      const resultThemes = await getThemesListRequest(url, requestMethods.GET);

      if (resultThemes?.items) setThemes(resultThemes?.items);
    } catch (error) {
      message.error(
        t(`${I18N_BASE_PATH}.hooks.errorOnGetThemes`, "Não foi possível buscar os temas, por favor tente novamente"),
      );
    }
  }, []);

  return (
    <>
      {isLoadingThemesListRequest && (
        <Loader isHidden={false} text={t("general.label.loadingThemes", "Buscando temas...")} />
      )}
      <ul className="grid grid-cols-2 justify-center gap-9 max-h-80 overflow-y-auto pr-5">
        {themes?.map((theme) => (
          <ThemeCardItem
            key={theme?._id}
            theme={theme}
            themeSelected={onboardingData?.theme}
            onSelectTheme={() => setOnboardingData((old) => ({ ...old, theme: theme?._id }))}
          />
        ))}
      </ul>
    </>
  );
};

export default Theme;
