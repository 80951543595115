import Onboarding from "@package/steps";
import dagre from "dagre";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import set from "lodash.set";
import union from "lodash.union";
import xor from "lodash.xor";
import { getIncomers, getOutgoers, isEdge, isNode } from "react-flow-renderer";

import { removeKey } from ".";
import { Logger } from "./logging";
import { parseStep } from "./onboarding";

const { steps } = Onboarding;

/** Returns the smaller distance between an element id to the final step ("DONE") */
const getDistance = (elements = [], destinationId = "", startId = "DONE-0") => {
  let start = elements.find((el) => el.id?.includes(startId));
  if (!start) {
    Logger.warn("Start was not found in getDistance:", startId);
    return null;
  }

  let head = start;
  let distance = 0;
  let reachedEnd = false;

  while (!reachedEnd) {
    if (head.id === destinationId) reachedEnd = true;
    else {
      let incomers = getIncomers(head, elements);
      if (!incomers?.length) {
        return null;
      } else if (incomers.length === 1) {
        head = incomers[0];
        distance++;
      } else {
        distance++;
        let incomersDistance = incomers
          .map((incomer) => getDistance(elements, destinationId, incomer.id))
          .filter((_distance) => _distance != null)
          .reduce((prev, curr) => (prev > curr ? prev : curr), -1);
        if (incomersDistance === -1) {
          return null;
        } else {
          distance += incomersDistance;
          reachedEnd = true;
        }
      }
    }
  }
  return distance;
};

/** Converts a step to react-flow-renderer element */
export const stepToElement = (step, position) => {
  return {
    id: step.name,
    data: { step },
    position,
    type: "defaultStep",
    targetPosition: "left",
    sourcePosition: "right",
  };
};

/** Converts a list of steps to a list of react-flow-renderer elements */
export const stepsToElements = (steps = []) => {
  let edges = [];
  steps.reverse().forEach((step, i, reversedSteps) => {
    let newEdges = [];

    if (!step.flow?.length) {
      // If no flow, adds the first enabled step
      if (step?.isEnabled) {
        for (let index = i + 1; index <= reversedSteps.length; index++) {
          let possibleStep = reversedSteps[index];
          if (possibleStep && possibleStep.isEnabled) {
            newEdges.push({
              id: `${possibleStep?.name}->${step.name}`,
              source: possibleStep.name,
              target: step.name,
              arrowHeadType: "arrow",
              type: "stepEdge",
            });
            break;
          }
        }
      } else {
        for (let index = i + 1; index <= reversedSteps.length; index++) {
          let possibleStep = reversedSteps[index];
          if (possibleStep && !possibleStep.isEnabled) {
            newEdges.push({
              id: `${possibleStep?.name}->${step.name}`,
              source: possibleStep.name,
              target: step.name,
              arrowHeadType: "arrow",
              type: "stepEdge",
            });
            break;
          }
        }
      }
    } else {
      let addedFlows = [];
      // If there are flows, tries to find the next step to add that contains this flow, or is an option in a FLOW_CHOICE step
      step.flow?.forEach((flow) => {
        if (addedFlows.includes(flow)) return;
        for (let index = i + 1; index <= reversedSteps.length; index++) {
          let possibleStep = reversedSteps[index];

          if (possibleStep?.name?.includes("CHOICE")) {
            let options = possibleStep.options?.map((opt) => opt.flow);
            if (options.includes(flow)) {
              newEdges.push({
                id: `${possibleStep?.name}->${step.name}(${flow})`,
                source: possibleStep.name,
                target: step.name,
                sourceHandle: flow,
                arrowHeadType: "arrow",
                type: "stepEdge",
              });
              addedFlows.push(flow);
              break;
            }
          } else if (possibleStep?.flow?.includes(flow)) {
            let sameFlows = possibleStep.flow.filter(
              (_flow) => step.flow.includes(_flow) && !addedFlows.includes(_flow),
            );
            if (sameFlows?.length) {
              newEdges.push({
                id: `${possibleStep?.name}->${step.name}`,
                source: possibleStep.name,
                target: step.name,
                arrowHeadType: "arrow",
                type: "stepEdge",
              });
              addedFlows.push(...sameFlows);
              break;
            }
          }
        }
      });
    }

    if (newEdges.length) edges.push(...newEdges);
  });

  let newElements = [
    // Adds the elements
    ...steps.reverse().map((step, i) => stepToElement(step, { x: 300 * i, y: 200 * i })),
    // Adds the edges
    ...edges,
  ];
  newElements = updateElementStepData(newElements);
  newElements = layoutElements(newElements);
  return newElements;
};

/** Returns a list of step names that have a direct or indirect connection to the "DONE" step */
const getEnabledStepsFromElements = (elements = []) => {
  let head = elements.find((el) => el.id?.includes("DONE"));
  if (!head) return [];
  let enabledSteps = [];
  let reachedEnd = false;

  let minifyStep = (step) => ({
    id: step.id,
    ...(step.data.step.flow && { flow: step.data.step.flow }),
    ...(step.data.step.options && { options: step.data.step.options.map((opt) => opt.flow) }),
  });

  // If no flows, returns the first incomers line
  if (!head.data?.step?.flow?.length) {
    while (!reachedEnd) {
      enabledSteps.push(minifyStep(head));
      let incomers = getIncomers(head, elements);
      if (incomers?.length > 0) {
        head = incomers[0];
      } else {
        reachedEnd = true;
      }
    }
  } else {
    enabledSteps.push(minifyStep(head));

    let getElementsFromFlowAndFlowChoiceFlows = (flow = "") => {
      let flowChoiceElement = elements.filter((el) =>
        el.data?.step?.options?.map((opt) => opt.flow)?.includes(flow),
      )[0];

      let elementsFromFlow = elements.filter((el) => el.data?.step?.flow?.includes(flow));
      // Orders elements by distance to end
      elementsFromFlow = elementsFromFlow
        .map((el) => ({ ...el, distanceToEnd: getDistance(elements, el.id) }))
        .sort((a, b) => a.distanceToEnd - b.distanceToEnd)
        .map((el) => removeKey("distanceToEnd", el));

      return [
        // Returns elements with flow
        elementsFromFlow,
        // Returns the flowChoice element itself
        flowChoiceElement,
      ];
    };

    let flowsToEnable = [...head.data.step.flow];

    let flowChoiceWithoutFlow;

    while (flowsToEnable.length) {
      let [stepsToEnable, flowChoiceElement] = getElementsFromFlowAndFlowChoiceFlows(flowsToEnable.shift());

      if (stepsToEnable?.length)
        enabledSteps.push(
          ...stepsToEnable
            .map((step) => minifyStep(step))
            .filter((step) => !enabledSteps.find((_step) => _step.id === step.id)),
        );
      if (flowChoiceElement) {
        let flowChoiceElementFlow = flowChoiceElement.data?.step?.flow;
        if (flowChoiceElementFlow?.length) flowsToEnable.push(...flowChoiceElementFlow);
        else flowChoiceWithoutFlow = flowChoiceElement;
      }
    }

    // When found first element without a flow, go until the start
    if (flowChoiceWithoutFlow) {
      head = flowChoiceWithoutFlow;
      while (!reachedEnd) {
        enabledSteps.push(minifyStep(head));
        let incomers = getIncomers(head, elements);
        if (incomers?.length > 0) {
          head = incomers[0];
        } else {
          reachedEnd = true;
        }
      }
    }
  }

  // Reverses so DONE comes last
  enabledSteps = enabledSteps.reverse();

  // Sorts so elements with less of the same flow come earlier
  // e.g: [1(A), 2(A,B), 3(B)] turns into [1(A), 3(B), 2(A,B)] so 2 correctly comes after 3
  enabledSteps = enabledSteps.sort((a, b) => {
    if (a.flow && b.flow) {
      if (a.flow.some((r) => b.flow.includes(r)) || b.flow.some((r) => a.flow.includes(r)))
        return a.flow.length - b.flow.length;
      else return 0;
    } else {
      return 0;
    }
  });

  return enabledSteps.map((step) => step.id);
};

/** Returns a list of steps from a list of elements */
export const elementsToSteps = (elements = []) => {
  let enabledSteps = getEnabledStepsFromElements(elements);

  return [
    ...enabledSteps.map((enabledEl) => ({ ...elements.find((el) => el.id === enabledEl).data.step, isEnabled: true })),
    ...elements
      .filter((el) => isNode(el) && !enabledSteps.includes(el.id))
      .map((el) => ({
        ...el.data.step,
        isEnabled: false,
        fields: !el?.data?.step.fields ? el?.data?.step?.defaultFields ?? [] : el?.data?.step.fields,
      })),
  ];
};

/**
 * Updates edges when flowChoice's options change.
 *
 * Edges with deleted flows will be deleted
 * Edges which flows have been changed will update to the new value
 */
const updateFlowChoiceEdges = (elements = [], steps = []) => {
  let flowChoiceEdges = elements.filter((el) => isEdge(el) && el.sourceHandle);
  // Nothing to update if there are no flowChoice edges
  if (!flowChoiceEdges?.length) return elements;

  let flowChoiceSteps = steps.filter((step) => step.name.includes("CHOICE"));
  // Nothing to update if there are no flowChoice steps
  if (!flowChoiceSteps?.length) return elements;

  let elementsFlows = elements
    .filter((el) => isNode(el) && el.id.includes("CHOICE"))
    .reduce((acc, el) => {
      let flows = el?.data?.step?.options?.map((opt) => opt.flow);
      if (flows) acc.push(...flows);
      return acc;
    }, []);
  let stepsFlows = flowChoiceSteps.reduce((acc, step) => {
    let flows = step?.options?.map((opt) => opt.flow);
    if (flows) acc.push(...flows);
    return acc;
  }, []);
  let flowsDifference = xor(elementsFlows, stepsFlows);

  if (flowsDifference.length) {
    if (flowsDifference.length === 1) {
      // New flow just added, no change needed
      if (stepsFlows.length > elementsFlows.length) return elements;
      // Flow deleted, remove edges related to it
      else flowChoiceEdges = flowChoiceEdges.filter((edge) => edge.sourceHandle !== flowsDifference[0]);
    } else {
      // First element is wrong flow in elementsFlow, second the correct in stepsFlow
      let [oldFlow, newFlow] = flowsDifference;
      if (!oldFlow || !newFlow) return elements;
      // Replaces oldFlow with newFlow
      flowChoiceEdges = flowChoiceEdges.map((edge) => {
        if (edge.sourceHandle === oldFlow) edge.sourceHandle = newFlow;
        return edge;
      });
    }
    elements = [...elements.filter((el) => isNode(el) || !el.sourceHandle), ...flowChoiceEdges];
  }

  return elements;
};

/** Updates the "flow" property of steps */
const updateElementsStepDataFlow = (elements = []) => {
  let flowChoicesEdges = elements.filter((el) => isEdge(el) && el.sourceHandle);

  // Resets the flows
  let newElements = elements.map((el) => set(el, "data.step.flow", undefined));
  if (!flowChoicesEdges?.length) return newElements;

  let setFlowUntilNextChoice = (flow = "", headElementId = "", elements) => {
    let head = elements.find((el) => el.id === headElementId);
    while (true) {
      // eslint-disable-next-line no-loop-func
      newElements = newElements.map((el) => {
        if (el.id === head.id)
          el.data = {
            ...el.data,
            // Appends the flow
            step: { ...el.data.step, flow: [...union(el.data.step.flow || [], [flow])] },
          };
        return el;
      });
      if (head.id?.includes("CHOICE")) return newElements;
      let outgoers = getOutgoers(head, elements);
      if (outgoers?.length) {
        head = outgoers[0];
      } else return newElements;
    }
  };

  flowChoicesEdges.forEach(
    (edge) => (newElements = setFlowUntilNextChoice(edge.sourceHandle, edge.target, newElements)),
  );
  return newElements;
};

/** Don't allow user to remove step "DONE"  */
export const doNotAllowRemoveDoneStep = (elements = []) =>
  elements?.some((element) => isEqual(parseStep(element?.id)[0], parseStep(steps.DONE.name)[0]));

/** Don't allow user to duplicate step "DONE"  */
export const doNotAllowDuplicateDoneStep = (stepName) => {
  const stepDoesNotDuplicate = "DONE";
  const [officialName] = parseStep(stepName);

  return isEqual(officialName, stepDoesNotDuplicate);
};

/** Updates the "data" property of elements  */
export const updateElementStepData = (elements = [], steps = []) => {
  let newElements = updateFlowChoiceEdges(cloneDeep(elements), steps);
  newElements = updateElementsStepDataFlow(newElements);
  let enabledSteps = getEnabledStepsFromElements(newElements);

  newElements = newElements.map((el) => {
    if (!isNode(el)) return el;
    el.data = {
      step: {
        ...(steps?.find((step) => step.name === el.id) || el.data.step),
        flow: el.data.step.flow,
        isEnabled: enabledSteps.includes(el.id),
      },
    };
    return el;
  });

  return newElements;
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

/** Returns a list of the elements with their positions correct */
export const layoutElements = (elements = []) => {
  dagreGraph.setGraph({ rankdir: "LR" });

  let nodeWidth = 260;
  elements.forEach((el) => {
    if (isNode(el)) {
      // Adds 50px for every option in FLOW_CHOICE steps
      let nodeHeight = 100 + (el.data?.step?.options?.length ? (el.data?.step?.options?.length - 1) * 50 : 0);
      dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = "left";
      el.sourcePosition = "right";

      let nodeHeight = 100 + (el.data?.step?.options?.length ? (el.data?.step?.options?.length - 1) * 50 : 0);
      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      el.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
    }

    return el;
  });
};
