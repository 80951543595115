import { randomHsl } from "@utils/colors";
import { createContext, useCallback, useContext, useState } from "react";

import defaultValues from "./defaultValues";

export const FlowContext = createContext(defaultValues);
export const useFlowContext = () => useContext(FlowContext);

/**
 * States used in the FlowEditor
 * */
export const FlowProvider = ({ children }) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [flowColors, setFlowColors] = useState({});
  const [elements, setElements] = useState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(undefined);
  const [reactFlowRef, setReactFlowRef] = useState(null);
  // Toggle for the flow view
  const [isFlowBuilder, setIsFlowBuilder] = useState(false);

  const getFlowColor = useCallback(
    (flow) => {
      if (flowColors[flow]) return flowColors[flow];
      else {
        let newColor = randomHsl();
        setFlowColors((old) => ({ ...old, [flow]: newColor }));
        return newColor;
      }
    },
    [flowColors, setFlowColors],
  );

  return (
    <FlowContext.Provider
      value={{
        isConnecting,
        setIsConnecting,
        getFlowColor,
        flowColors,
        isFlowBuilder,
        setIsFlowBuilder,
        elements,
        setElements,
        reactFlowInstance,
        setReactFlowInstance,
        reactFlowRef,
        setReactFlowRef,
      }}
    >
      {children}
    </FlowContext.Provider>
  );
};
