import { useEffect, useState } from "react";

/**
 * A state connected to the local storage.
 * When initially loaded, it will search for its "key" to load as value, and for every change it will update the localStorage.
 * Transformation can be used to check and transform the variable before settings it (as localStorage only accepts strings)
 */
const useLocalStorageState = (defaultValue, key, transformation = (val) => val) => {
  const [value, setValue] = useState(defaultValue);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    let val = localStorage.getItem(key);
    if (val) setValue(transformation(val));
  }, []);

  useEffect(() => {
    if (!isInitialized) setIsInitialized(true);
    else localStorage.setItem(key, value);
  }, [value]);

  return [value, setValue];
};

export default useLocalStorageState;
