import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import { createContext, useCallback, useContext, useEffect, useState } from "react";

import defaultValues from "./defaultValues";

/* * Stores variables to be used throughout the application and save them into template. */

export const OnboardingVariablesContext = createContext(defaultValues);

export const useOnboardingVariablesContext = () => useContext(OnboardingVariablesContext);

export const OnboardingVariablesProvider = ({ children }) => {
  const { onboardingOptions } = useOnboardingOptionsContext();

  const modifiers = defaultValues.modifiers;

  const [variables, setVariables] = useState(defaultValues.variables);
  const defaultVariables = defaultValues.variables;

  // Does not allow the user to type special characters
  const preventUserTypingInvalidCharacters = useCallback(({ char }) => {
    const pattern = "[a-zA-Z0-9]";

    if (!char.key.match(pattern)) {
      char.preventDefault();
    }
  }, []);

  // Clearing the state of standard variables and re-adding concatenated variables to user-created variables
  useEffect(() => {
    const variableNamesCreated = [];

    if (onboardingOptions?.variables?.length > 0) {
      onboardingOptions?.variables?.forEach((variable) => {
        if (variable?.variableName && variable?.variableValue) {
          variableNamesCreated.push(variable?.variableName);
        }
      });

      setVariables([...defaultVariables, ...variableNamesCreated]);
    }
  }, [onboardingOptions]);

  return (
    <OnboardingVariablesContext.Provider
      value={{ variables, setVariables, modifiers, defaultVariables, preventUserTypingInvalidCharacters }}
    >
      {children}
    </OnboardingVariablesContext.Provider>
  );
};
