import { Icon } from "@combateafraude/react";
import Loader from "@components/Loader";
import { useAPIContext } from "@contexts/API";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import useAPI, { requestMethods } from "@hooks/useAPI";
import { Logger } from "@utils/logging";
import { prepareOnboardingOptions, unprepareOnboardingOptions } from "@utils/onboarding";
import { Form, Input, message } from "antd";
import AntButton from "antd/es/button";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SimpleCrypto from "simple-crypto-js";

// Encrypts the configurations so its not possible to see or change the JSON
const simpleCrypto = new SimpleCrypto("builder");

const I18N_BASE_PATH = "src.components.modal.bodies.templateImportExportBody";

/** Modal body to import and export the template configurations */
const TemplateImportExportBody = ({
  atTemplateList = false,
  _templateId = undefined,
  setTemplatesShouldUpdate,
  close,
}) => {
  const { t } = useTranslation();

  const { tenantId, templateId } = useAPIContext();
  const { onboardingOptions, setOnboardingOptions, onboardingLanguage } = useOnboardingOptionsContext();

  const [getTemplateRequest] = useAPI();
  const [updateTemplateRequest] = useAPI();

  const [toExport, setToExport] = useState();
  const [toImport, setToImport] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(t(`${I18N_BASE_PATH}.states.loading`, "Carregando..."));
  const [haveChanged, setHaveChanged] = useState(false);

  // Update template options imported via another template
  const saveImportedConfigurations = useCallback(async () => {
    try {
      setIsLoading(true);
      setLoadingText(t(`${I18N_BASE_PATH}.methods.saveImportedSettings.updatingTemplate`, "Atualizando template..."));

      const payload = {
        ...simpleCrypto.decrypt(toImport),
        domain: `https://${process.env.REACT_APP_ONBOARDING_DEFAULT_DOMAIN}`,
        emailTemplateId: onboardingOptions?.emailTemplateId,
      };

      const result = await updateTemplateRequest(
        `/onboarding-builder/templates/${_templateId || templateId}?tenantId=${tenantId}`,
        requestMethods.PATCH,
        payload,
      );

      if (!!result) {
        setHaveChanged(false);
        message.success(
          t(
            `${I18N_BASE_PATH}.methods.saveImportedSettings.templateUpdated`,
            "Template atualizado e salvo com sucesso",
          ),
        );
        if (atTemplateList) setTemplatesShouldUpdate(true);
        close();
      }
    } catch (error) {
      message.error(
        t(
          `${I18N_BASE_PATH}.methods.saveImportedSettings.errorOnImportTemplate`,
          "Ocorreu um erro ao salvar as configurações importadas",
        ),
      );
    } finally {
      setIsLoading(false);
    }
  }, [
    tenantId,
    templateId,
    _templateId,
    simpleCrypto,
    onboardingOptions,
    prepareOnboardingOptions,
    requestMethods,
    updateTemplateRequest,
    setTemplatesShouldUpdate,
    close,
    t,
  ]);

  // Remove special template keys to export onboarding options and import at another template
  const deleteEspecialKeysFromTemplate = useCallback((optionsToExport) => {
    const keysToDeleteFromExport = ["_id", "tenantId", "createdAt", "updatedAt"];
    for (const key of keysToDeleteFromExport) {
      delete optionsToExport[key];
    }
  }, []);

  // If the modal is open on template list, get template data and sets it to import
  useEffect(async () => {
    if (atTemplateList) {
      setIsLoading(true);

      try {
        const { template } = await getTemplateRequest(
          `/onboarding-builder/templates/${_templateId}?tenantId=${tenantId}`,
        );

        deleteEspecialKeysFromTemplate(template);

        let encrypted = simpleCrypto.encrypt(template);
        Logger.console("Encrypted OnboardingOptions", encrypted);
        setToExport(encrypted);
      } catch (error) {
        Logger.console("OnboardingOptions encryption failed");
      } finally {
        setIsLoading(false);
      }
    } else {
      // If the modal is not open on template list, only sets the template options to import
      try {
        const templateOptionsToExport = { ...onboardingOptions };

        deleteEspecialKeysFromTemplate(templateOptionsToExport);

        let encrypted = simpleCrypto.encrypt(prepareOnboardingOptions(templateOptionsToExport, onboardingLanguage));
        Logger.console("Encrypted OnboardingOptions", encrypted);
        setToExport(encrypted);
      } catch (e) {
        Logger.console("OnboardingOptions encryption failed", e);
      }
    }
  }, []);

  useEffect(() => {
    if (toImport) {
      try {
        let decrypted = simpleCrypto.decrypt(toImport);
        Logger.console("Decrypted OnboardingOptions", decrypted);
        setOnboardingOptions(
          unprepareOnboardingOptions({
            ...decrypted,
            domain: onboardingOptions?.domain,
            emailTemplateId: onboardingOptions?.emailTemplateId,
          }),
          true,
        );
        message.success(t(`${I18N_BASE_PATH}.hooks.settingsImported`, "Configuração importada"));
        setHaveChanged(true);
      } catch (e) {
        Logger.console("OnboardingOptions decryption failed", e);
      }
    }
  }, [toImport]);

  return (
    <Form layout="vertical">
      {isLoading ? (
        <div className="h-full w-full flex flex-col items-center justify-center min-h-36">
          <Loader isHidden={false} text={loadingText} />
        </div>
      ) : (
        <>
          <Form.Item label={t("general.label.export", "Exportar")}>
            <Input
              value={toExport}
              disabled
              addonAfter={
                <AntButton
                  type="text"
                  size="small"
                  title={t(`${I18N_BASE_PATH}.components.copyButton.title`, "Copiar")}
                  className="cursor-pointer !bg-transparent"
                  onClick={() =>
                    navigator.clipboard
                      .writeText(toExport)
                      .then(() =>
                        message.success(t(`${I18N_BASE_PATH}.components.copyButton.clipboard`, "Configuração copiada")),
                      )
                  }
                  icon={<Icon icon="download" size="sm" />}
                />
              }
            />
          </Form.Item>
          <Form.Item label={t(`${I18N_BASE_PATH}.components.fields.import.label`, "Importar")}>
            <Input
              placeholder={t(
                `${I18N_BASE_PATH}.components.fields.import.placeholder`,
                "Cole a configuração exportada aqui",
              )}
              value={toImport}
              onChange={(e) => setToImport(e.target.value)}
            />
          </Form.Item>
          <div className="flex justify-end items-center w-full pt-4 pb-1">
            <AntButton className="!mr-2" type="text" onClick={close} htmlType="button">
              {t("general.label.cancel", "Cancelar")}
            </AntButton>
            <AntButton type="primary" onClick={saveImportedConfigurations} disabled={!haveChanged} loading={isLoading}>
              {t("general.label.save", "Salvar")}
            </AntButton>
          </div>
        </>
      )}
    </Form>
  );
};

export default TemplateImportExportBody;
