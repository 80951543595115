import { Icon } from "@combateafraude/react";
import { Button as AntButton } from "antd";

const FloatingMenuButton = ({ icon, children, ...rest }) => (
  <AntButton type="text" block className="!text-left !bg-transparent !shadow-none" {...rest}>
    {icon && <Icon icon={icon} size="md" className="mr-2" />}
    {children}
  </AntButton>
);

export default FloatingMenuButton;
