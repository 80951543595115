import StepsList from "@components/StepsList";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import { useTranslation } from "react-i18next";

import PaneTitle from "../PaneTitle";

const I18N_BASE_PATH = "src.components.sidebar";

const Flow = () => {
  const { t } = useTranslation();
  const { isTemplateDefault } = useOnboardingOptionsContext();

  return (
    <>
      <PaneTitle title={t("general.label.flow", "Fluxo")} />
      {isTemplateDefault && <StepsList />}
      {!isTemplateDefault && (
        <div className="p-3 min-h-32">
          <p>
            {t(
              `${I18N_BASE_PATH}.components.tabs.flow.components.unableToAddNewSteps`,
              "Desculpe, ainda não é possível adicionar novas etapas neste template! :(",
            )}
          </p>
        </div>
      )}
    </>
  );
};

export default Flow;
