import isEqual from "lodash.isequal";
import { useCallback, useEffect, useState } from "react";

/** useState that allows for comparing if the value has changed over previously saved value */
const useSaveableState = (initialValue) => {
  const [value, setRealValue] = useState(initialValue);
  const [savedValue, setSavedValue] = useState(initialValue);
  const [isValueSaved, setIsValueSaved] = useState(false);

  const setValue = useCallback(
    (newValue, isSaved = false) => {
      setRealValue(newValue);
      // Avoids coercion
      if (isSaved === true) setSavedValue(newValue);
    },
    [setRealValue],
  );

  useEffect(() => {
    setIsValueSaved(isEqual(value, savedValue));
  }, [value, savedValue]);

  return [value, setValue, isValueSaved];
};

export default useSaveableState;
