import { authenticationUtils } from "@utils/auth/utils";
import { Logger } from "@utils/logging";
import Cookies from "js-cookie";
import { createContext, useCallback, useContext, useEffect } from "react";
import api from "@services/api";

const { AUTHENTICATION_COOKIES_OPTIONS, formatTokenBasedOnPlatform, validateToken } = authenticationUtils;

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const isUserAuthenticated = useCallback(() => !!validateToken()?.token, [validateToken]);

  // Get the tokens from cookies to validate the user
  useEffect(() => {
    const { token } = validateToken();

    if (!!token) {
      api.defaults.headers.Authorization = token;
    }
  }, []);

  // Update the authentications data in cookies
  const saveProfileCredentials = useCallback((_token = "", refreshToken = "") => {
    if (!_token && !refreshToken) {
      return Logger.warn("Tried to update the profile credentials with empty token data");
    }

    const { platform } = validateToken();
    let token = formatTokenBasedOnPlatform(_token, platform);

    if (platform === "bifrost") {
      Cookies.set(
        AUTHENTICATION_COOKIES_OPTIONS.BIFROST_ID_TOKEN,
        token,
        AUTHENTICATION_COOKIES_OPTIONS.COOKIE_OPTIONS,
      );
      Cookies.set(
        AUTHENTICATION_COOKIES_OPTIONS.BIFROST_REFRESH_TOKEN,
        refreshToken,
        AUTHENTICATION_COOKIES_OPTIONS.COOKIE_OPTIONS,
      );
    } else if (platform === "auth") {
      Cookies.set(AUTHENTICATION_COOKIES_OPTIONS.AUTH_ID_TOKEN, token, AUTHENTICATION_COOKIES_OPTIONS.COOKIE_OPTIONS);
      Cookies.set(
        AUTHENTICATION_COOKIES_OPTIONS.AUTH_REFRESH_TOKEN,
        refreshToken,
        AUTHENTICATION_COOKIES_OPTIONS.COOKIE_OPTIONS,
      );
    }

    if (!!token) {
      api.defaults.headers.Authorization = token;
    }
  }, []);

  const refreshUserToken = useCallback(async () => {
    const { platform, refreshToken } = validateToken();

    if (!!refreshToken) {
      return api
        .post(
          platform === "bifrost"
            ? `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API_V1}onboarding-builder/bifrost/refresh`
            : platform === "auth" && `${process.env.REACT_APP_BASE_URL_AUTH_API}token`,
          {
            // Bifrost parameters
            refreshToken: platform === "bifrost" ? refreshToken : undefined,
            // Auth parameters
            refresh_token: platform === "auth" ? refreshToken : undefined,
            grant_type: platform === "auth" ? "refresh_token" : undefined,
          },
        )
        .then((res) => {
          const {
            // Bifrost data
            idToken: bifrostToken,
            refreshToken: bifrostRefreshToken,
            // Auth data
            access_token: authToken,
            refresh_token: authRefreshToken,
          } = (platform === "bifrost" ? res?.data?.response : res?.data) || {};

          saveProfileCredentials(bifrostToken || authToken, bifrostRefreshToken || authRefreshToken);

          return res.data;
        });
    }

    return null;
  }, [saveProfileCredentials]);

  return (
    <AuthContext.Provider
      value={{
        isUserAuthenticated,
        saveProfileCredentials,
        refreshUserToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};
