import { Button as CafButton, Switch } from "@combateafraude/react";
import { Icon } from "@combateafraude/react";
import CodeEditor from "@components/CodeEditor";
import Loader from "@components/Loader";
import Tabs from "@components/Tabs";
import { useAnalytics } from "@contexts/Analytics";
import { useAPIContext } from "@contexts/API";
import { useLoader } from "@contexts/Loader";
import useAPI, { requestMethods } from "@hooks/useAPI";
import useLocalStorageState from "@hooks/useLocalStorageState";
import useSaveableState from "@hooks/useSaveableState";
import useTempURL from "@hooks/useTempURL";
import useTimeout from "@hooks/useTimeout";
import useUpdateEffect from "@hooks/useUpdateEffect";
import { getTempKey } from "@utils/";
import { isItAValidEmail } from "@utils/valid";
import { Input, message, Tooltip } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { defaultEmailTemplate } from "../DefaultEmailTemplate";

const I18N_BASE_PATH = "src.pages.email.builder";

function Builder({ tenantId, templateId }) {
  const { t } = useTranslation();

  const { language } = useAPIContext();
  const { logAnalyticsTracking, analyticsEvents, analytics } = useAnalytics();

  const [startLoader, stopLoader, isLoaderLoading] = useLoader();
  const [htmlValue, setHtmlValue, isHtmlValueSaved] = useSaveableState();
  const [emailValue, setEmailValue, isEmailValueSaved] = useSaveableState("");
  const [getTemplateRequest, getTemplateLoading] = useAPI();
  const [getEmailIdentities] = useAPI();
  const [createEmailRequest] = useAPI();

  const [htmlUploadRequest, htmlUploadLoading] = useTempURL();
  const [updateTemplateRequest, updateTemplateLoading] = useAPI();

  const [template, setTemplate] = useState();
  const [emailExists, setEmailExists] = useState(false);
  const [initialSourceProperty, setInitialSourceProperty] = useState(undefined);

  useEffect(() => {
    logAnalyticsTracking(analyticsEvents.ACCESS_EMAIL_TEMPLATE);
  }, [analytics]);

  useEffect(async () => {
    if (tenantId && templateId) {
      let newTemplate = await getTemplateRequest(`/accounts/${tenantId}/email-templates/${templateId}`);
      if (newTemplate) {
        setTemplate(newTemplate);
        // Get the HTML source from the AWS URL
        let _htmlValue = await fetch(newTemplate.template, {
          method: requestMethods.GET,
        }).then((res) => res.text());
        setHtmlValue(_htmlValue, true);

        if (newTemplate?.source) {
          setInitialSourceProperty(newTemplate?.source);
          setEmailValue(newTemplate?.source, true);
        }
      } else {
        message.error(t("general.label.unableToRetrieveTemplate", "Não foi possível recuperar o template"));
        setTemplate(defaultEmailTemplate["pt-BR"]);
        setHtmlValue(defaultEmailTemplate["pt-BR"].template, true);
        setEmailValue("", true);
        setInitialSourceProperty(undefined);
      }
    } else {
      setTemplate(defaultEmailTemplate["pt-BR"]);
      setHtmlValue(defaultEmailTemplate["pt-BR"].template);
      setEmailValue("");
      setInitialSourceProperty(undefined);
    }
  }, [tenantId, templateId, t]);

  const updateTemplate = useCallback(async () => {
    let valueToSave = htmlValue;
    if (!valueToSave?.includes('href="{{onboardingLink}}"'))
      message.error(
        t(
          `${I18N_BASE_PATH}.methods.updateTemplate.validLinkHref`,
          'O template necessita de um link com href "{{onboardingLink}}"',
        ),
      );
    else {
      let success = false;
      let htmlUrls = await htmlUploadRequest(valueToSave, "text/html; charset='UTF-8'");
      if (htmlUrls) {
        let payload = { templateKey: getTempKey(htmlUrls.getUrl) };

        if (isItAValidEmail(emailValue)) {
          payload = { ...payload, source: emailValue };
        }

        let result = await updateTemplateRequest(
          `/accounts/${tenantId}/email-templates/${templateId}`,
          requestMethods.PATCH,
          payload,
        );
        success = !!result;
      }

      if (isItAValidEmail(emailValue) && !emailExists) {
        const response = await createEmailRequest("/email-identities", requestMethods.POST, { email: emailValue });

        if (response) {
          setEmailExists(true);
          setEmailValue(emailValue, true);
        } else {
          message.error(
            t(`${I18N_BASE_PATH}.methods.updateTemplate.emailFailureOnSave`, "Não foi possível salvar o email"),
          );
        }
      }
      if (success) {
        message.success(t(`${I18N_BASE_PATH}.methods.updateTemplate.successfullySaved`, "Salvo com sucesso"));
        setHtmlValue(htmlValue, true);
      } else message.error(t(`${I18N_BASE_PATH}.methods.updateTemplate.failureOnSave`, "Não foi possível salvar"));
    }
  }, [htmlValue, updateTemplateRequest, t, emailValue, emailExists]);

  // Manages the Loader
  useUpdateEffect(() => {
    if (getTemplateLoading) startLoader();
    else if (htmlUploadLoading) startLoader(t("general.label.sendingHTML", "Enviando HTML..."));
    else if (updateTemplateLoading) startLoader(t("general.label.savingTemplate", "Salvando template..."));
    else stopLoader(500);
  }, [getTemplateLoading, htmlUploadLoading, updateTemplateLoading]);

  const [wrapLines, setWrapLines] = useLocalStorageState(true, "wrapLines", (val) => val === "true");
  const [fontSize, setFontSize] = useLocalStorageState(12, "fontSize", (val) =>
    parseInt(val) >= 10 && parseInt(val) <= 30 ? parseInt(val) : 12,
  );

  const saveButtonIsDisabled = useMemo(() => {
    if (isItAValidEmail(emailValue)) return isHtmlValueSaved && isEmailValueSaved;

    return isHtmlValueSaved;
  }, [isHtmlValueSaved, isEmailValueSaved, emailValue]);

  // Deals with iframe flickering on value change
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const { callbackOnTimeout } = useTimeout();
  useEffect(() => {
    setIsIframeLoading(true);
    callbackOnTimeout(() => setIsIframeLoading(false), 50);
  }, [htmlValue]);

  useEffect(() => {
    const doCallAPI = async () => {
      if (isItAValidEmail(emailValue)) {
        const response = await getEmailIdentities(`/email-identities?email=${emailValue}`);
        setEmailExists(response?.exists);
      }
    };
    doCallAPI();
  }, [emailValue]);

  if (!language) {
    return <Loader isHidden={false} />;
  }

  return (
    <div
      className="caf-components relative flex flex-col w-screen h-screen items-center"
      style={{ background: "#F8F8F8" }}
    >
      <div className="flex justify-between w-full p-9">
        <div className="flex flex-col">
          <h1 className="text-3xl font-black">{template?.name}</h1>
          <span className="text-lg font-normal">{template?.description}</span>
        </div>
        <div className="flex flex-row-reverse items-end">
          {tenantId && templateId && (
            <CafButton
              className="!h-10 min-w-40"
              type="primary"
              loading={isLoaderLoading}
              onClick={updateTemplate}
              disabled={saveButtonIsDisabled}
            >
              {t(`general.label.${saveButtonIsDisabled ? "saved" : "save"}`, saveButtonIsDisabled ? "Salvo" : "Salvar")}
            </CafButton>
          )}
        </div>
      </div>
      <div className="flex bg-white w-full h-36 p-9 border-t border-b " style={{ borderColor: "rgba(0, 0, 0, 0.1)" }}>
        <aside className="flex flex-col h-full items-start justify-items-center" style={{ width: "403px" }}>
          <div>
            <label htmlFor="email_input" className="font-semibold text-lg text-gray-500">
              {t(`${I18N_BASE_PATH}.components.emailInput.label`, "Personalização do remetente")}
            </label>
            <Tooltip
              title={
                <div className="flex items-center px-2">
                  <span className="font-normal text-xs">
                    {t(
                      `${I18N_BASE_PATH}.components.emailInput.tooltip`,
                      "Se este campo não for preenchido o destinatário receberá o email com o nosso remetente padrão",
                    )}
                    <strong className="pl-1 font-bold">no-reply@caf.io</strong>
                  </span>
                </div>
              }
            >
              <span className="pl-2 bg-none">
                <Icon icon="tooltip" size="sm" />
              </span>
            </Tooltip>
          </div>
          <Input
            status={initialSourceProperty !== emailValue && isItAValidEmail(emailValue) && !emailExists && "warning"}
            prefix={
              <Icon
                icon="mail"
                size="md"
                className={
                  initialSourceProperty !== emailValue && !emailExists && isItAValidEmail(emailValue)
                    ? "#F8B239"
                    : "text-blue-900"
                }
              />
            }
            suffix={
              initialSourceProperty !== emailValue &&
              isItAValidEmail(emailValue) &&
              emailExists && <Icon icon={"check_d"} size="md" className={"text-blue-900"} />
            }
            id="email_input"
            size="large"
            type="email"
            placeholder="no-reply@suaempresa.com"
            value={emailValue}
            onChange={(event) => {
              setEmailValue(event.target.value);
            }}
          />
          {initialSourceProperty !== emailValue && isItAValidEmail(emailValue) > 0 && !emailExists && (
            <span className="font-semibold text-xs leading-3 pt-2" style={{ color: "#F8B239" }}>
              {t(`${I18N_BASE_PATH}.components.emailInput.footerMessage`, "O email ainda não está cadastrado.")}
            </span>
          )}
        </aside>
        <aside className="pl-5 flex items-end h-full" style={{ width: "35rem" }}>
          <span className="h-10 flex items-center text-base leading-5 font-normal text-gray-500">
            {t(
              `${I18N_BASE_PATH}.components.asideText.description`,
              'O email informado precisa "existir" e será enviado uma mensagem de autorização de uso para ele. Certifique-se de ter acesso antes do registro.',
            )}
          </span>
        </aside>
      </div>
      <div className="flex content-between space-x-3 w-full h-full gap-10 p-9">
        <Tabs>
          <Tabs.TitleBar
            titles={[t(`${I18N_BASE_PATH}.components.tabs.htmlEditor.title`, "Editor de HTML")]}
            extras={
              <>
                <div className="flex gap-3 items-center p-px pl-1.5 font-semibold text-sm">
                  <label htmlFor="fonstSize_input">
                    {t(`${I18N_BASE_PATH}.components.tabs.htmlEditor.extras.font`, "Tamanho da fonte")}
                  </label>
                  <Input
                    id="fonstSize_input"
                    size="small"
                    type="number"
                    className="!w-14"
                    value={fontSize}
                    onChange={(e) => {
                      let val = e.target.value && parseInt(e.target.value);
                      if (val && val >= 10 && val <= 30) setFontSize(val);
                    }}
                  />
                </div>
                <div className="flex gap-3 items-center py-px px-1.5 font-semibold text-sm">
                  <label htmlFor="wrapLines_switch" className="cursor-pointer">
                    {t(`${I18N_BASE_PATH}.components.tabs.htmlEditor.extras.wrapLines`, "Quebrar linhas")}
                  </label>
                  <Switch id="wrapLines_switch" size="default" checked={wrapLines} onChange={setWrapLines} />
                </div>
              </>
            }
          />
          <Tabs.Content>
            <CodeEditor
              {...{ value: htmlValue, setValue: setHtmlValue, wrapLines, fontSize, isLoading: isLoaderLoading }}
            />
          </Tabs.Content>
        </Tabs>
        <Tabs>
          <Tabs.TitleBar titles={[t(`${I18N_BASE_PATH}.components.tabs.preview.title`, "Pré-visualização")]} />
          <Tabs.Content>
            <iframe
              title={t(`${I18N_BASE_PATH}.components.tabs.preview.iframe`, "Email preview")}
              srcDoc={htmlValue}
              width="100%"
              height="100%"
              className={isIframeLoading ? "hidden" : "visible"}
            />
          </Tabs.Content>
        </Tabs>
      </div>
    </div>
  );
}

export default Builder;
