import {
  formatListContainsOnlyOneItem,
  templateStepsOrderValidationsRules,
  validateTemplateStepsOrderBasedOnEachFlow,
} from "@utils/template-validations/steps-validations/utils";
import {
  templateValidationsMessages,
  validateTemplateMessages,
} from "@utils/template-validations/templateValidationsMessages";
import isEqual from "lodash.isequal";

export const sendDocumentTypeStepMustBeBeforeDDStep = (
  sendDocumentTypeFlowsStepsIndexes = [],
  DDFlowsStepsIndexes = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  const sendDocumentTypeIsInFrontOfDDValidations = validateTemplateStepsOrderBasedOnEachFlow(
    [sendDocumentTypeFlowsStepsIndexes, DDFlowsStepsIndexes],
    templateStepsOrderValidationsRules.BETWEEN_TWO_STEPS,
  );

  if (!sendDocumentTypeIsInFrontOfDDValidations?.length) {
    return;
  }

  let sendDocumentTypeIsInFrontOfDDFlows = sendDocumentTypeIsInFrontOfDDValidations?.map(
    (validation) => validation?.flow,
  );

  // add a new template validation if the send document type step is in front of DD step based of each flow
  if (
    !!sendDocumentTypeIsInFrontOfDDFlows?.length &&
    sendDocumentTypeIsInFrontOfDDFlows?.every((flowName) => !!flowName)
  ) {
    sendDocumentTypeIsInFrontOfDDFlows = formatListContainsOnlyOneItem(sendDocumentTypeIsInFrontOfDDFlows);

    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.SEND_DOCUMENT_TYPE_IS_IN_FRONT_OF_DD)(
        sendDocumentTypeIsInFrontOfDDFlows,
      ),
    );
  }
};

export const sendDocumentTypeStepMustBeAfterDocumentTypeStep = (
  documentTypeFlowsStepsIndexes = [],
  sendDocumentTypeFlowsStepsIndexes = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  const dataConfirmationIsInFrontOfDDValidations = validateTemplateStepsOrderBasedOnEachFlow(
    [documentTypeFlowsStepsIndexes, sendDocumentTypeFlowsStepsIndexes],
    templateStepsOrderValidationsRules.BETWEEN_TWO_STEPS,
  );

  if (!dataConfirmationIsInFrontOfDDValidations?.length) {
    return;
  }

  let sendDocumentTypeIsInFrontOfDDFlows = dataConfirmationIsInFrontOfDDValidations?.map(
    (validation) => validation?.flow,
  );

  // add a new template validation if the data confirmation step is in front of DD step based of each flow
  if (
    !!sendDocumentTypeIsInFrontOfDDFlows?.length &&
    sendDocumentTypeIsInFrontOfDDFlows?.every((flowName) => !!flowName)
  ) {
    sendDocumentTypeIsInFrontOfDDFlows = formatListContainsOnlyOneItem(sendDocumentTypeIsInFrontOfDDFlows);

    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.DOCUMENT_TYPE_IS_IN_FRONT_OF_SEND_DOCUMENT_TYPE)(
        sendDocumentTypeIsInFrontOfDDFlows,
      ),
    );
  }
};

export const templateHasNotDDsendDocumentTypeMustNotBeEnabled = (
  DDFlowsStepsIndexes = [],
  sendDocumentTypeFlowsStepsIndexes = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  let senDocumentTypeFlowNames = [];

  if (!DDFlowsStepsIndexes?.length && !!sendDocumentTypeFlowsStepsIndexes?.length) {
    sendDocumentTypeFlowsStepsIndexes?.forEach((cameraAccessFlowStepIndex) =>
      senDocumentTypeFlowNames.push(cameraAccessFlowStepIndex?.flow),
    );
  }

  const documentTypeFlows = DDFlowsStepsIndexes?.map((documentTypeFlowStepIndex) => documentTypeFlowStepIndex?.flow);

  if (!!sendDocumentTypeFlowsStepsIndexes?.length && !!DDFlowsStepsIndexes?.length) {
    const sendDocumentTypeFlows = sendDocumentTypeFlowsStepsIndexes?.map(
      (_sendDocumentTypeFlowStepIndex) => _sendDocumentTypeFlowStepIndex?.flow,
    );

    if (!!sendDocumentTypeFlows?.length) {
      sendDocumentTypeFlows?.forEach((cameraAccessFlow) => {
        const DDFlow = documentTypeFlows?.find((DDFlow) => isEqual(DDFlow?.flow, cameraAccessFlow?.flow));

        if (!DDFlow) {
          senDocumentTypeFlowNames.push(cameraAccessFlow?.flow);
        }
      });
    }
  }

  if (!!senDocumentTypeFlowNames?.length && senDocumentTypeFlowNames?.every((flowName) => !!flowName)) {
    senDocumentTypeFlowNames = formatListContainsOnlyOneItem(senDocumentTypeFlowNames);

    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.TEMPLATE_MUST_HAS_DD_TO_ENABLE_SEND_DOCUMENT_TYPE)(
        senDocumentTypeFlowNames,
      ),
    );
  }
};
