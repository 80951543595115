import Onboarding from "@package/steps";
import { parseStep } from "@utils/onboarding";
import {
  templateValidationsMessages,
  validateTemplateMessages,
} from "@utils/template-validations/templateValidationsMessages";
import isEqual from "lodash.isequal";

const { steps } = Onboarding;

export const hasPowerOfAttorney = (onboardingSteps = [], templateValidationNotificationsToAdd = () => []) => {
  const hasAttorneyStep = onboardingSteps.some((step) => isEqual(parseStep(step?.name)[0], steps.ATTORNEY.name));

  // Filtrar fluxos que contenham a etapa ATTORNEY
  const attorneyFlows = onboardingSteps.reduce((flows, step) => {
    if (isEqual(parseStep(step?.name)[0], steps.ATTORNEY.name)) {
      const flow = step?.flow;
      if (flow) {
        flows.push(flow[0]);
      }
    }
    return flows;
  }, []);

  // Filtrar etapas de upload de documentos que pertencem aos fluxos de attorney
  const uploadDocumentsAttorney = onboardingSteps.filter(
    (step) =>
      (step?.flow || []).some((flow) => attorneyFlows.includes(flow)) &&
      isEqual(parseStep(step?.name)[0], steps.UPLOAD_DOCUMENTS.name),
  );

  // Verificar se todos os fluxos de attorney têm o tipo de arquivo POWER_OF_ATTORNEY
  const hasPowerOfAttorneyType = uploadDocumentsAttorney.every((step) =>
    step?.fields?.some((field) => field?.type === "POWER_OF_ATTORNEY"),
  );

  // Adicionar notificação de validação se algum fluxo não contiver o tipo de arquivo POWER_OF_ATTORNEY
  if ((!hasPowerOfAttorneyType || uploadDocumentsAttorney.length === 0) && hasAttorneyStep) {
    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.ATTORNEY_FLOWS_HAS_POWER_OF_ATTORNEY),
    );
  }
};
