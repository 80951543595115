import { onboardingTemplatesAvailableLanguages } from "@utils/internationalization";
import { Select } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const I18N_BASE_PATH = "src.components.modal.bodies.templateCreateOnboardingBody.steps.language";

const Language = ({ onboardingData, setOnboardingData, setCanGoForward, setNextStepButtonTitle }) => {
  const { t } = useTranslation();

  useEffect(() => setCanGoForward(!!onboardingData?.language), [onboardingData]);
  useEffect(
    () =>
      setNextStepButtonTitle(
        !onboardingData?.language && t(`${I18N_BASE_PATH}.nextStepButtonTitle`, "Selecione um idioma para continuar"),
      ),
    [onboardingData],
  );

  return (
    <div className="w-full flex items-center justify-center">
      <Select
        value={onboardingData?.language}
        onChange={(e) => setOnboardingData((old) => ({ ...old, language: e }))}
        className="w-96 !text-sm"
        placeholder={t(`${I18N_BASE_PATH}.form.select.placeholder`, "Selecione um idioma")}
        size="large"
        // prop getPopupContainer for resolve conflict with modal
        getPopupContainer={(node) => node.parentNode}
      >
        {onboardingTemplatesAvailableLanguages?.map((language) => (
          <Select.Option className="!text-gray-700 w-full" key={language?.id} value={language?.id}>
            <img className="w-5 inline mr-2" src={language?.flag} alt={t(language?.name, language?.name)} />
            {t(language?.name, language?.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default Language;
