import isEqual from "lodash.isequal";
import { useEffect, useState } from "react";

import { progressStepsLabels } from "./utils";

const ProgressSteps = ({ currentStep }) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);

  // Update progress percentage based on current modal step
  useEffect(() => {
    const stepIndex = progressStepsLabels
      ?.map((step, index) => (isEqual(step?.id, currentStep?.toLowerCase()) ? index : null))
      ?.filter((index) => index !== null)[0];

    setStepIndex(stepIndex);
    setProgressPercentage((stepIndex + 1) * 25);
  }, [currentStep]);

  return (
    <header className="w-full px-3 flex flex-col justify-center gap-5">
      <section className="flex flex-row items-center justify-between px-3">
        {progressStepsLabels?.map((stepLabel, index) => (
          <div
            key={stepLabel?.id}
            className={`flex flex-row items-center justify-center gap-1 text-sm leading-4 font-bold ${
              index <= stepIndex ? "text-caf-primary" : ""
            }`}
          >
            {index + 1}.<span className="block">{stepLabel?.text}</span>
          </div>
        ))}
      </section>
      <div className="h-2 rounded-full bg-gray-100 w-full relative">
        <div className="h-2 rounded-full bg-caf-primary" style={{ width: `${progressPercentage}%` }} />
      </div>
    </header>
  );
};

export default ProgressSteps;
