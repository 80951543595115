import { Trans } from "react-i18next";

import i18n from "../../../../i18n";
import { Country, Language, Model, Name, Theme } from "./Steps";

const I18N_BASE_PATH = "src.components.modal.bodies.templateCreateOnboardingBody";

let templateCreateOnboardingData;
let progressStepsLabels;

i18n.on("languageChanged", () => {
  templateCreateOnboardingData = {
    LANGUAGE: {
      title: i18n.t("general.label.language", "Idioma"),
      description: i18n.t(
        `${I18N_BASE_PATH}.utils.templateCreateOnboardingData.language.description`,
        "Defina o idioma em que o template deve estar",
      ),
      component: <Language />,
    },
    MODEL: {
      title: i18n.t(`${I18N_BASE_PATH}.utils.templateCreateOnboardingData.model.title`, "Modelo de template"),
      description: i18n.t(
        `${I18N_BASE_PATH}.utils.templateCreateOnboardingData.model.description`,
        "Escolha qual modelo de template deseja criar",
      ),
      component: <Model />,
    },
    THEME: {
      title: i18n.t(`${I18N_BASE_PATH}.utils.templateCreateOnboardingData.theme.title`, "Temas"),
      description: (
        <Trans i18nKey={`${I18N_BASE_PATH}.utils.templateCreateOnboardingData.theme.description`}>
          <>
            Escolha o tema que deseja aplicar no seu template.
            <br />
            Você poderá personalizar o layout e editar fluxos.
          </>
        </Trans>
      ),
      component: <Theme />,
    },
    NAME: {
      title: i18n.t(`${I18N_BASE_PATH}.utils.templateCreateOnboardingData.name.title`, "Nome"),
      description: (
        <Trans i18nKey={`${I18N_BASE_PATH}.utils.templateCreateOnboardingData.name.description`}>
          <>Nomeie o Template de Onboarding</>
        </Trans>
      ),
      component: <Name />,
    },
  };

  progressStepsLabels = [
    {
      id: "language",
      text: i18n.t("general.label.language", "Idioma"),
    },
    {
      id: "model",
      text: i18n.t(`${I18N_BASE_PATH}.progressSteps.model`, "Modelo de template"),
    },
    {
      id: "theme",
      text: i18n.t(`${I18N_BASE_PATH}.progressSteps.theme`, "Tema"),
    },
    {
      id: "name",
      text: i18n.t(`${I18N_BASE_PATH}.progressSteps.name`, "Nome"),
    },
  ];
});

export { progressStepsLabels, templateCreateOnboardingData };
