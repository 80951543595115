import { parseStep } from "@utils/onboarding";

export const shouldRenderPreviewPDFSwitchWhenSendDocumentTypeStepIsUsedFlow = ({ steps, ...rest }) => {
  const stepToFind = "SEND_DOCUMENT_TYPE";

  const stepFound = steps?.find((step) => {
    const [pureName] = parseStep(step?.name);

    return pureName === stepToFind;
  });

  const ddStep = steps?.find((step) => parseStep(step?.name)?.[0] === "DD");

  return !!stepFound && !!ddStep?.preview;
};
