const defaultValues = {
  isConnecting: false,
  setIsConnecting: (newValue) => {},
  elements: [],
  setElements: (elements) => {},
  reactFlowInstance: undefined,
  setReactFlowInstance: (elements) => {},
  reactFlowRef: null,
  setReactFlowRef: (elements) => {},
};
Object.freeze(defaultValues);

export default defaultValues;
