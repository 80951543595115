import useSaveableState from "@hooks/useSaveableState";
import useTimeout from "@hooks/useTimeout";
import { Logger } from "@utils/logging";
import { useContext, useEffect } from "react";
import { createContext, useState } from "react";

import defaultValues from "./defaultValues";

export const OnboardingOptionsContext = createContext(defaultValues);
export const useOnboardingOptionsContext = () => useContext(OnboardingOptionsContext);

/** Timeout in which realOnboardingOptions will be updated after the last modification */
export const updateTimeout = 500;

export const OnboardingOptionsProvider = ({ children }) => {
  // In order for the options to not automatically update as the user is typing (causing lag as the Onboarding is re-rendered),
  // it is implemented for the realOnboardingOptions to be updated only after a specified timeout after the user's last modification.
  const { callbackOnTimeout } = useTimeout();

  /** OnboardingOptions that are actually passed to the Onboarding as a prop */
  const [realOnboardingOptions, setRealOnboardingOptions] = useState();
  /** OnboardingOptions used in the builder to manipulate the values */
  const [onboardingOptions, setUpdatedOnboardingOptions, isSaved] = useSaveableState();
  const [templateTheme, setTemplateTheme] = useState();
  const [isTemplateDefault, setIsTemplateDefault] = useState(true);
  const [initialOnboardingOptions, setInitialOnboardingOptions] = useState();

  const [isUpdating, setIsUpdating] = useState(false);
  const [onboardingLanguage, setOnboardingLanguage] = useState(undefined);

  /** Updates the updatedOnboardingOptions and the real one after a timeout, if no other modification are made in between */
  const setOnboardingOptions = (newOnboardingOptions, instant = false, initial = false) => {
    if (initial) setInitialOnboardingOptions({ ...initialOnboardingOptions, ...newOnboardingOptions });

    const mergedOptions = { ...onboardingOptions, ...newOnboardingOptions };
    // New template don't have those properties
    if (!isTemplateDefault) {
      ["colors", "footer", "background", "documentTypes"].forEach((key) => {
        delete mergedOptions?.[key];
      });
    }

    setUpdatedOnboardingOptions(mergedOptions, initial);

    if (instant) {
      setRealOnboardingOptions({ ...realOnboardingOptions, ...newOnboardingOptions });
    } else {
      setIsUpdating(true);
      callbackOnTimeout(() => {
        setRealOnboardingOptions({ ...realOnboardingOptions, ...newOnboardingOptions });
        setIsUpdating(false);
      }, updateTimeout);
    }
  };

  useEffect(
    () => setOnboardingLanguage(onboardingOptions?.internationalization?.language),
    [onboardingOptions?.internationalization?.language],
  );

  useEffect(() => {
    Logger.console("Onboarding Options changed:", realOnboardingOptions);
  }, [realOnboardingOptions]);

  return (
    <OnboardingOptionsContext.Provider
      value={{
        realOnboardingOptions,
        onboardingOptions,
        setOnboardingOptions,
        initialOnboardingOptions,
        templateTheme,
        setTemplateTheme,
        isTemplateDefault,
        setIsTemplateDefault,
        isUpdating,
        isSaved,
        onboardingLanguage,
      }}
    >
      {children}
    </OnboardingOptionsContext.Provider>
  );
};
