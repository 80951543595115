import { Icon } from "@combateafraude/react";
import StepIcon from "@components/StepIcon";
import { Button as AntButton } from "antd";
import classNames from "classnames";
import ReactDOM from "react-dom";

const FloatingSidebar = ({ icon = "", title = "", isVisible, hide, children }) => {
  return ReactDOM.createPortal(
    <div
      className={classNames(
        "sidebar fixed top-0 z-30 h-full flex flex-col bg-white w-96 transition-all duration-300",
        isVisible ? "left-0 shadow-2xl" : "-left-96 shadow-none",
      )}
    >
      <header className="flex items-start justify-between text-black border-b border-gray-200 py-3 px-4">
        <span className="text-base flex-1 w-full flex">
          <StepIcon Src={icon} className={`transition-all min-w-7 pr-2`} />
          <p className="font-bold">{title}</p>
          {/* TODO: Add help/tooltip */}
        </span>
        <AntButton className="-mt-1" type="text" onClick={hide} shape="circle" icon={<Icon icon="close" size="md" />} />
      </header>
      <section className="py-4 px-2 h-full overflow-y-auto">{children}</section>
    </div>,
    document.querySelector("#sidebars-root"),
  );
};

export default FloatingSidebar;
