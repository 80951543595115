import { getQueryVariable } from "@utils/";
import { Link } from "react-router-dom";

const newTab = getQueryVariable("newTab");

/** Conditionally sends to a new tab or opens in the same, based on the query param "newTab" */
const ConditionalLink = ({ children, to, isExternal = false }) => {
  return newTab ? (
    <a href={`${to}`} target="_blank" style={{ color: "inherit" }} rel="noreferrer">
      {children}
    </a>
  ) : (
    <Link to={to} target={isExternal && "_blank"} style={{ color: "inherit" }}>
      {children}
    </Link>
  );
};

export default ConditionalLink;
