import defaultValues from "@contexts/API/defaultValues";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import useUpdateEffect from "@hooks/useUpdateEffect";
import Onboarding from "@package/steps";
import { removeKey } from "@utils/";
import { cleanStep, indexStep, isEnabledStep, parseStep } from "@utils/onboarding";
import isEqual from "lodash.isequal";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import useSupportedSteps from "./SupportedSteps";

const { steps } = Onboarding;

export const OnboardingStepsContext = createContext(defaultValues);
export const useOnboardingStepsContext = () => useContext(OnboardingStepsContext);

export const OnboardingStepsProvider = ({ children }) => {
  const { i18n } = useTranslation();

  const { supportedPFSteps, supportedPJSteps, supportedUniquePFSteps, supportedUniquePJSteps } = useSupportedSteps();
  const { onboardingOptions, setOnboardingOptions, onboardingLanguage } = useOnboardingOptionsContext();

  const [stepsTranslated, setStepsTranslated] = useState(steps);
  const [onboardingSteps, setOnboardingSteps] = useState();
  const [initialOnboardingSteps, setInitialOnboardingSteps] = useState();
  const initialUpdate = useRef(false);

  useEffect(() => setStepsTranslated(steps), [i18n?.language, onboardingLanguage]);

  /** Remove disabled steps */
  const removeDisabledSteps = (steps) => {
    const hasSmartChoice = steps?.some((step) => step?.name?.includes("SMART_CHOICE"));
    if (hasSmartChoice) {
      return steps;
    } else {
      return steps
        ?.filter((step) => step.isEnabled)
        ?.map((step) => ({
          ...removeKey("isEnabled", step),
        }));
    }
  };

  /** Remove empty flow key */
  const removeEmptyFlowKey = (steps) => {
    return steps?.map((step) => (step?.flow ? step : { ...removeKey("flow", step) }));
  };

  // Initializes the steps based on onboardingOptions.steps
  useUpdateEffect(() => {
    if (
      onboardingOptions?.steps?.length > 0 &&
      !isEqual(removeEmptyFlowKey(removeDisabledSteps(onboardingSteps)), onboardingOptions?.steps)
    ) {
      let newSteps = [];

      onboardingOptions?.steps
        // Filter for existing steps (DONE will be added later in order)
        ?.filter((_step) => !_step.name.includes(steps.DONE.name) && stepsTranslated[parseStep(_step.name)[0]])
        .forEach((_step, i) =>
          newSteps.push({
            ...cleanStep(stepsTranslated[parseStep(_step.name)[0]]),
            ..._step,
            name: indexStep(onboardingOptions?.steps.slice(0, i), _step.name),
            isEnabled: isEnabledStep(_step) ?? true,
          }),
        );

      newSteps = [
        ...newSteps,
        // Adds DONE step last
        {
          ...cleanStep(stepsTranslated.DONE),
          ...onboardingOptions?.steps.find((_step) => _step.name.includes(steps.DONE.name)),
          name: indexStep([], steps.DONE.name),
          isEnabled: true,
        },
      ];

      setOnboardingSteps(newSteps);
      setInitialOnboardingSteps(newSteps);
      initialUpdate.current = true;
    }
  }, [onboardingOptions]);

  // If its adding or reordering steps, change should be instant
  const [isInstantChange, setIsInstantChange] = useState(false);

  // Updates onboardingOptions.steps based on onboardingSteps
  useUpdateEffect(() => {
    if (onboardingSteps) {
      let newSteps = removeEmptyFlowKey(removeDisabledSteps(onboardingSteps));

      if (!isEqual(newSteps, onboardingOptions?.steps)) {
        setOnboardingOptions(
          {
            ...onboardingOptions,
            steps: newSteps,
          },
          isInstantChange,
          initialUpdate.current,
        );
        initialUpdate.current = false;
      }

      setIsInstantChange(false);
    }
  }, [onboardingSteps]);

  // Add a new step on the first position
  const addStep = (name) => {
    let _step = stepsTranslated[name];
    if (_step) {
      setIsInstantChange(true);
      setOnboardingSteps([
        {
          ...cleanStep(_step),
          name: indexStep(onboardingSteps, _step.name),
          isEnabled: true,
        },
        ...onboardingSteps,
      ]);
    }
  };

  // Reorder a step in the list from a start to end position
  const reorderStep = (startIndex, endIndex) => {
    if (endIndex === startIndex) return;
    // Doesn't allow a draggable to be dropped after the last step ("DONE")
    if (endIndex === onboardingSteps.length - 1) return;

    const result = Array.from(onboardingSteps);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    setIsInstantChange(true);
    setOnboardingSteps(result);
  };

  // From onboarding-client context
  const [currentStep, setCurrentStep] = useState();
  const [goTo, setGoTo] = useState();
  const [goBackward, setGoBackward] = useState();
  const [goForward, setGoForward] = useState();

  return (
    <OnboardingStepsContext.Provider
      value={{
        onboardingSteps,
        initialOnboardingSteps,
        addStep,
        reorderStep,
        setOnboardingSteps,
        supportedPFSteps,
        supportedUniquePFSteps,
        supportedPJSteps,
        supportedUniquePJSteps,
        // From onboarding-client context
        currentStep,
        setCurrentStep,
        goTo,
        setGoTo,
        goBackward,
        setGoBackward,
        goForward,
        setGoForward,
      }}
    >
      {children}
    </OnboardingStepsContext.Provider>
  );
};
