import { LoadingOutlined } from "@ant-design/icons";
import { Spin as AntdSpin } from "antd";

const Loader = ({ isHidden, text, style }) => {
  const iconLoader = <LoadingOutlined style={{ fontSize: 80 }} spin />;

  return (
    <div
      className={`absolute top-0 left-0 h-full w-full z-30 transition-all duration-300 ${
        isHidden ? "opacity-0" : "opacity-100"
      }`}
      style={style}
    >
      <span className="absolute block h-full w-full bg-white opacity-100" />
      <div className="absolute h-full w-full flex flex-col items-center justify-center">
        <AntdSpin indicator={iconLoader} style={{ color: "var(--color-caf-primary)" }} />
        {!!text && <p className="font-bold text-lg text-caf-primary">{text}</p>}
      </div>
    </div>
  );
};

export default Loader;
