import { Logger } from "./logging";

export const isEmpty = (obj) => !obj || (obj && Object.keys(obj).length === 0);

/** Removes one property from an object */
export const removeKey = (key, { [key]: _, ...rest }) => rest;

/** Removes multiple properties from an object */
export const removeKeys = (keys = [], object) => keys.reduce((object, key) => removeKey(key, object), object);

/** Gets a variable from the query params */
export function getQueryVariable(variable) {
  try {
    var query = window.location.search.substring(1);
    if (query) {
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] === variable) {
          return pair[1] || true;
        }
      }
    }
  } catch (error) {
    Logger.error(error, {
      message: "Couldn't get query params variable.",
    });
    return "";
  }
  return "";
}

/** Returns only the temp key from a AWS Bucket URL */
export function getTempKey(url = "") {
  return url.match(/(?=temp)(.*)(?=\?)/g)[0];
}

/** scroll to a specific DOM element  */
export const scrollToElement = (elementId) => {
  if (!elementId) return;

  const element = document.getElementById(elementId);
  element.scrollIntoView({ top: 0, behavior: "smooth" });
};

export const getRandomNumber = (min, max) => Math.random() * (max - min) + min;
