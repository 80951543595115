import { Icon } from "@combateafraude/react";
import { useOnboardingStepsContext } from "@contexts/OnboardingSteps";
import { isEmpty } from "@utils/";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const previewModes = {
  LABEL: "label",
  ICON: "icon",
  TEXT: "text",
  LIST: "list",
};

const documentIcons = {
  RG: "faceID",
  PASSPORT: "passport",
  CTPS: "ctps",
  CNH: "car",
  RNE: "person",
  ANY: "person",
  OTHER: "combateafraude",
};

const ObjectPreview = ({ fields, object }) => {
  const { t } = useTranslation();
  const { onboardingSteps } = useOnboardingStepsContext();

  const flowAndCaptureType = useMemo(() => {
    const stepsByFlow = onboardingSteps?.reduce((acc, step) => {
      if (step.flow) {
        step.flow.forEach((flowType) => {
          if (!acc[flowType]) {
            acc[flowType] = [];
          }
          acc[flowType].push(step);
        });
      }
      return acc;
    }, {});

    const result = Object.entries(stepsByFlow).map(([flowType, steps]) => {
      const hasSendDocumentStep = steps.some((step) => step.name.includes("SEND_DOCUMENT_TYPE"));
      const hasDDStep = steps.some((step) => step.name.split("-")[0] === "DD");
      let hasUpload = false;
      let hasCapture = false;

      steps.forEach((step) => {
        if (step.sendDocumentTypes) {
          if (step.sendDocumentTypes.some((type) => type.id === "upload")) {
            hasUpload = true;
          }
          if (step.sendDocumentTypes.some((type) => type.id === "picture")) {
            hasCapture = true;
          }
        }
      });

      let captureType = "undefined";
      if (hasUpload && hasCapture) {
        captureType = "upload and capture";
      } else if (hasUpload) {
        captureType = "upload";
      } else if (hasCapture || !hasSendDocumentStep || hasDDStep) {
        captureType = "capture";
      }

      return { flowType, captureType };
    });

    return result;
  }, [onboardingSteps]);

  const labelFields = useMemo(
    () => fields.filter((field) => field.previewMode === previewModes.LABEL).map((field) => field.property),
    [fields],
  );

  const icon = useMemo(() => documentIcons[object?.flow], [object]);

  const captureTypeObj = useMemo(() => flowAndCaptureType?.find((item) => item.flowType === object?.flow));

  return (
    <>
      {!isEmpty(object) && (
        <div className="flex w-full flex-col items-stretch">
          <div className="flex space-x-2 items-center">
            <div className={`flex-1 border border-gray-300 p-3 rounded-md font-normal flex flex-col justify-start`}>
              <div>
                <Icon key={`PREVIEW_ICON_${object}`} icon={icon} className="mr-2" />
                {labelFields?.map((field, i) => (
                  <span key={`PREVIEW_LABEL_${field || i}`} className="text-xs font-bold mb-1">
                    {t(
                      `onboardingProps.defaultOnboardingValues.defaultDocumentTypesOptions.${object?.[
                        field
                      ].toLowerCase()}.title`,
                    )}
                  </span>
                ))}
              </div>
              <div className="mt-2">
                {!!captureTypeObj ? (
                  <>
                    <strong className="mt-2">
                      {t("onboardingProps.defaultOnboardingValues.defaultDocumentTypesOptions.sendDocumentType.title")}
                    </strong>
                    {captureTypeObj.captureType === "capture" && (
                      <span className="mt-2 ml-1">
                        {t(
                          "onboardingProps.defaultOnboardingValues.defaultDocumentTypesOptions.sendDocumentType.capture",
                        )}
                      </span>
                    )}
                    {captureTypeObj.captureType === "upload" && (
                      <span className="mt-2 ml-1">
                        {t(
                          "onboardingProps.defaultOnboardingValues.defaultDocumentTypesOptions.sendDocumentType.upload",
                        )}
                      </span>
                    )}
                    {captureTypeObj.captureType === "upload and capture" && (
                      <span className="mt-2 ml-1">
                        {t(
                          "onboardingProps.defaultOnboardingValues.defaultDocumentTypesOptions.sendDocumentType.uploadAndCapture",
                        )}
                      </span>
                    )}
                  </>
                ) : (
                  <span className="mt-2 ml-1">
                    {t("onboardingProps.defaultOnboardingValues.defaultDocumentTypesOptions.sendDocumentType.without")}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ObjectPreview;
