import cafLogo from "@assets/images/caf_logo.svg";
import unauthorizedImg from "@assets/images/unauthorized.svg";
import { getLoggedUser } from "@utils/auth/authentication";
import { authenticationUtils } from "@utils/auth/utils";
import { message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const { validateToken } = authenticationUtils;

const I18N_BASE_PATH = "src.pages.auth.unauthorized";

function Unauthorized() {
  const { t } = useTranslation();

  const history = useHistory();

  const [tenantId, setTenantId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getUserTenantId = useCallback(async () => {
    try {
      setIsLoading(true);
      const { tenantId } = await getLoggedUser({
        url: `${process.env.REACT_APP_BASE_URL_AUTH_API}users/me`,
        token: validateToken()?.token,
        history,
      });

      if (!!tenantId) {
        setTenantId(tenantId);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      message.error(
        t(`${I18N_BASE_PATH}.methods.getUserTenantId.errorMessage`, "Ocorreu um erro ao buscar os seus templates."),
      );
    }
  }, [validateToken, t]);

  useEffect(() => {
    getUserTenantId();
  }, []);

  return (
    <div className="bg-white w-full h-screen flex items-center justify-center flex-col gap-32 2xl:gap-36 px-8 max-w-2xl mx-auto my-0 relative">
      <img
        className="absolute top-3 md:top-8 w-32 sm:w-56"
        src={cafLogo}
        alt={t("general.label.combateAFraudeLogo", "Logo da Combate à Fraude")}
      />
      <div className="flex flex-col item items-center justify-center">
        <div className="flex flex-col item items-center justify-center gap-4 mt-6 my-20">
          <img
            src={unauthorizedImg}
            className="h-auto w-auto md:h-64 md:w-64 3xl:h-72 3xl:w-72"
            alt={t(
              `${I18N_BASE_PATH}.components.image`,
              "Homem vestido com uma farda ao lado de um placa com um símbolo de proibido (círculo com um traço no meio) com a mão direita levantada reforçando a ideia de “proibido continuar”",
            )}
          />
          <h3 className="font-black text-center !mb-0 !mt-7 !p-0 text-3xl">
            {t(`${I18N_BASE_PATH}.components.heading`, "Acesso negado")}
          </h3>
          <p className="font-normal text-base !m-0 !p-0 text-center max-w-sm text-gray-500">
            {t(
              `${I18N_BASE_PATH}.components.paragraph`,
              "Esta página possui acesso restrito. Você não tem permissão para acessá-la.",
            )}
          </p>
          <div className="flex item items-center justify-center w-full max-w-md !mt-0">
            <a
              className={`bg-caf-primary hover:underline-offset-auto hover:text-white h-10 w-3/5 flex items-center justify-center text-center rounded-full text-white font-semibold text-base ${
                !tenantId || isLoading
                  ? "cursor-not-allowed opacity-50"
                  : "hover:opacity-90 transition-opacity duration-200"
              }`}
              href={`/onboarding?tenantId=${tenantId}`}
            >
              {t(`${I18N_BASE_PATH}.components.buttonGoToTemplates`, "Voltar para meus templates")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Unauthorized;
