const defaultValues = {
  activeError: "",
  setActiveError: (newValue = "") => {},
  mode: "desktop",
  setMode: (newValue = "") => {},
  zoom: 1,
  setZoom: (newValue = "") => {},
};
Object.freeze(defaultValues);

export default defaultValues;
