import Modal from "@components/Modal";

import { useModalContext } from "./Modal";

const useModal = () => {
  const { openModals, setOpenModals } = useModalContext();

  const open = (title, body, showCloseButton, modalClassName) => {
    setOpenModals([
      ...openModals,
      {
        title,
        component: (
          <Modal key={title} title={title} showCloseButton={showCloseButton} modalClassName={modalClassName}>
            {body}
          </Modal>
        ),
      },
    ]);
  };

  return open;
};

export default useModal;
