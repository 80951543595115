import { parseStep } from "@utils/onboarding";

export const shouldRenderIfStepSendDocumentTypeIsUsedOnFlow = ({ steps, ...rest }) => {
  const stepToFind = "SEND_DOCUMENT_TYPE";

  const stepFound = steps?.find((step) => {
    const [pureName] = parseStep(step?.name);

    return pureName === stepToFind;
  });

  return !!stepFound;
};
