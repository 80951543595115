import { Icon } from "@combateafraude/react";
import classNames from "classnames";
import { useCallback, useState } from "react";

const FloatingMenu = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  /** Shows the modal */
  const show = useCallback(() => setIsMenuOpen(true), [setIsMenuOpen]);
  /** Hides the modal */
  const hide = useCallback(() => setIsMenuOpen(false), [setIsMenuOpen]);
  /** Toggles the model between closed and shown */
  const toggle = useCallback(() => setIsMenuOpen((old) => !old), [setIsMenuOpen]);

  return (
    <div className="relative">
      <span
        className={classNames(
          "w-8 h-8 grid place-items-center bg-gray-100 rounded-full transition-all hover:bg-gray-300 z-10 cursor-pointer",
          {
            "bg-gray-300": isMenuOpen,
          },
        )}
        onClick={toggle}
      >
        <Icon icon="more-horizontal" />
      </span>
      <div
        className={classNames(
          "modal absolute top-0 right-0 flex flex-col py-3 space-y-2 bg-white rounded-lg transition-all cursor-auto shadow-md z-10 transform origin-top-right",
          `${isMenuOpen ? "opacity-100 scale-100" : "opacity-0 scale-0 pointer-events-none"}`,
        )}
        onMouseLeave={hide}
      >
        {children?.(hide)}
      </div>
    </div>
  );
};

export default FloatingMenu;
