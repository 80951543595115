import Onboarding from "@package/steps";
import { useSidebarEditorContext } from "@contexts/SidebarEditor";
import { useContext, useEffect, useState } from "react";

const { HoveredElementContext } = Onboarding;

const ElementEditor = () => {
  const { selectCustomizableElement, selectedStep, focusedCustomizableElement } = useSidebarEditorContext();

  const { element, elementPath } = useContext(HoveredElementContext);

  const unfocusCustomizableElements = () => {
    document.querySelectorAll(`[data-customizable]`)?.forEach((elem) => elem.classList.remove("active"));
  };
  const focusCustomizableElement = (elementName) => {
    document.querySelectorAll(`[data-customizable="${elementName}"]`)?.forEach((elem) => elem.classList.add("active"));
  };
  useEffect(() => {
    unfocusCustomizableElements();
    if (focusedCustomizableElement) {
      let parsedElementName = focusedCustomizableElement.split("_");
      if (parsedElementName[0] === "STEP" && selectedStep?.name) {
        focusCustomizableElement(
          `${parsedElementName[0]}_${selectedStep.name}_${parsedElementName[parsedElementName.length - 1]}`,
        );
      } else {
        focusCustomizableElement(focusedCustomizableElement);
      }
    }
  }, [focusedCustomizableElement, selectedStep]);

  const [hasClicked, setHasClicked] = useState(false);
  useEffect(() => {
    document.addEventListener("click", () => setHasClicked(true), false);
    return () => {
      document.removeEventListener("click", () => setHasClicked(true));
    };
  }, []);

  useEffect(() => {
    if (hasClicked) {
      if (element) selectCustomizableElement(element, elementPath);
      setHasClicked(false);
    }
  }, [hasClicked, element]);

  return <></>;
};

export default ElementEditor;
