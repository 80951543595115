import { Button, Icon } from "@combateafraude/react";
import { TemplateConfirmTemplateValidationBody, TemplateImportExportBody } from "@components/Modal";
import { templateModels } from "@components/Modal/Bodies/TemplateCreateOnboardingBody/Steps/Model";
import { useAPIContext } from "@contexts/API";
import { useModal } from "@contexts/Modal";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import { useTemplateValidationsContext } from "@contexts/TemplateValidations";
import { prepareOnboardingOptions } from "@utils/onboarding";
import { message, Modal, Tooltip, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactJson from "react-json-view";

const I18N_BASE_PATH = "src.components.sidebar";
const { Text } = Typography;

const TopBar = ({ onUpdateTemplate, lastTimeSaved }) => {
  const { t } = useTranslation();

  const [lastTimeSavedIntervalId, setLastTimeSavedIntervalId] = useState(null);
  const [lastTimeSavedMinutes, setLastTimeSavedMinutes] = useState(0);
  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);

  const { isFetching } = useAPIContext();
  const { onboardingOptions, isSaved } = useOnboardingOptionsContext();

  useEffect(() => {
    clearInterval(lastTimeSavedIntervalId);
    setLastTimeSavedMinutes(0);

    const intervalId = setInterval(() => {
      const now = new Date();

      const diff = now.getTime() - lastTimeSaved.getTime();

      const minutes = Math.floor(diff / 1000 / 60);

      setLastTimeSavedMinutes(minutes);
    }, [60000]);

    setLastTimeSavedIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, [lastTimeSaved]);

  const { templateValidationsNotifications, setHasToAnimateValidationButton } = useTemplateValidationsContext();

  const openTemplateImportExportModal = useModal();
  const openConfirmTemplateValidationModal = useModal();

  const onboardingTypeText = useMemo(() => {
    if (!onboardingOptions?.type) return;

    const type = templateModels.find((model) => model.id.toUpperCase() === onboardingOptions?.type.toUpperCase());

    return t(type?.title);
  }, [onboardingOptions, t]);

  const onboardingNameText = useMemo(() => {
    if (!onboardingOptions?.name) return;

    const templateName = onboardingOptions?.name;

    return templateName;
  }, [onboardingOptions]);

  return (
    <div
      id="topbar"
      className="fixed top-0 z-30 flex items-center justify-between px-5 py-2 border-b border-solid border-gray-300 bg-gray-100"
      style={{
        width: "calc(100% - 24rem)",
      }}
    >
      <div>
        {onboardingTypeText && (
          <>
            <span className="text-xs">{onboardingTypeText}</span>
            <br />
            {onboardingNameText && (
              <Tooltip title={onboardingNameText} mouseEnterDelay={1}>
                <Text strong ellipsis className="w-64 min-w-10">
                  {onboardingNameText}
                </Text>
              </Tooltip>
            )}
          </>
        )}
      </div>
      {lastTimeSavedMinutes > 1 && !isSaved && (
        <div>
          <span
            className="text-xs"
            dangerouslySetInnerHTML={{
              __html: t(`${I18N_BASE_PATH}.lastTimeSaved`, "", {
                lastTimeSavedMinutes,
              }),
            }}
          ></span>
        </div>
      )}
      <div>
        {onUpdateTemplate ? (
          <Button
            type="primary"
            className={`w-48 ${isSaved ? "!border" : undefined}`}
            onClick={() => {
              if (templateValidationsNotifications?.length > 0) {
                if (templateValidationsNotifications?.every((validation) => validation?.type === "warning")) {
                  openConfirmTemplateValidationModal(
                    t(
                      `${I18N_BASE_PATH}.components.saveButton.confirmTemplateValidationModal.text`,
                      "Confirmar validações de template pendentes",
                    ),
                    <TemplateConfirmTemplateValidationBody
                      onUpdateTemplate={onUpdateTemplate}
                      templateValidationNotificationsCount={templateValidationsNotifications?.length}
                    />,
                  );
                  return;
                } else {
                  message.warning(
                    t(
                      `${I18N_BASE_PATH}.components.saveButton.confirmTemplateValidationModal.validationsToRevalidate`,
                      "Há validações de template que precisam ser corrigidas, por favor, corrija-as antes de salvar",
                    ),
                  );

                  setHasToAnimateValidationButton(true);

                  return;
                }
              }

              onUpdateTemplate();
            }}
            disabled={isSaved}
            loading={isFetching}
          >
            {isFetching
              ? t(`${I18N_BASE_PATH}.components.saveButton.confirmTemplateValidationModal.button.saving`, "Salvando")
              : isSaved
              ? t("general.label.saved", "Salvo")
              : t("general.label.save", "Salvar")}
          </Button>
        ) : (
          <>
            <Button type="primary" onClick={() => setIsTemplateModalVisible(true)}>
              {t(`${I18N_BASE_PATH}.components.showTemplateButton`, "Exibir template")}{" "}
            </Button>
            <Modal
              title={t("general.label.template", "Template")}
              visible={isTemplateModalVisible}
              onOk={() => setIsTemplateModalVisible(false)}
              okButtonProps={{ hidden: true }}
              onCancel={() => setIsTemplateModalVisible(false)}
              cancelButtonProps={{ hidden: true }}
              width={1000}
            >
              <ReactJson
                src={prepareOnboardingOptions(onboardingOptions)}
                indentWidth={2}
                shouldCollapse={(field) => field.name !== "root"}
                displayDataTypes={false}
                sortKeys={true}
              />
            </Modal>
          </>
        )}
        <Button
          type="primary"
          outlined
          shape="circle"
          icon={<Icon className="pb-1 hover:text-white transition-colors duration-150" icon="download" size="sm" />}
          title={t("general.label.templateImportExportModalText", "Importar/exportar configurações")}
          className="ml-2"
          onClick={() =>
            openTemplateImportExportModal(
              t("general.label.templateImportExportModalText", "Importar/exportar configurações"),
              <TemplateImportExportBody />,
            )
          }
        />
      </div>
    </div>
  );
};

export default TopBar;
