import { AnalyticsSources, fingerprint, Sdk } from "@combateafraude/analytics-sdk";
import { authenticationUtils } from "@utils/auth/utils";
import { Logger } from "@utils/logging";
import { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";

import defaultValues from "./defaultValues";

/**
 * Context analytics SDK, the analytics SDK is used to send metrics to the API
 */

export const AnalyticsContext = createContext(defaultValues);
export const useAnalyticsContext = () => useContext(AnalyticsContext);

export const AnalyticsProvider = ({ children }) => {
  const [sessionId, setSessionId] = useState(defaultValues.sessionId);
  const [analytics, setAnalytics] = useState(defaultValues.analytics);
  const [loggedPlatform, setLoggedPlatform] = useState(defaultValues.loggedPlatform);

  useEffect(() => {
    initializeAnalytics();

    const sessionId = uuidV4();
    setSessionId(sessionId);

    const { platform } = authenticationUtils.validateToken();
    setLoggedPlatform(platform);
  }, []);

  const initializeAnalytics = async () => {
    try {
      const sdk = new Sdk({
        source: AnalyticsSources.OnboardingBuilder,
        getDeviceInfoFunction: fingerprint.getBrowserFingerprint,
        stage: process.env.REACT_APP_ENV,
      });
      await sdk.initialize();
      setAnalytics(sdk);
    } catch (error) {
      Logger.error(error, {
        message: "Couldn't initialize Analytics SDK.",
      });
      setAnalytics(null);
    }
  };

  return (
    <AnalyticsContext.Provider
      value={{
        analytics,
        sessionId,
        loggedPlatform,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
