import cafLogo from "@assets/images/caf_logo.svg";
import Button from "@components/Button";
import { authenticationUtils } from "@utils/auth/utils";
import { getLanguageSaved } from "@utils/translator";
import { useEffect } from "react";
import { useMemo } from "react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import i18n from "../../../i18n";

const { AUTHENTICATION_COOKIES_OPTIONS } = authenticationUtils;

const I18N_BASE_PATH = "src.pages.auth.signIn";

function SignIn() {
  const { t } = useTranslation();

  const [userProfileSelected, setUserProfileSelected] = useState({ userProfile: null, authenticationUrl: null });

  useEffect(() => {
    const { builderLanguage } = getLanguageSaved();

    i18n.changeLanguage(builderLanguage);
  }, []);

  const redirectUri = useMemo(() => {
    return encodeURIComponent(
      AUTHENTICATION_COOKIES_OPTIONS.isLocalhost
        ? `${window.location.origin}/onboarding`
        : `${process.env.REACT_APP_URL}onboarding`,
    );
  }, []);

  // backoffice still don't have support for caf.io
  const redirectUriCafOperator = useMemo(() => {
    return AUTHENTICATION_COOKIES_OPTIONS.isLocalhost
      ? encodeURIComponent(`${window.location.origin}/auth/login`)
      : encodeURIComponent(`${process.env.REACT_APP_URL}auth/login`);
  }, []);

  const handleSelectCompanyProfile = useCallback(() => {
    setUserProfileSelected({
      userProfile: "company",
      authenticationUrl: `${process.env.REACT_APP_BASE_URL_AUTH}?service=management&env=${process.env.REACT_APP_ENV}&continue=${redirectUri}&domain=caf`,
    });
  }, [redirectUri]);

  const handleSelectCafOperatorProfile = useCallback(() => {
    setUserProfileSelected({
      userProfile: "caf-operator",
      authenticationUrl: `${process.env.REACT_APP_BASE_URL_BIFROST}login?client_id=${process.env.REACT_APP_BIFROST_COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${redirectUriCafOperator}`,
    });
  }, [redirectUriCafOperator]);

  return (
    <div className="bg-white w-full h-screen flex items-center justify-center gap-32 2xl:gap-36 flex-col px-8 max-w-2xl mx-auto my-0 relative">
      <img
        className="absolute top-3 md:top-8 w-32 sm:w-56"
        src={cafLogo}
        alt={t("general.label.combateAFraudeLogo", "Logo da Combate à Fraude")}
      />
      <div className="flex flex-col item items-center justify-center">
        <div className="flex flex-col item items-center justify-center gap-4 mt-6 mb-20">
          <h3 className="font-black text-center !m-0 !p-0 text-xl lg:text-3xl">
            {t(`${I18N_BASE_PATH}.components.heading`, "Selecione o seu perfil")}
          </h3>
          <p className="font-normal text-sm lg:text-base !m-0 !p-0 text-center max-w-2xl text-gray-500">
            {t(
              `${I18N_BASE_PATH}.components.paragraph`,
              "Após selecionar o seu perfil, você será direcionado para a página de login",
            )}
          </p>
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-center mb-8 sm:mb-16 gap-8">
          <Button.Square
            icon="company"
            title={t(`${I18N_BASE_PATH}.components.profiles.company.title`, "Cliente")}
            description={t(
              `${I18N_BASE_PATH}.components.profiles.company.description`,
              "Sou Cliente da CAF e desejo editar meus templates",
            )}
            isSelected={userProfileSelected?.userProfile === "company"}
            onSelect={handleSelectCompanyProfile}
            className="!pb-4"
          />
          <Button.Square
            icon="combateafraude-2"
            title={t(`${I18N_BASE_PATH}.components.profiles.operator.title`, "Operador CAF")}
            description={t(
              `${I18N_BASE_PATH}.components.profiles.operator.description`,
              "Sou operador CAF e desejo editar os templates do meu cliente",
            )}
            isSelected={userProfileSelected?.userProfile === "caf-operator"}
            onSelect={handleSelectCafOperatorProfile}
          />
        </div>
        <div className="flex item items-center justify-center w-full max-w-md !mt-0">
          <a
            className={`bg-caf-primary hover:underline-offset-auto hover:text-white h-10 w-full flex items-center justify-center text-center rounded-full text-white font-semibold text-base ${
              !userProfileSelected?.userProfile
                ? "cursor-not-allowed opacity-50"
                : "hover:opacity-90 transition-opacity duration-200"
            }`}
            href={userProfileSelected?.authenticationUrl}
            title={
              !userProfileSelected?.userProfile &&
              t(`${I18N_BASE_PATH}.components.buttonChooseProfile.title`, "Selecione um perfil para continuar")
            }
          >
            {t(`${I18N_BASE_PATH}.components.buttonChooseProfile.text`, "Continuar")}
          </a>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
