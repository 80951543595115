import {
  defaultPFOnboardingOptions as ptBrDefaultPFOnboardingOptions,
  defaultPJOnboardingOptions as ptBrDefaultPJOnboardingOptions,
} from "./pt-BR";

export const defaultOnboardingOptions = {
  "pt-BR": {
    defaultPFOnboardingOptions: ptBrDefaultPFOnboardingOptions,
    defaultPJOnboardingOptions: ptBrDefaultPJOnboardingOptions,
  },
};
