import brazilFlag from "@assets/images/countries-flags/brazil-flag.png";
import mexicoFlag from "@assets/images/countries-flags/mexico-flag.png";
import unitedKingdomFlag from "@assets/images/countries-flags/united-kingdom-flag.png";
import unitedStatesFlag from "@assets/images/countries-flags/united-states-flag.png";

export const onboardingTemplatesAvailableLanguages = [
  {
    id: "pt-BR",
    name: "general.languages.ptBR",
    // => Português (Brasil)
    flag: brazilFlag,
  },
  {
    id: "en-US",
    name: "general.languages.enUS",
    // => Inglês (EUA)
    flag: unitedStatesFlag,
  },
  /*  
  TODO: https://app.clickup.com/t/32qgdr8
  {
    id: "en-GB",
    name: "general.languages.enGB",
    // => Inglês (RU)
    flag: unitedKingdomFlag,
  }, */
  {
    id: "es-MX",
    name: "general.languages.esMX",
    // => espanhol (MX)
    flag: mexicoFlag,
  },
];
