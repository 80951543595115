import {
  templateValidationsMessages,
  validateTemplateMessages,
} from "@utils/template-validations/templateValidationsMessages";
import isEqual from "lodash.isequal";

import { formatListContainsOnlyOneItem } from "../utils";

// The "PF_PF_DATA" step must has "email" or "phone_number" field to send onboarding link
export const PFPFDataStepMustHaveEmailOrPhoneNumberField = (
  PF_PF_DATAFlowsStepsIndexes = [],
  onboardingSteps = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  if (!PF_PF_DATAFlowsStepsIndexes?.length) {
    return;
  }

  let PF_PF_DATAFlowsThatHasNotEmailOrPhoneNumberFields = [];

  PF_PF_DATAFlowsStepsIndexes?.forEach((PF_PF_DATAFlowStepIndex) => {
    const PF_PF_DATAFlowStep = onboardingSteps?.[PF_PF_DATAFlowStepIndex?.stepIndex];

    const PF_PF_DATAFlowStepHasEmailField = PF_PF_DATAFlowStep?.fields?.some((f) => isEqual(f?.field, "email"));
    const PPF_PF_DATAFlowStepHasPhoneField = PF_PF_DATAFlowStep?.fields?.some((f) => isEqual(f?.field, "phone_number"));

    // if the PF_PF_DATA flow step has not email or phone number field
    if (!PF_PF_DATAFlowStepHasEmailField && !PPF_PF_DATAFlowStepHasPhoneField) {
      PF_PF_DATAFlowsThatHasNotEmailOrPhoneNumberFields.push(PF_PF_DATAFlowStepIndex?.flow);
    }
  });

  // add a new template validation if the PF_PF_DATA step hasn't email or phone number field based of each flow
  if (
    !!PF_PF_DATAFlowsThatHasNotEmailOrPhoneNumberFields?.length &&
    PF_PF_DATAFlowsThatHasNotEmailOrPhoneNumberFields?.every((flowName) => !!flowName)
  ) {
    PF_PF_DATAFlowsThatHasNotEmailOrPhoneNumberFields = formatListContainsOnlyOneItem(
      PF_PF_DATAFlowsThatHasNotEmailOrPhoneNumberFields,
    );

    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages["PF_PF_DATA_HAS_NEITHER_EMAIL_NOR_PHONE-NUMBER_FIELD"])(
        PF_PF_DATAFlowsThatHasNotEmailOrPhoneNumberFields,
      ),
    );
  }
};
