import Onboarding from "@package/steps";
import { useContext, useEffect } from "react";

import { useOnboardingStepsContext } from "./OnboardingSteps";
const { OnboardingStepsContext } = Onboarding;

/** Get values from the Onboarding context and store them in Builder's */
const CaptureOnboardingStepsContext = () => {
  const { currentStep, goTo, goForward, goBackward } = useContext(OnboardingStepsContext);
  const { setCurrentStep, setGoTo, setGoForward, setGoBackward } = useOnboardingStepsContext();

  // setGoBackward(goBackward);
  // setGoForward(goForward);

  useEffect(() => setCurrentStep(currentStep), [currentStep]);
  // useEffect(() => setGoForward(() => goForward()), [goForward]);
  // useEffect(() => setGoBackward(() => goBackward()), [goBackward]);

  // useEffect(
  //   (oldGoForward) => {
  //     if (!oldGoForward) setGoForward(() => goForward());
  //   },
  //   [goForward],
  // );

  // useEffect(
  //   (oldGoBackward) => {
  //     if (!oldGoBackward) setGoBackward(() => goBackward());
  //   },
  //   [goBackward],
  // );

  useEffect(() => goTo && setGoTo((step) => (step) => goTo(step)), [goTo]);

  // useEffect(() => goForward && console.log("go goForward"), []);
  // useEffect(() => goBackward && console.log("go 2 goBackward"), []);

  return <></>;
};

export default CaptureOnboardingStepsContext;
