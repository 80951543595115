import { Button as AntButton } from "antd";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

const I18N_BASE_PATH = "src.components.modal.bodies.templateConfirmTemplateValidationBody";

const TemplateConfirmTemplateValidationBody = ({ close, onUpdateTemplate, templateValidationNotificationsCount }) => {
  const { t } = useTranslation();

  const templateValidationNotificationsDescription = useMemo(() => {
    return templateValidationNotificationsCount === 1
      ? t(
          `${I18N_BASE_PATH}.states.oneTemplateValidationNotificationsDescription`,
          "aviso de validação de template pendente que pode ser corrigida",
        )
      : t(
          `${I18N_BASE_PATH}.states.templateValidationNotificationsDescriptionMultiple`,
          "avisos de validações de template pendentes que podem ser corrigidas",
        );
  }, [templateValidationNotificationsCount, t]);

  return (
    <div>
      <p className="text-base">
        <Trans
          i18nKey={`${I18N_BASE_PATH}.components.description`}
          values={{ templateValidationNotificationsCount, templateValidationNotificationsDescription }}
        >
          Você possui <strong>{templateValidationNotificationsCount}</strong>{" "}
          {templateValidationNotificationsDescription}, você tem certeza que deseja salvar o Onboarding mesmo assim?
        </Trans>
      </p>
      <div className="flex justify-between mt-8">
        <AntButton onClick={close} type="ghost">
          {t("general.label.cancel", "Cancelar")}
        </AntButton>
        <AntButton
          onClick={() => {
            close();
            onUpdateTemplate();
          }}
          type="primary"
        >
          {t(`${I18N_BASE_PATH}.components.saveAnywayButton`, "Salvar mesmo assim")}
        </AntButton>
      </div>
    </div>
  );
};

export default TemplateConfirmTemplateValidationBody;
