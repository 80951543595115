import { Form, Switch } from "antd";
import { useEffect, useState } from "react";

const RequiredSwitchOption = ({ inputTypeSelected, checked, onChange, label, ...rest }) => {
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    setIsActive(inputTypeSelected !== "email" && inputTypeSelected !== "phone");
  }, [inputTypeSelected]);

  return isActive ? (
    <Form.Item label={label}>
      <Switch onChange={onChange} checked={checked} {...rest} />
    </Form.Item>
  ) : (
    <></>
  );
};

export default RequiredSwitchOption;
