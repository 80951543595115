import { useAnalyticsContext } from "@contexts/Analytics";
import { useAPIContext } from "@contexts/API";
import { Logger } from "@utils/logging";

const INDICATORS = {
  tracking: {
    name: "tracking",
    version: 1,
  },
};

const useAnalytics = () => {
  const { analytics, sessionId, loggedPlatform } = useAnalyticsContext();
  const { tenantId, templateId } = useAPIContext();

  const analyticsEvents = {
    LIST_TEMPLATES: "LIST_TEMPLATES",
    ACCESS_TEMPLATE: "ACCESS_TEMPLATE",
    LIST_EMAIL_TEMPLATES: "LIST_EMAIL_TEMPLATES",
    ACCESS_EMAIL_TEMPLATE: "ACCESS_EMAIL_TEMPLATE",
  };

  const logAnalyticsTracking = async (name, data = {}) => {
    try {
      if (!analytics) {
        Logger.warn("Analytics not initialized yet");
        return;
      }

      const analyticsInfo = analytics?.getAnalyticsInfo() || {};

      const event = {
        name,
        date: new Date().toISOString(),
        device: analyticsInfo?.device,
        loggedPlatform,
        trackingId: analyticsInfo?.trackingId,
        sessionId,
        tenantId,
        ...(templateId ? { templateId } : {}),
        data,
      };

      analytics.indicator(INDICATORS.tracking.name, INDICATORS.tracking.version, event);
    } catch (error) {
      Logger.error(error, {
        message: "Couldn't log Analytics SDK event info.",
      });
    }
  };

  return {
    analytics,
    analyticsEvents,
    logAnalyticsTracking,
  };
};

export default useAnalytics;
