import api from "@services/api";
import { Logger } from "@utils/logging";
import { useState } from "react";

export const requestMethods = {
  GET: "get",
  POST: "post",
  PATCH: "patch",
  DELETE: "delete",
  PUT: "put",
};

/** Dynamically do API requests */
const useAPI = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setIsLoading] = useState(false);

  const set = (data = null, error = null, loading = false) => {
    setData(data);
    setError(error);
    setIsLoading(loading);
  };

  const request = async (url = "", method = requestMethods.GET, data, options) => {
    if (!url || !method) return;

    try {
      set(null, null, true);

      return api({ method, url, data, ...options })
        .then((res) => {
          if (!res || !(res.status >= 200 && res.status < 300)) throw res;
          Logger.console(
            `Success from request (path=${url}, method=${method}, data=${data}, options=${options}):`,
            res,
          );
          set(res.data);
          return res.data || true;
        })
        .catch((err) => {
          Logger.error(error, {
            message: `Error from request (path=${url}, method=${method}, data=${data}, options=${options})`,
          });
          set(null, err);
          return null;
        });
    } catch (err) {
      Logger.error(error, {
        message: `Error from request (path=${url}, method=${method}, data=${data}, options=${options})`,
      });
      set(null, err);
      return null;
    }
  };

  return [request, loading, data, error];
};
export default useAPI;
