import { Logger } from "@utils/logging";
import axios from "axios";
import isEmpty from "lodash.isempty";
import { useState } from "react";

/** Uploads a file and returns a getUrl and putUrl for it  */
const useTempURL = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setIsLoading] = useState(false);

  const set = (data = null, error = null, loading = false) => {
    setData(data);
    setIsLoading(loading);
    setError(error);
  };

  const request = async (data, contentType) => {
    if (isEmpty(data)) return;

    try {
      set(null, null, true);

      let urls = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API_V1}upload`,
      })
        .then((res) => {
          if (!res || !(res.status >= 200 && res.status < 300)) throw res;
          Logger.console(`Success from GET temp URL request:`, res);
          return res.data || true;
        })
        .catch((err) => {
          Logger.error(error, {
            message: `Error from GET temp URL request (data=${data}, contentType=${contentType})`,
          });
          set(null, err);
          return null;
        });

      if (urls && urls.getUrl && urls.uploadUrl) {
        return axios({
          method: "PUT",
          url: urls.uploadUrl,
          data,
          ...(contentType && { headers: { "Content-Type": contentType } }),
        })
          .then((res) => {
            if (!res || !(res.status >= 200 && res.status < 300)) throw res;
            Logger.console(`Success from PUT temp URL request (data=${data}, contentType=${contentType}):`, res);
            set(urls);
            return urls;
          })
          .catch((err) => {
            Logger.error(err, {
              message: `Error from PUT temp URL request (data=${data}, contentType=${contentType})`,
            });
            set(null, err);
            return null;
          });
      } else {
        Logger.error(error, {
          message: `Invalid urls from temp URL request (data=${data}, contentType=${contentType})`,
        });
        set(null);
        return null;
      }
    } catch (err) {
      Logger.error(err, {
        message: `Error from temp URL request (data=${data}, contentType=${contentType})`,
      });
      set(null, err);
      return null;
    }
  };

  return [request, loading, data, error];
};
export default useTempURL;
