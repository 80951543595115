import { Button as CafButton, Icon } from "@combateafraude/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const MenuOptionsDefault = ({
  shouldRenderDuplicateAndDeleteAction,
  handleDuplicateCard,
  handleDeleteCard,
  handleShowCard,
  isSmartChoice = false,
}) => {
  const { t } = useTranslation();

  const renderOptions = useMemo(
    () => [
      <CafButton type="text" block className="!text-left !bg-transparent !shadow-none" onClick={handleShowCard}>
        <Icon icon="eye" /> {t("general.label.view", "Visualizar")}
      </CafButton>,
      <>
        {shouldRenderDuplicateAndDeleteAction && !isSmartChoice && (
          <>
            <hr />
            <CafButton
              type="text"
              block
              className="!text-left !bg-transparent !shadow-none"
              onClick={handleDuplicateCard}
            >
              <Icon icon="duplicate" /> {t("general.label.duplicate", "Duplicar")}
            </CafButton>
          </>
        )}
      </>,
      <>
        {shouldRenderDuplicateAndDeleteAction && (
          <>
            <hr />
            <CafButton type="text" block danger className="!text-left !shadow-none" onClick={handleDeleteCard}>
              <Icon icon="trash" /> {t("general.label.remove", "Excluir")}
            </CafButton>
          </>
        )}
      </>,
    ],
    [handleDeleteCard, handleDeleteCard, handleShowCard],
  );

  return <>{renderOptions.map((option) => option)}</>;
};
