const Tabs = ({ children }) => <div className="w-full h-full flex flex-col">{children}</div>;

const TitleBar = ({ titles, extras }) => (
  <div className="w-full flex justify-between">
    <div className="flex gap-2">
      {titles?.map((title, i) => (
        <span key={`TITLE_${i}`} className="py-2 z-10 text-gray-500 font-semibold text-lg">
          {title}
        </span>
      ))}
    </div>
    <div className="flex items-center gap-2">{extras}</div>
  </div>
);

const Content = ({ children }) => (
  <div className="w-full h-full overflow-hidden border border-gray-300 rounded-lg rounded-tl-none">{children}</div>
);

Tabs.TitleBar = TitleBar;
Tabs.Content = Content;

export default Tabs;
