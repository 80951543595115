import Cookies from "js-cookie";

const isLocalhost = !!window.location.origin.startsWith("http://localhost");
const isCombateafraudeDomain = !!window.location.origin.includes("combateafraude.com");

// BIFROST AUTH DATA
const BIFROST_ID_TOKEN = isLocalhost
  ? `CAF-Bifrost-${process.env.REACT_APP_ENV}_id_token`
  : `__Secure-CAF-Bifrost-${process.env.REACT_APP_ENV}_id_token`;
const BIFROST_REFRESH_TOKEN = isLocalhost
  ? `CAF-Bifrost-${process.env.REACT_APP_ENV}_refresh_token`
  : `__Secure-CAF-Bifrost-${process.env.REACT_APP_ENV}_refresh_token`;

// AUTH DATA
const AUTH_ID_TOKEN = isLocalhost ? "CAF_access_token" : "__Secure-CAF_access_token";
const AUTH_REFRESH_TOKEN = isLocalhost ? "CAF_refresh_token" : "__Secure-CAF_refresh_token";

const COOKIE_OPTIONS = {
  domain: isLocalhost ? "localhost" : isCombateafraudeDomain ? ".combateafraude.com" : ".caf.io",
  secure: true,
  sameSite: "strict",
};

const AUTHENTICATION_COOKIES_OPTIONS = {
  isLocalhost,
  BIFROST_ID_TOKEN,
  BIFROST_REFRESH_TOKEN,
  AUTH_ID_TOKEN,
  AUTH_REFRESH_TOKEN,
  COOKIE_OPTIONS,
};

const formatTokenBasedOnPlatform = (token = "", platform = "") => {
  if (platform === "auth" && !token.includes("Bearer")) {
    return `Bearer ${token}`;
  }

  return token;
};

// Validate if the token is from bifrost or auth
const validateToken = () => {
  // BIFROST
  const bifrostToken = Cookies.get(AUTHENTICATION_COOKIES_OPTIONS.BIFROST_ID_TOKEN);
  const bifrostRefreshToken = Cookies.get(AUTHENTICATION_COOKIES_OPTIONS.BIFROST_REFRESH_TOKEN);

  // AUTH
  const authToken = Cookies.get(AUTHENTICATION_COOKIES_OPTIONS.AUTH_ID_TOKEN);
  const authRefreshToken = Cookies.get(AUTHENTICATION_COOKIES_OPTIONS.AUTH_REFRESH_TOKEN);

  return {
    token: formatTokenBasedOnPlatform(
      bifrostToken || authToken,
      !!bifrostToken ? "bifrost" : !!authToken ? "auth" : null,
    ),
    refreshToken: bifrostRefreshToken || authRefreshToken || null,
    platform: !!bifrostToken ? "bifrost" : !!authToken ? "auth" : null,
  };
};

export const authenticationUtils = { AUTHENTICATION_COOKIES_OPTIONS, formatTokenBasedOnPlatform, validateToken };
