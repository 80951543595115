import { Icon } from "@combateafraude/react";
import useAPI from "@hooks/useAPI";
import useTempURL from "@hooks/useTempURL";
import { Logger } from "@utils/logging";
import { Button as AntButton, Form, Input, message, Upload } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

const I18N_BASE_PATH = "src.components.modal.bodies.templatePropertiesBody";

/** Modal body to modify the template properties */
const TemplatePropertiesBody = ({
  close,
  handleFinish,
  tenantId,
  defaultValues = { name: undefined, description: undefined, favicon: undefined },
}) => {
  const { t } = useTranslation();

  const [getTemplateRequest] = useAPI();
  const [faviconUploadRequest, faviconUploadLoading] = useTempURL();

  const [faviconUrl, setFaviconUrl] = useState(defaultValues.favicon);

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Form
      validateTrigger="onBlur"
      layout="vertical"
      onFinish={async (values) => {
        setIsLoading(true);
        const { template } = await getTemplateRequest(
          `/onboarding-builder/templates/${defaultValues._id}?tenantId=${tenantId}`,
        );

        let res = await handleFinish({ ...template, ...values, favicon: faviconUrl });
        if (res) {
          close();
          message.success(
            t("general.templatePropertiesBody.templateSuccessfullyUpdated", "Template atualizado com sucesso"),
          );
        } else {
          message.error(
            t("general.templatePropertiesBody.errorOnUpdateTemplate", "Não foi possível atualizar template"),
          );
        }
        setIsLoading(false);
      }}
      initialValues={defaultValues}
    >
      <Form.Item label={t("general.label.favicon", "Favicon")} className="font-bold pt-1">
        <Upload
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={async (file) => {
            if (!faviconUploadLoading) {
              if (!file.type.includes("image")) {
                Logger.console(`${file.name} is not a image file: ${file.type}`);
                message.error(
                  t(`${I18N_BASE_PATH}.components.upload.beforeUpload`, "Selecione um arquivo do tipo imagem"),
                );
                return Upload.LIST_IGNORE;
              }
              let urls = await faviconUploadRequest(file, file.type);
              if (urls) setFaviconUrl(urls.getUrl);
              else {
                setFaviconUrl(null);
                message.error(
                  t(`${I18N_BASE_PATH}.components.upload.errorOnFavicon`, "Não foi possível salvar Favicon"),
                );
              }
            }
            return false;
          }}
          disabled={faviconUploadLoading}
        >
          {!faviconUploadLoading && faviconUrl ? (
            <img src={faviconUrl} alt="favicon" className="h-full w-full object-fill" />
          ) : (
            <>
              <Icon
                icon={faviconUploadLoading ? "rotate-cw" : "upload"}
                className={`z-10 ${faviconUploadLoading ? "animate-spin" : ""}`}
                size={faviconUploadLoading ? "md" : "lg"}
              />
              <span
                className={`absolute h-full w-full bg-gray-200 transition ${
                  !faviconUploadLoading ? "opacity-0" : "opacity-0 animate-pulse"
                }`}
              />
            </>
          )}
        </Upload>
      </Form.Item>
      <Form.Item
        label={t("general.templatePropertiesBody.title", "Título")}
        name="name"
        className="font-bold pt-1"
        required
        normalize={(value) => value?.trimStart() ?? ""}
        rules={[
          {
            required: true,
            message: t("onboardingClientPackage.components.form.input.required"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("general.templatePropertiesBody.description", "Descrição")}
        name="description"
        className="font-bold"
      >
        <TextArea rows="3" />
      </Form.Item>
      <div className="flex justify-end items-center w-full pt-4 pb-1">
        <AntButton className="!mr-2" type="text" onClick={close} htmlType="button">
          {t("general.label.cancel", "Cancelar")}
        </AntButton>
        <AntButton type="primary" htmlType="submit" loading={isLoading}>
          {t("general.label.save", "Salvar")}
        </AntButton>
      </div>
    </Form>
  );
};

export default TemplatePropertiesBody;
