import Onboarding from "@package/steps";
import {
  formatListContainsOnlyOneItem,
  templateStepsOrderValidationsRules,
  validateIfStepHasSpecificFieldBasedOnSecondaryStep,
  validateStepThatRequiresAnotherStep,
  validateTemplateStepsOrderBasedOnEachFlow,
} from "@utils/template-validations/steps-validations/utils";
import {
  templateValidationsMessages,
  validateTemplateMessages,
} from "@utils/template-validations/templateValidationsMessages";

const { steps } = Onboarding;

// The "DATA_CONFIRMATION" step should be before "DD" step
export const dataConfirmationStepShouldBeAfterDDStep = (
  DDFlowsStepsIndexes = [],
  dataConfirmationFlowsStepsIndexes = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  const dataConfirmationIsInFrontOfDDValidations = validateTemplateStepsOrderBasedOnEachFlow(
    [DDFlowsStepsIndexes, dataConfirmationFlowsStepsIndexes],
    templateStepsOrderValidationsRules.BETWEEN_TWO_STEPS,
  );

  if (!dataConfirmationIsInFrontOfDDValidations?.length) {
    return;
  }

  let dataConfirmationIsInFrontOfDDFlows = dataConfirmationIsInFrontOfDDValidations?.map(
    (validation) => validation?.flow,
  );

  // add a new template validation if the data confirmation step is in front of DD step based of each flow
  if (
    !!dataConfirmationIsInFrontOfDDFlows?.length &&
    dataConfirmationIsInFrontOfDDFlows?.every((flowName) => !!flowName)
  ) {
    dataConfirmationIsInFrontOfDDFlows = formatListContainsOnlyOneItem(dataConfirmationIsInFrontOfDDFlows);

    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.DD_IS_IN_FRONT_OF_DATA_CONFIRMATION)(
        dataConfirmationIsInFrontOfDDFlows,
      ),
    );
  }
};

// The template must have "DATA_CONFIRMATION" step in case of "EMAIL_VALIDATION"
export const dataConfirmationStepMustBeInCaseOfEmailValidationStep = (
  dataConfirmationFlowsStepsIndexes = [],
  emailValidationFlowsStepsIndexes = [],
  emailValidationFlows = [],
  dataConfirmationFlows = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  if (!emailValidationFlowsStepsIndexes?.length) {
    return;
  }

  const ifTemplateHasEmailValidationMustHaveDataConfirmationValidations = validateTemplateStepsOrderBasedOnEachFlow(
    [dataConfirmationFlowsStepsIndexes, emailValidationFlowsStepsIndexes],
    templateStepsOrderValidationsRules.BETWEEN_TWO_STEPS,
  );

  validateStepThatRequiresAnotherStep(
    ifTemplateHasEmailValidationMustHaveDataConfirmationValidations,
    emailValidationFlows,
    dataConfirmationFlows,
    templateValidationNotificationsToAdd,
    templateValidationsMessages["TEMPLATE_MUST_HAS_DATA_CONFIRMATION_IN_CASE_OF_EMAIL-VALIDATION"],
  );
};

// The "DATA_CONFIRMATION" step must has email field in case of "EMAIL_VALIDATION"
export const dataConfirmationStepMustHaveEmailFieldInCaseOfEmailValidationStep = (
  emailValidationFlowsStepsIndexes = [],
  emailValidationFlows = [],
  onboardingOptionsSteps = [],
  templateValidationNotificationsToAdd = () => [],
  defaultFlowName = "",
) => {
  if (!emailValidationFlowsStepsIndexes?.length) {
    return;
  }

  validateIfStepHasSpecificFieldBasedOnSecondaryStep(
    emailValidationFlows,
    "email",
    steps.DATA_CONFIRMATION.name,
    onboardingOptionsSteps,
    templateValidationNotificationsToAdd,
    templateValidationsMessages["DATA_CONFIRMATION_MUST_HAS_EMAIL_FIELD_IN_CASE_OF_EMAIL-VALIDATION"],
    defaultFlowName,
  );
};

// The template must have "DATA_CONFIRMATION" step in case of "PHONE_NUMBER_VALIDATION"
export const dataConfirmationStepMustBeInCaseOfPhoneNumberValidationStep = (
  phoneNumberValidationFlowsStepsIndexes = [],
  dataConfirmationFlowsStepsIndexes = [],
  phoneNumberValidationFlows = [],
  dataConfirmationFlows = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  if (!phoneNumberValidationFlowsStepsIndexes?.length) {
    return;
  }

  const ifTemplateHasPhoneNumberValidationMustHaveDataConfirmationValidations =
    validateTemplateStepsOrderBasedOnEachFlow(
      [dataConfirmationFlowsStepsIndexes, phoneNumberValidationFlowsStepsIndexes],
      templateStepsOrderValidationsRules.BETWEEN_TWO_STEPS,
    );

  validateStepThatRequiresAnotherStep(
    ifTemplateHasPhoneNumberValidationMustHaveDataConfirmationValidations,
    phoneNumberValidationFlows,
    dataConfirmationFlows,
    templateValidationNotificationsToAdd,
    templateValidationsMessages["TEMPLATE_MUST_HAS_DATA_CONFIRMATION_IN_CASE_OF_PHONE_NUMBER-VALIDATION"],
  );
};

// The "DATA_CONFIRMATION" step must has phone_number field in case of "PHONE_NUMBER_VALIDATION"
export const dataConfirmationMustHasPhoneNumberFieldInCaseOfPhoneNumberStep = (
  phoneNumberValidationFlowsStepsIndexes = [],
  phoneNumberValidationFlows = [],
  onboardingOptionsSteps = [],
  templateValidationNotificationsToAdd = () => [],
  defaultFlowName = "",
) => {
  if (!phoneNumberValidationFlowsStepsIndexes?.length) {
    return;
  }

  validateIfStepHasSpecificFieldBasedOnSecondaryStep(
    phoneNumberValidationFlows,
    "phone_number",
    steps.DATA_CONFIRMATION.name,
    onboardingOptionsSteps,
    templateValidationNotificationsToAdd,
    templateValidationsMessages["DATA_CONFIRMATION_MUST_HAS_PHONE_NUMBER_FIELD_IN_CASE_OF_PHONE_NUMBER-VALIDATION"],
    defaultFlowName,
  );

  validateIfStepHasSpecificFieldBasedOnSecondaryStep(
    phoneNumberValidationFlows,
    "cpf",
    steps.DATA_CONFIRMATION.name,
    onboardingOptionsSteps,
    templateValidationNotificationsToAdd,
    templateValidationsMessages["THE_DATA_CONFIRMATION_MUST_HAVE_A_CPF_FIELD_IN_CASE_OF_A_PHONE_NUMBER-VALIDATION"],
    defaultFlowName,
  );
};
