import Onboarding from "@package/steps";
import { onboardingPropsParserTranslator } from "@utils/translator";
import cloneDeep from "lodash.clonedeep";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import isObject from "lodash.isobject";
import omit from "lodash.omit";
import pick from "lodash.pick";
import pickBy from "lodash.pickby";

import { removeKeys } from ".";

const { steps, rules, defaultOnboardingValues } = Onboarding;

/** Remove properties not used by Builder */
export const cleanStep = (step) => {
  return removeKeys(["component", "componentNew", "previewStep", "noPadding", "fields"], step);
};

/** Parse a step name to get its name and index. Ex.: "WELCOME-0" = ["WELCOME", 0] */
export function parseStep(name = "") {
  let [stepName, stepIndex] = name.split("-");
  stepIndex = stepIndex && parseInt(stepIndex);
  return [stepName, stepIndex];
}

/** Returns the step name with its correct index */
export function indexStep(steps = [], name = "") {
  if (!isNaN(parseStep(name)[1])) return name;

  let repeatedSteps = steps.filter((s) => parseStep(s.name)[0] === name) || [];
  repeatedSteps = repeatedSteps?.sort((a, b) => (parseStep(a.name)[1] > parseStep(b.name)[1] ? 1 : -1));

  let nameWithIndex = `${name}-${parseStep(repeatedSteps[repeatedSteps.length - 1]?.name)[1] + 1 || 0}`;
  return nameWithIndex;
}

export function isEnabledStep(step) {
  return step?.isEnabled;
}

/** Format domain url based on environments */
export function buildDomainURL(initialDomain) {
  if (initialDomain) return `https://${initialDomain + "."}${process.env.REACT_APP_ONBOARDING_DEFAULT_DOMAIN}`;

  return `https://${process.env.REACT_APP_ONBOARDING_DEFAULT_DOMAIN}`;
}

/** Prepare onboarding options for exporting */
export function prepareOnboardingOptions(onboardingOptions = {}, language) {
  return pickBy(
    {
      ...onboardingOptions,
      documentTypes: cleanDocumentTypes(onboardingOptions?.documentTypes, language),
      steps: cleanOnboardingSteps(onboardingOptions?.steps, language),
      rules: cleanOnboardingRules(onboardingOptions?.rules, language),
      variables: cleanOnboardingVariables(onboardingOptions?.variables),
      domain: buildDomainURL(onboardingOptions.domain),
      helpButtonLink: !!onboardingOptions.helpButtonLink ? `https://${onboardingOptions.helpButtonLink}` : undefined,
    },
    (v) => (Array.isArray(v) || isObject(v) ? !isEmpty(v) : true), // Removes empty objects and arrays
  );
}

/** Remove unnecessary properties in documentTypes */
export function cleanDocumentTypes(documentTypes = [], language) {
  let newDocumentTypes = cloneDeep(documentTypes);
  return newDocumentTypes
    .map((onboardingDoc) => {
      if (!onboardingDoc.type) return null;
      let omits = [];
      let doc = onboardingPropsParserTranslator(defaultOnboardingValues?.defaultDocumentTypesOptions, language)?.[
        onboardingDoc.type
      ];
      Object.keys(onboardingDoc)
        .filter((key) => key !== "type")
        .forEach((prop) => {
          if (onboardingDoc[prop] == null || isEqual(doc[prop], onboardingDoc[prop])) omits.push(prop);
        });
      return omit(onboardingDoc, omits);
    })
    .filter((doc) => doc);
}

/** Special properties to remove from steps */
const propertiesToRemove = [
  // DD and PFL preview step
  "previewStep",
  // QSA special steps
  "numberStep",
  "contactInfoStep",
  "contactStep",
];

/** Remove unnecessary properties on steps */
export function cleanOnboardingSteps(onboardingSteps = [], language) {
  let newOnboardingStepsClone = cloneDeep(onboardingSteps);

  // TODO: dinamically search objects and arrays within onboardingSteps
  const newOnboardingSteps = newOnboardingStepsClone.map((onboardingStep) => {
    if (!onboardingStep.name) return null;
    let omits = ["description", "longDescription", "tags"];
    let step = steps[parseStep(onboardingStep.name)[0]];

    if (onboardingStep?.components) {
      return pick(onboardingStep, ["name", "components"]);
    }

    // Remove null/undefined props or if its equal to the default value
    Object.keys(onboardingStep)
      .filter((key) => !["name", "fields", "sdkOptions", "preview"]?.includes(key))
      .forEach((prop) => {
        if (
          onboardingStep[prop] == null ||
          propertiesToRemove.includes(prop) ||
          isEqual(step[prop], onboardingStep[prop])
        ) {
          omits.push(prop);
        }
      });
    // Do the same to "fields" prop
    if (onboardingStep.fields) {
      if (step.fields == null) {
        omits.push("fields");
      } else {
        onboardingStep.fields?.forEach((field, i) => {
          let stepField = step.fields.find((stepField) => stepField.field === field.field);
          Object.keys(field)
            .filter((key) => key !== "field" && key !== "type" && key !== "title" && key !== "extensions")
            .forEach((prop) => {
              if (stepField?.canBeSentAsDefault) {
                return;
              } else {
                if (prop === "fields" || field[prop] == null || (stepField && isEqual(field[prop], stepField[prop])))
                  omits.push(`fields[${i}].${prop}`);
              }
            });
        });
      }
    }
    // Do the same to "fieldCnpj" prop
    if (onboardingStep.fieldCnpj) {
      if (!step.fieldCnpj) omits.push("fieldCnpj");
      else
        Object.keys(onboardingStep.fieldCnpj).forEach((prop) => {
          if (onboardingStep.fieldCnpj[prop] == null || isEqual(onboardingStep.fieldCnpj[prop], step.fieldCnpj[prop]))
            omits.push(`fieldCnpj.${prop}`);
        });
    }
    // Do the same to "errors" prop
    if (onboardingStep.errors) {
      if (step.errors == null) omits.push("errors");
      else
        Object.keys(onboardingStep.errors)?.forEach((errorName, i) => {
          let error = onboardingStep.errors[errorName];
          let stepError = step.errors[Object.keys(step.errors)?.find((stepErrorName) => stepErrorName === errorName)];
          Object.keys(error).forEach((prop) => {
            if (error[prop] == null || (stepError && isEqual(error[prop], stepError[prop])))
              omits.push(`errors[${errorName}][${prop}]`);
          });
        });
    }
    return omit(onboardingStep, omits);
  });
  return newOnboardingSteps;
}

/** Remove unnecessary properties on rules */
export function cleanOnboardingRules(onboardingRules = [], language) {
  const onboardingRulesClean = onboardingRules
    ?.map((onboardingRule) => {
      if (!onboardingRule.id) return null;
      let omits = [];
      let rule = onboardingPropsParserTranslator(rules, language)[onboardingRule.id];
      Object.keys(rule)
        .filter((key) => key !== "id")
        .forEach((prop) => {
          if (isEqual(rule[prop], onboardingRule[prop])) omits.push(prop);
        });
      return omit(onboardingRule, omits);
    })
    .filter((onboardingRule) => onboardingRule);

  return isEmpty(onboardingRulesClean) ? null : onboardingRulesClean;
}

export function cleanOnboardingVariables(variables = []) {
  const variablesFormattedToObject = variables?.reduce((acc, cur) => {
    if (cur.variableName && cur.variableValue) {
      acc[cur.variableName] = cur.variableValue;
    }
    return acc;
  }, {});

  return isEmpty(variablesFormattedToObject) ? null : variablesFormattedToObject;
}

/** Prepare onboarding options for importing */
export function unprepareOnboardingOptions(onboardingOptions = {}) {
  return {
    ...onboardingOptions,
    domain: (onboardingOptions?.domain || "").replace(
      /.?(?:dev.cadastro.io|beta.cadastro.io|cadastro.io|http(s)?:\/\/)/g, // TODO: Replace with a more dynamic use of URLs
      "",
    ),
    helpButtonLink: (onboardingOptions?.helpButtonLink || "").replace(/.?(?:http(s)?:\/\/)/g, ""),
    terms: onboardingOptions?.terms || [],
    variables:
      onboardingOptions?.variables &&
      Object.keys(onboardingOptions?.variables)?.map((key) => ({
        variableName: key,
        variableValue: onboardingOptions?.variables[key],
      })),
  };
}
