import clockIcon from "@assets/icons/clock.svg";
import { Button as CafButton, Icon } from "@combateafraude/react";
import { OnboardingInput } from "@components/Inputs";
import { onboardingInputTypes } from "@components/Inputs/OnboardingInput";
import { TemplateVariableGuideBody } from "@components/Modal";
import { previewModes } from "@components/ObjectListEditor";
import { useAPIContext } from "@contexts/API";
import { useModal } from "@contexts/Modal";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import useAPI from "@hooks/useAPI";
import useUpdateEffect from "@hooks/useUpdateEffect";
import { isEmpty } from "@utils";
import { truncate } from "@utils/formatting";
import { Collapse, Form, Tooltip } from "antd";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import PaneTitle from "../PaneTitle";

const { Panel } = Collapse;
const I18N_BASE_PATH = "src.components.sidebar";

const Settings = () => {
  const { t } = useTranslation();
  const { onboardingOptions, onboardingLanguage } = useOnboardingOptionsContext();
  const { tenantId } = useAPIContext();

  const [getEmailTemplatesRequest, getEmailTemplatesLoading, getEmailTemplatesData] = useAPI();
  const [shouldUpdateEmailTemplates, setShouldUpdateEmailTemplates] = useState(false);

  const shouldRenderIncreaseFontMessage = onboardingOptions.configurations?.accessibility?.increaseFont;
  const monochromeIsActive = onboardingOptions.configurations?.accessibility?.monochromeMode;

  useEffect(() => {
    if (tenantId) setShouldUpdateEmailTemplates(true);
  }, [tenantId]);
  useUpdateEffect(() => {
    if (shouldUpdateEmailTemplates) {
      getEmailTemplatesRequest(
        `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API_V1}accounts/${tenantId}/email-templates?_includeDefault=true&_all=true&_language=${onboardingLanguage}`,
      );
      setShouldUpdateEmailTemplates(false);
    }
  }, [shouldUpdateEmailTemplates]);

  const validateSchedule = useCallback((object, allValues) => {
    const { after, shippingMethods } = object;

    if (after > 730) {
      return t(
        `src.components.sidebar.components.tabs.settings.form.schedule.modal.input.fields.after.validation.quantity`,
        "O intervalo máximo é de 730 horas.",
      );
    }
    if (!after) {
      return t(
        `${I18N_BASE_PATH}.components.tabs.settings.form.schedule.modal.input.fields.after.validation.required`,
        "Antes de criar o intervalo, informe a quantidade de horas.",
      );
    }
    if (isEmpty(shippingMethods)) {
      return t(
        `${I18N_BASE_PATH}.components.tabs.settings.form.schedule.modal.input.fields.shippingMethods.validation.required`,
        "Para criar o intervalo, informe pelo menos um método de envio.",
      );
    }

    const previousValues = allValues.slice(0, -1);
    const previousShippingMethodsWithSameAfter = previousValues
      .filter((value) => value.after === after)
      .map(({ shippingMethods }) => shippingMethods)
      .flat();

    const alreadyAdded = shippingMethods.every((method) => previousShippingMethodsWithSameAfter.includes(method));

    if (alreadyAdded)
      return t(
        `${I18N_BASE_PATH}.components.tabs.settings.form.schedule.modal.input.validations.unique`,
        "Esse intervalo já foi criado. Crie um intervalo diferente ou remova o anterior.",
      );
  }, []);

  // validação se contem a palavra banco no domínio para exibir aviso
  let containsBank = false;
  const domain = onboardingOptions?.domain;
  if (domain.match(/banco/gi) || domain.match(/bank/gi)) {
    containsBank = true;
  }

  const openTemplateVariableGuide = useModal();
  return (
    <>
      <PaneTitle title={t(`${I18N_BASE_PATH}.components.tabs.settings.title`, "Configurações")} />
      <Form layout="vertical">
        <Collapse expandIconPosition="right" ghost className="!bg-transparent !border-0">
          {onboardingOptions?.type === "PJ" && ( // TODO: Change verification if PF rules are implemented
            <Panel
              className="text-xl font-bold !border-0"
              header={t(`${I18N_BASE_PATH}.components.tabs.settings.form.rules.panelHeader`, "Regras")}
              key="0"
            >
              <OnboardingInput.RulesList property="rules" />
            </Panel>
          )}
          <Panel className="text-xl font-bold !border-0" header={t("general.label.properties", "Propriedades")} key="1">
            <Form.Item className="!mb-3" label={t("general.label.favicon", "Favicon")}>
              <OnboardingInput.Image property="favicon" />
            </Form.Item>
            <Form.Item className="!mb-3" label={t("general.templatePropertiesBody.title", "Título")}>
              <OnboardingInput property="name" required />
            </Form.Item>
            <Form.Item className="!mb-3" label={t("general.templatePropertiesBody.description", "Descrição")}>
              <OnboardingInput property="description" />
            </Form.Item>
          </Panel>
          <Panel
            className="text-xl font-bold !border-0"
            header={t(`${I18N_BASE_PATH}.components.tabs.settings.form.general.panelHeader`, "Geral")}
            key="2"
          >
            <Form.Item
              className="!mb-3 font-normal"
              label={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.emailTemplate.label`,
                "Template de email",
              )}
              tooltip={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.emailTemplate.tooltip`,
                "Email customizado enviado na geração de link para Onboarding que esteja utilizando este template",
              )}
            >
              <OnboardingInput.Select
                property="emailTemplateId"
                options={getEmailTemplatesData?.data}
                loading={getEmailTemplatesLoading}
                disabled={!getEmailTemplatesLoading && !getEmailTemplatesData?.data?.length}
                defaultValue={getEmailTemplatesData?.data?.[0]?._id}
                placeholder={
                  !tenantId
                    ? t(
                        `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.emailTemplate.placeholder.hasNoTenantId`,
                        "Nenhum tenantId informado",
                      )
                    : getEmailTemplatesLoading
                    ? t("general.label.searchingForTemplate", "Buscando templates...")
                    : getEmailTemplatesData?.data?.length
                    ? t(
                        `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.emailTemplate.placeholder.default`,
                        "Selecione um template...",
                      )
                    : t(
                        `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.emailTemplate.placeholder.error`,
                        "Erro ao buscar templates",
                      )
                }
                labelKey="name"
                valueKey="_id"
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <hr />
                    <div className="flex flex-col py-3 space-y-2">
                      <a
                        href={`${process.env.REACT_APP_URL}email?tenantId=${tenantId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <CafButton type="text" block className="!text-left !bg-transparent !shadow-none">
                          <Icon icon="settings" />{" "}
                          {t(
                            `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.emailTemplate.dropdownRender.settings`,
                            "Gerir templates",
                          )}
                        </CafButton>
                      </a>
                      <CafButton
                        type="text"
                        block
                        className="!text-left !bg-transparent !shadow-none"
                        onClick={() => setShouldUpdateEmailTemplates(true)}
                      >
                        <Icon icon="processing_line" /> {t("general.label.update", "Atualizar")}
                      </CafButton>
                    </div>
                  </div>
                )}
              />
            </Form.Item>
            <Form.Item
              className="!mb-3"
              label={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.domain.label`,
                `Domínio (${process.env.REACT_APP_ENV})`,
                { domain: process.env.REACT_APP_ENV },
              )}
              tooltip={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.domain.tooltip`,
                "Prefixo da URL do Onboarding caso o domínio for modificado, é necessário criar um DNS para o mesmo. | Algumas palavras do domínio podem ser bloqueadas por operadoras, prejudicando o envio de SMS.",
              )}
              extra={
                containsBank && (
                  <span className="text-xs text-red-500">
                    {t(
                      `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.domain.alert`,
                      'Ao utilizarmos a palavra "Banco" no domínio do Link, as mensagens SMS podem não ser entregues ao destinatário.',
                    )}
                  </span>
                )
              }
            >
              <OnboardingInput
                property="domain"
                suffix={
                  <Tooltip title={`.${process.env.REACT_APP_ONBOARDING_DEFAULT_DOMAIN}`}>
                    <span className="text-xs">.{truncate(process.env.REACT_APP_ONBOARDING_DEFAULT_DOMAIN, 10)}</span>
                  </Tooltip>
                }
                prefix={<span className="text-xs">https://</span>}
              />
            </Form.Item>
            <Form.Item
              className="!mb-3"
              label={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.helpButtonLink.label`,
                "Botão de ajuda",
              )}
              tooltip={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.helpButtonLink.tooltip`,
                "URL para uma central de ajuda no qual o usuário será redirecionado caso clique no botão",
              )}
            >
              <OnboardingInput property="helpButtonLink" prefix={<span className="text-xs">https://</span>} />
            </Form.Item>
            <Form.Item
              className="!mb-3"
              label={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.mobileOnly.label`,
                "Apenas mobile",
              )}
              tooltip={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.mobileOnly.tooltip`,
                "Será possível realizar o Onboarding apenas por dispositivos móveis (ajuda a garantir uma qualidade de fotos melhor)",
              )}
            >
              <OnboardingInput.Switch property="mobileOnly" />
            </Form.Item>
            <Form.Item
              className="!mb-3"
              label={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.captureAcceptedTermsData.label`,
                "Capturar dados no aceite de termos",
              )}
              tooltip={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.captureAcceptedTermsData.tooltip`,
                "Quando o usuário aceitar algum termo, serão capturados algumas informações como data e hora do aceite, IP do usuário e detalhes do navegador",
              )}
            >
              <OnboardingInput.Switch property="configurations.captureAcceptedTermsData" />
            </Form.Item>
            <Form.Item
              className="!mb-3"
              label={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.verifyBrowserCompatibility.label`,
                "Aviso de compatibilidade de navegador",
              )}
              tooltip={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.verifyBrowserCompatibility.tooltip`,
                "Ativa um modal de aviso ao usuário informando compatibilidade com os navegadores Chrome, Firefox e Safari.",
              )}
            >
              <OnboardingInput.Switch property="configurations.verifyBrowserCompatibility" />
            </Form.Item>
            <Form.Item
              className="!mb-3"
              label={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.dataReload.label`,
                "Reload de dados",
              )}
              tooltip={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.dataReload.tooltip`,
                "Caso o usuário preencha os dados, mas não finalize o Onboarding, esses dados são salvos durante 24 horas",
              )}
            >
              <OnboardingInput.Switch property="configurations.dataReload" />
            </Form.Item>
            <Form.Item
              className="!mb-3"
              label={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.immutableAttributes.label`,
                "Atributos imutáveis",
              )}
              tooltip={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.general.options.immutableAttributes.tooltip`,
                "Se um atributo for informado na criação do link de onboarding, como por exemplo cpf, nome ou data de nascimento, eles não serão alterados ao longo do fluxo de onboarding, estes valores estarão presentes na transação",
              )}
            >
              <OnboardingInput.Switch property="configurations.immutableAttributes" />
            </Form.Item>
            {/* - "I miss you guys" - Cruz, July 29th 2022 */}
            {/* <Form.Item
              className="!mb-3"
              label="Card central expansível"
              tooltip="Permite que a altura do Card central se expanda conforme o tamanho do conteúdo até uma altura máxima pré-determinada"
            >
              <OnboardingInput.Switch defaultValue={true} property="configurations.isCardExpandable" />
            </Form.Item> */}
          </Panel>
          <Panel
            className="text-xl font-bold !border-0"
            header={t(`${I18N_BASE_PATH}.components.tabs.settings.form.schedule.panelHeader`, "Reenvio de link")}
            key="3"
          >
            <div className="p-2 border bg-gray-100 border-gray-500 rounded-md mb-3">
              <p className="text-xs mb-0 font-semibold">
                {t(
                  `${I18N_BASE_PATH}.components.tabs.settings.form.schedule.modal.title.understanding`,
                  "Configure intervalos de reenvio de link quando o status for pendente",
                )}
              </p>
            </div>
            <div className="p-2 border bg-yellow-100 border-yellow-600 rounded-md mb-3">
              <p className="text-xs mb-0 font-semibold">
                {t(
                  `src.components.sidebar.components.tabs.settings.form.schedule.modal.title.warning`,
                  "O reenvio de link é feito apenas 1 vez, não existe uma rotina de recorrência para enviá-lo. Se você deseja mais envios, deverá ser adicionado mais intervalos.",
                )}
              </p>
            </div>
            <OnboardingInput.ObjectListEditor
              property="schedule"
              isRootProperty={true}
              canEdit={false}
              draggable={false}
              validateObject={validateSchedule}
              saveButtonLabel={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.schedule.modal.button.label`,
                "Criar intervalo",
              )}
              objectName={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.schedule.modal.input.objectName`,
                "intervalo",
              )}
              smallPreview
              fixedPreviewLabel={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.schedule.preview.label`,
                "Reenviar após",
              )}
              fields={[
                {
                  property: "after",
                  type: onboardingInputTypes.NUMBER,
                  label: t(
                    `${I18N_BASE_PATH}.components.tabs.settings.form.schedule.modal.input.fields.after.label`,
                    "Reenviar após",
                  ),
                  suffix: t(
                    `${I18N_BASE_PATH}.components.tabs.settings.form.schedule.modal.input.fields.after.suffix`,
                    "Horas",
                  ),
                  tooltip: t(
                    `src.components.sidebar.components.tabs.settings.form.schedule.modal.input.fields.after.tooltip`,
                    "O envio acontece aos 20min de cada hora e não obedece o horário de criação. Se o link for criado às 16:25, e o reenvio está para acontecer depois de 1h, ele acontecerá às 18:20",
                  ),
                  min: 1,
                  previewMode: previewModes.TEXT,
                  previewTextSuffix: "h",
                  previewTextIcon: clockIcon,
                },
                {
                  property: "shippingMethods",
                  type: onboardingInputTypes.MULTIPLE_CHECKBOX,
                  previewMode: previewModes.LIST,
                  options: [
                    {
                      label: `${I18N_BASE_PATH}.components.tabs.settings.form.schedule.modal.input.fields.shippingMethods.options.sms`,
                      value: "sms",
                    },
                    {
                      label: `${I18N_BASE_PATH}.components.tabs.settings.form.schedule.modal.input.fields.shippingMethods.options.email`,
                      value: "email",
                    },
                  ],
                  labelKey: "label",
                  valueKey: "value",
                  label: t(
                    `${I18N_BASE_PATH}.components.tabs.settings.form.schedule.modal.input.fields.shippingMethods.label`,
                    "Forma de Envio",
                  ),
                },
              ]}
            />
          </Panel>
          <Panel
            className="text-xl font-bold !border-0"
            header={t(`${I18N_BASE_PATH}.components.tabs.settings.form.variables.panelHeader`, "Variáveis")}
            key="4"
          >
            <div className="p-2 border bg-gray-100 border-gray-500 rounded-md mb-3">
              <p className="text-xs mb-0 font-semibold">
                {t(
                  `${I18N_BASE_PATH}.components.tabs.settings.form.variables.modal.title.understanding`,
                  "Para compreender melhor como funcionam as variáveis, clique",
                )}{" "}
                <a
                  onClick={() =>
                    openTemplateVariableGuide(
                      t(
                        `${I18N_BASE_PATH}.components.tabs.settings.form.variables.modal.heading`,
                        "Variáveis: o que são, como criar e utilizar",
                      ),
                      <TemplateVariableGuideBody />,
                    )
                  }
                  className="text-caf-primary hover:underline hover:text-caf-primary hover:opacity-80 transition-opacity duration-100"
                >
                  {t(`${I18N_BASE_PATH}.components.tabs.settings.form.variables.modal.title.middle`, "aqui")}
                </a>{" "}
                {t(
                  `${I18N_BASE_PATH}.components.tabs.settings.form.variables.modal.title.completeGuide`,
                  "para um guia completo",
                )}
              </p>
            </div>
            <OnboardingInput.ObjectListEditor
              property="variables"
              objectName={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.variables.modal.input.objectName`,
                "variável",
              )}
              isRootProperty={true}
              smallPreview={true}
              fields={[
                {
                  property: "variableName",
                  type: onboardingInputTypes.TEXT,
                  previewMode: previewModes.LABEL,
                  label: t(
                    `${I18N_BASE_PATH}.components.tabs.settings.form.variables.modal.input.fields.variableName.label`,
                    "Nome",
                  ),
                  tooltip: t(
                    `${I18N_BASE_PATH}.components.tabs.settings.form.variables.modal.input.fields.variableName.tooltip`,
                    "Nome que irá referenciar o valor da variável",
                  ),
                  userCantTypeInvalidCharacters: true,
                },
                {
                  property: "variableValue",
                  type: onboardingInputTypes.TEXT,
                  previewMode: previewModes.TEXT,
                  label: t(
                    `${I18N_BASE_PATH}.components.tabs.settings.form.variables.modal.input.fields.variableValue.label`,
                    "Valor",
                  ),
                },
              ]}
            />
          </Panel>
          <Panel
            className="text-xl font-bold !border-0"
            header={t(
              `${I18N_BASE_PATH}.components.tabs.settings.form.accessibilityOptions.panelHeader`,
              "Opções de Acessibilidade",
            )}
            key="5"
          >
            <Form.Item
              className="!mb-3"
              label={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.accessibilityOptions.highContrast.label`,
                "Alto contraste",
              )}
              tooltip={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.accessibilityOptions.highContrast.tooltip`,
                "O fundo escuro com letras brancas é mais agradável para a visão e facilita a legibilidade.",
              )}
            >
              <OnboardingInput.Switch property="configurations.accessibility.highContrast" />
            </Form.Item>
            <Form.Item
              className="!mb-3"
              label={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.accessibilityOptions.increaseFont.label`,
                "Aumentar todas as fontes",
              )}
              tooltip={t(
                `${I18N_BASE_PATH}.components.tabs.settings.form.accessibilityOptions.increaseFont.tooltip`,
                "Todas as fontes ficam em negrito facilitando a leitura.",
              )}
            >
              <OnboardingInput.Switch property="configurations.accessibility.increaseFont" />
              {shouldRenderIncreaseFontMessage && (
                <div className="text-xs text-red-500 font-normal pt-4">
                  <Trans
                    i18nKey={`${I18N_BASE_PATH}.components.tabs.settings.form.accessibilityOptions.increaseFont.description`}
                  >
                    <strong className="font-extrabold">Atenção:</strong> Ao selecionar essa opção todas as fontes
                    personalizadas serão substituidas pela fonte padrão <br />
                    <strong className="font-extrabold">Roboto ExtraBold.</strong>
                  </Trans>
                </div>
              )}
            </Form.Item>
            <div className="items-start justify-between flex w-full">
              <Form.Item
                className="!mb-3"
                label={t(
                  `${I18N_BASE_PATH}.components.tabs.settings.form.accessibilityOptions.monochrome.label`,
                  "Modo Monocromático",
                )}
                tooltip={t(
                  `${I18N_BASE_PATH}.components.tabs.settings.form.accessibilityOptions.monochrome.tooltip`,
                  "Um esquema de cores única facilita a leitura de pessoas com daltonismo.",
                )}
              >
                <OnboardingInput.Switch property="configurations.accessibility.monochromeMode" />
                {monochromeIsActive && (
                  <div className="py-5 flex-col items-center justify-between">
                    <span className="text-xs font-bold text-black">
                      {t(
                        `${I18N_BASE_PATH}.components.tabs.settings.form.accessibilityOptions.monochrome.description`,
                        "Esquema de cores do modo Monocromático",
                      )}
                    </span>
                    <div className="mt-3">
                      <OnboardingInput.Color
                        property="configurations.accessibility.monochromeColor"
                        defaultColor="#E21B45"
                      />
                    </div>
                  </div>
                )}
              </Form.Item>
              <Icon icon={monochromeIsActive ? "arrow_down" : "arrow_right"} size={"sm"} />
            </div>
          </Panel>
        </Collapse>
      </Form>
    </>
  );
};

export default Settings;
