import CustomizableElementsEditor from "@components/CustomizableElementsEditor";
import FloatingSidebar from "@components/FloatingSidebar";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import { useSidebarEditorContext } from "@contexts/SidebarEditor";
import useFloatingSidebar from "@hooks/useFloatingSidebar";
import Onboarding from "@package/steps";
import { parseStep } from "@utils/onboarding";
import { onboardingPropsParserTranslator } from "@utils/translator";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
const { steps } = Onboarding;

/** Sidebar for editing steps and customizableELements */
const EditSidebar = () => {
  const { t } = useTranslation();
  const { onboardingLanguage } = useOnboardingOptionsContext();

  const {
    selectedStep,
    selectedStepIndex,
    selectedCustomizableElementName,
    selectedCustomizableElementPath,
    selectedCustomizableElement,
    selectedErrorName,
    unselectAllElements,
  } = useSidebarEditorContext();
  const [showSidebar, hideSidebar, isSidebarVisible] = useFloatingSidebar({ onHide: () => unselectAllElements() });

  const [selectedStepTranslated, setSelectedStepTranslated] = useState(selectedStep);

  useEffect(() => {
    if ((!selectedStep && !selectedCustomizableElement) || selectedStep?.components) {
      hideSidebar();
      return;
    }

    // If the user clicks the edge (arrow), don't open the step edit sidebar
    if (selectedStep && Object.values(selectedStep)?.every((value) => !value)) {
      hideSidebar();
      return;
    }

    showSidebar();
  }, [selectedStep, selectedCustomizableElement]);

  useEffect(() => {
    setSelectedStepTranslated(onboardingPropsParserTranslator(selectedStep, onboardingLanguage));
  }, [selectedStep, onboardingLanguage]);

  const stepData = useMemo(() => steps[parseStep(selectedStep?.name)[0]], [selectedStep]);

  return (
    <FloatingSidebar
      {...{ isVisible: isSidebarVisible, hide: hideSidebar }}
      icon={stepData?.emoji || selectedCustomizableElement?.emoji}
      title={
        t(selectedCustomizableElement?.name) ||
        t(`general.steps.${parseStep(selectedStep?.name)[0]}.description`, selectedStep?.description)
      }
    >
      <CustomizableElementsEditor
        {...{
          step: selectedStepTranslated,
          stepIndex: selectedStepIndex,
          errorName: selectedErrorName,
          customizableElement: selectedCustomizableElement,
          customizableElementName: selectedCustomizableElementName,
          customizableElementPath: selectedCustomizableElementPath,
        }}
      />
    </FloatingSidebar>
  );
};

export default EditSidebar;
