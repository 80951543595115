import { useCallback, useRef, useState } from "react";

const useFloatingSidebar = ({ onShow, onHide } = {}) => {
  const [isVisible, setIsVisible] = useState(false);
  const isHiding = useRef(false);

  const show = useCallback(() => {
    isHiding.current = false;
    setIsVisible(true);
    if (typeof onShow === "function") onShow();
  }, [setIsVisible, onShow]);

  const hide = useCallback(() => {
    if (isVisible) {
      isHiding.current = true;
      setIsVisible(false);
      setTimeout(() => {
        if (isHiding.current) {
          isHiding.current = false;
        }
      }, 300);
      if (typeof onHide === "function") onHide();
    }
  }, [isVisible, setIsVisible, onHide]);

  return [show, hide, isVisible];
};

export default useFloatingSidebar;
