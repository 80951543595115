import { Icon } from "@combateafraude/react";
import { Button as AntButton } from "antd";
import isEqual from "lodash.isequal";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";

import ObjectPreview from "./ObjectPreview";

const I18N_BASE_PATH = "src.components.objectListEditor";

/** Edits an array of objects */
const ObjectListEditor = ({
  property,
  isRootProperty,
  stepIndex,
  inputValue,
  onInputChange,
  fields,
  options,
  // If should limit so there can be only one of each option
  limitOptions = false,
  // Main property of the options
  optionsValueKey,
  // What you call the object
  objectName = "",
  canNotRemoveThisField,
  canEdit,
  validateObject,
  canRemoveAll,
  ...rest
}) => {
  const { t } = useTranslation();

  const removeObject = useCallback(
    (index) => {
      onInputChange([...inputValue.slice(0, index), ...inputValue.slice(index + 1, inputValue.length)]);
    },
    [inputValue, onInputChange],
  );

  const [lastAddedObjectIndex, setLastAddedObjectIndex] = useState();
  const [editObjectIndex, setEditObjectIndex] = useState();

  const defaultAttachedPictureDocument = {
    id: "attachedPicture",
    icon: "image",
    title: `${I18N_BASE_PATH}.objectListEditorByDefaultFields.constants.defaultDocumentsToAdd.attachedPicture.title`,
    // => Foto
    description: `${I18N_BASE_PATH}.objectListEditorByDefaultFields.constants.defaultDocumentsToAdd.attachedPicture.description`,
    // => Carregue fotos em JPG ou PNG
  };

  const addObject = useCallback(
    (newObject = {}) => {
      setLastAddedObjectIndex(inputValue?.length || 0);
      onInputChange([...(inputValue || []), newObject]);
    },
    [inputValue, onInputChange],
  );

  const filteredOptions = useMemo(
    () =>
      options?.filter(
        (option) =>
          !limitOptions ||
          !inputValue?.some(
            (value) => value?.[optionsValueKey] === option?.[optionsValueKey] && !option?.acceptMultipleFields,
          ),
      ),
    [inputValue, options, limitOptions, optionsValueKey],
  );

  let currentOption = {};
  if (options && limitOptions && (!isNaN(lastAddedObjectIndex) || !isNaN(editObjectIndex))) {
    const index = lastAddedObjectIndex || editObjectIndex;
    currentOption =
      options.find((option) => inputValue?.[index]?.[optionsValueKey] === option?.[optionsValueKey]) || {};
  }

  useEffect(() => {
    if (inputValue) {
      if (!options?.length) setInputValueUpdated(inputValue);
      else {
        let updateAllFields = false;
        let newObjects = inputValue.map((object, index) => {
          const optionsFoundedInObject = options?.find((option) => option[optionsValueKey] === object[optionsValueKey]);
          // On change optionsValueKey field update all the others
          if (inputValueUpdated?.[index] && inputValueUpdated[index][optionsValueKey] !== object[optionsValueKey]) {
            updateAllFields = true;
            return { ...object, ...optionsFoundedInObject };
          }

          return { ...optionsFoundedInObject, ...object };
        });

        if (updateAllFields) {
          setTimeout(() => {
            setInputValueUpdated(newObjects);
            onInputChange(newObjects);
          }, 100);
        } else if (!isEqual(inputValueUpdated, newObjects)) {
          setTimeout(() => setInputValueUpdated(newObjects), 100);
        }
      }
    }
  }, [inputValue]);

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [inputValueUpdated, setInputValueUpdated] = useState(inputValue);
  // Update input value that has default values brought by template
  useEffect(() => {
    if (inputValueUpdated && isFirstRender) {
      setIsFirstRender(false);
      onInputChange(inputValueUpdated);
    }
  }, [inputValueUpdated]);

  const handleUpdateOnboardingOptionsOnSort = useCallback(
    (values) => {
      // Removes attribute "chosen" and "selected" that are added by react-sortablejs when reordering the list
      const valuesFormatted = values?.map((value) => {
        delete value["chosen"];
        delete value["selected"];

        return value;
      });

      onInputChange(valuesFormatted);
    },
    [onInputChange],
  );

  const finalOptions = useMemo(() => {
    const filteredOptionsValidated = !!currentOption?.acceptMultipleFields
      ? filteredOptions?.filter((option) => !option?.acceptMultipleFields && option?.type !== currentOption?.type)
      : filteredOptions;

    return limitOptions && filteredOptions ? [...[currentOption], ...filteredOptionsValidated] : filteredOptions;
  }, [limitOptions, filteredOptions, currentOption]);

  const canRemoveField = (field) => {
    if (canNotRemoveThisField) {
      return field[canNotRemoveThisField?.key] !== canNotRemoveThisField?.value;
    }
    return true;
  };

  const validate = useCallback((object) => validateObject?.(object, inputValue), [inputValue, validateObject]);

  const canRemoveWhenUploadIsPdf = canNotRemoveThisField?.value === "uploadPdf";
  const canShowAddButton =
    !limitOptions || filteredOptions?.length > 0 || (inputValue?.length !== 2 && canRemoveWhenUploadIsPdf);

  const canRemoveFields = (object) => (canRemoveAll === undefined ? canRemoveField(object) : canRemoveAll);

  return (
    <div className="flex flex-col space-y-2">
      <ReactSortable
        list={inputValue || []}
        setList={handleUpdateOnboardingOptionsOnSort || (() => {})}
        animation={150}
        className={"space-y-2"}
        handle={".dragger-handle"}
      >
        {inputValue?.map?.((object, i) => {
          const extraFields = options?.find((o) => o?.type === object?.type)?.fields;

          return (
            <ObjectPreview
              key={`OBJECT_PREVIEW_${i}`}
              property={property}
              isRootProperty={isRootProperty}
              stepIndex={stepIndex}
              fields={[...fields, ...(extraFields || object?.fields || [])]}
              object={inputValue?.[i]}
              objectIndex={i}
              objectName={t(objectName, objectName)}
              lastAddedObjectIndex={lastAddedObjectIndex}
              setEditObjectIndex={setEditObjectIndex}
              clearLastAddedObjectIndex={() => setLastAddedObjectIndex(undefined)}
              clearEditObjectIndex={() => setEditObjectIndex(undefined)}
              removeObject={() => removeObject(i)}
              options={finalOptions}
              small={rest?.smallPreview}
              canRemoveField={canRemoveFields(object)}
              isDefaultRequired={object.defaultRequired}
              saveButtonLabel={rest?.saveButtonLabel}
              canEdit={canEdit}
              draggable={rest?.draggable}
              fixedLabel={rest?.fixedPreviewLabel}
              validate={validate}
            />
          );
        })}
      </ReactSortable>
      {canShowAddButton && (
        <AntButton
          type="link"
          className="!text-left"
          icon={<Icon icon="add" size="md" className="mr-2" />}
          onClick={() =>
            limitOptions && filteredOptions?.length
              ? addObject(filteredOptions[0])
              : canRemoveWhenUploadIsPdf
              ? addObject(defaultAttachedPictureDocument)
              : addObject()
          }
          block
        >
          {`${t(`${I18N_BASE_PATH}.components.addObject`, "Adicionar")} ${t(objectName, objectName) || ""}`}
        </AntButton>
      )}
    </div>
  );
};

export default ObjectListEditor;
