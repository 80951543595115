import { createContext, useContext, useState } from "react";

import defaultValues from "./defaultValues";

export const SidebarContext = createContext(defaultValues);
export const useSidebarContext = () => useContext(SidebarContext);

/* it controls the sidebar state that show the panes. */

export const SidebarProvider = ({ children }) => {
  const defaultActivePane = "1";
  const [activePane, setActivePane] = useState(defaultActivePane);

  return (
    <SidebarContext.Provider
      value={{
        activePane,
        setActivePane,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
