import { Icon } from "@combateafraude/react";
import { useFlowContext } from "@contexts/Flow";
import { useSidebarContext } from "@contexts/Sidebar";
import { Tabs, Tooltip } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import EditSidebar from "./EditSidebar";
import TabPaneAppearance from "./TabPanes/Appearance";
import TabPaneFlow from "./TabPanes/Flow";
import TabPaneSettings from "./TabPanes/Settings";

/** Pane initially selected */
const defaultActivePane = "1";

const I18N_BASE_PATH = "src.components.sidebar";

const TabTitle = ({ icon, isActive, hint }) => (
  <Tooltip title={hint} placement="right">
    <span>
      <Icon className={`${isActive ? "text-caf-primary" : ""}`} icon={icon} />
    </span>
  </Tooltip>
);

const Sidebar = () => {
  const { t } = useTranslation();
  const { setIsFlowBuilder } = useFlowContext();
  const { activePane, setActivePane } = useSidebarContext();

  useEffect(() => {
    if (["0", "1"].includes(activePane)) {
      setIsFlowBuilder(false);
    } else if (activePane === "2") {
      setIsFlowBuilder(true);
    }
  }, [activePane]);

  return (
    <div
      id="sidebar"
      className="fixed top-0 z-20 flex flex-col justify-between h-full px-2 py-4 bg-white shadow-xl w-96"
    >
      <EditSidebar />
      <div className="flex flex-col flex-1 overflow-y-auto">
        <Tabs
          defaultActiveKey={defaultActivePane}
          activeKey={activePane}
          centered
          onChange={setActivePane}
          tabPosition="left"
          animated={{ inkBar: true, tabPane: false }}
          tabBarStyle={{ padding: "0px" }}
        >
          <Tabs.TabPane
            tab={
              <TabTitle
                icon="image"
                isActive={activePane === "1"}
                hint={t(`${I18N_BASE_PATH}.components.tabs.appearance.hint`)}
              />
            }
            key="1"
          >
            <TabPaneAppearance />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <TabTitle
                icon="flow"
                isActive={activePane === "2"}
                hint={t(`${I18N_BASE_PATH}.components.tabs.flow.hint`)}
              />
            }
            key="2"
          >
            <TabPaneFlow />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <TabTitle
                icon="settings"
                isActive={activePane === "3"}
                hint={t(`${I18N_BASE_PATH}.components.tabs.settings.hint`)}
              />
            }
            key="3"
          >
            <TabPaneSettings />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Sidebar;
