import { useLoader } from "@contexts/Loader";
import api from "@services/api";
import { getClientFlags, getLoggedUser } from "@utils/auth/authentication";
import { authenticationUtils } from "@utils/auth/utils";
import { Logger } from "@utils/logging";
import Cookies from "js-cookie";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import i18n from "../../i18n";
import defaultValues from "./defaultValues";

const isLocalhost = !!window.location.origin.startsWith("http://localhost");

const LANGUAGE = isLocalhost ? "CAF_language" : "__Secure-CAF_language";

const languageSelected =
  Cookies.get(LANGUAGE) !== undefined && Cookies.get(LANGUAGE) !== "undefined"
    ? Cookies.get(LANGUAGE)
    : window.navigator.language;

const { AUTHENTICATION_COOKIES_OPTIONS } = authenticationUtils;

export const APIContext = createContext(defaultValues);
export const useAPIContext = () => useContext(APIContext);

export const APIProvider = ({ children }) => {
  const [startLoader, stopLoader, isLoaderLoading] = useLoader();

  const [userId, setUserId] = useState();
  const [userEmail, setUserEmail] = useState();
  const [tenantId, setTenantId] = useState(defaultValues.tenantId);
  const [templateId, setTemplateId] = useState(defaultValues.templateId);
  const [language, setLanguage] = useState(defaultValues.language);
  const [isCafUser, setIsCafUser] = useState(defaultValues.isCafUser);
  const [featureFlags, setFeatureFlags] = useState({});
  const [isFetching, setIsFetching] = useState(defaultValues.isFetching);

  const history = useHistory();

  const hasWhatsappOnboarding = useMemo(() => featureFlags?.onboardingChatbot, [featureFlags]);

  useEffect(() => {
    const authToken = Cookies.get(AUTHENTICATION_COOKIES_OPTIONS.AUTH_ID_TOKEN);
    const bifrostToken = Cookies.get(AUTHENTICATION_COOKIES_OPTIONS.BIFROST_ID_TOKEN);

    if (!!authToken && !bifrostToken) {
      startLoader("");
      getLoggedUser({
        url: `${process.env.REACT_APP_BASE_URL_AUTH_API}users/me`,
        token: `Bearer ${authToken}`,
        history,
      }).then((user) => {
        const { language, username } = user || {};
        const isCafUser = ["@caf.io", "@combateafraude.com"].some((domain) => username?.includes(domain));

        setIsCafUser(isCafUser);
        setFeatureFlags(user?.accountData?.featureFlags);
        setLanguage(language || languageSelected);
      });
    } else {
      if (tenantId) {
        getLoggedUser({
          url: `${process.env.REACT_APP_BASE_URL_BACKOFFICE_API}users/me`,
          token: bifrostToken,
          history,
        }).then((user) => {
          setUserId(undefined);
          setLanguage(user?.language || languageSelected);
          setUserEmail(user?.username);
        });
        getClientFlags({
          url: `${process.env.REACT_APP_BASE_URL_BACKOFFICE_API}clients/${tenantId}/feature-flags`,
          token: bifrostToken,
          history,
        }).then((flags) => {
          setFeatureFlags(flags);
        });
        setIsCafUser(true);
      }
    }
  }, [tenantId]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    i18n.on("languageChanged", () => {
      if (!!language) {
        stopLoader();
      }
    });
  }, []);

  /** Returns a list of templates */
  const getTemplates = async () => {
    if (!tenantId) return;

    try {
      setIsFetching(true);

      return api
        .get(`/onboarding-builder/templates?tenantId=${tenantId}`)
        .then((response) => {
          Logger.console("Templates from tenantId ", tenantId, response.data?.data);
          setIsFetching(false);
          return response.data?.data;
        })
        .catch((error) => {
          Logger.error(error, {
            message: "Couldn't get templates (inside).",
          });
          setIsFetching(false);
          return null;
        });
    } catch (error) {
      Logger.error(error, {
        message: "Couldn't get templates (outside).",
      });
      setIsFetching(false);
      return null;
    }
  };

  /** Creates a new template */
  const createTemplate = async (template) => {
    if (!tenantId && !template) return;

    try {
      setIsFetching(true);

      return api
        .post(`/onboarding-builder/templates?tenantId=${tenantId}`, template)
        .then((response) => {
          Logger.console("Response from createTemplate ", tenantId, response);
          setIsFetching(false);
          return true;
        })
        .catch((error) => {
          Logger.error(error, {
            message: "Couldn't create template (inside).",
          });
          setIsFetching(false);
          return null;
        });
    } catch (error) {
      Logger.error(error, {
        message: "Couldn't create template (outside).",
      });
      setIsFetching(false);
      return null;
    }
  };

  /** Duplicates a template */
  const duplicateTemplate = async (templateIdToDuplicate = templateId) => {
    if (!tenantId || !templateIdToDuplicate) return;

    try {
      setIsFetching(true);

      return api
        .post(`/onboarding-builder/templates/${templateIdToDuplicate}?tenantId=${tenantId}`)
        .then((response) => {
          Logger.console("Response from duplicateTemplate ", templateIdToDuplicate, response);
          setIsFetching(false);
          return true;
        })
        .catch((error) => {
          Logger.error(error, {
            message: "Couldn't duplicate template (inside).",
          });
          setIsFetching(false);
          return null;
        });
    } catch (error) {
      Logger.error(error, {
        message: "Couldn't duplicate template (outside).",
      });
      setIsFetching(false);
      return null;
    }
  };

  /** Deletes a template */
  const deleteTemplate = async (templateIdToDelete = templateId) => {
    if (!tenantId || !templateIdToDelete) return;

    try {
      setIsFetching(true);

      return api
        .delete(`/onboarding-builder/templates/${templateIdToDelete}?tenantId=${tenantId}`)
        .then((response) => {
          Logger.console("Response from deleteTemplate ", templateIdToDelete, response);
          setIsFetching(false);
          return true;
        })
        .catch((error) => {
          Logger.error(error, {
            message: "Couldn't delete template (inside).",
          });
          setIsFetching(false);
          return null;
        });
    } catch (error) {
      Logger.error(error, {
        message: "Couldn't delete template (outside).",
      });
      setIsFetching(false);
      return null;
    }
  };

  /** Updates a template */
  const updateTemplate = async (template, templateIdToUpdate = templateId) => {
    if (!tenantId || !templateIdToUpdate || !template) return;

    try {
      setIsFetching(true);

      return api
        .patch(`/onboarding-builder/templates/${templateIdToUpdate}?tenantId=${tenantId}`, template)
        .then((response) => {
          Logger.console("Response from updateTemplate ", templateIdToUpdate, response);
          setIsFetching(false);
          return true;
        })
        .catch((error) => {
          Logger.error(error, {
            message: "Couldn't update template (inside).",
          });
          setIsFetching(false);
          return null;
        });
    } catch (error) {
      Logger.error(error, {
        message: "Couldn't update template (outside).",
      });
      setIsFetching(false);
      return null;
    }
  };

  /** Returns a specific template */
  const getTemplate = async (templateIdToGet = templateId) => {
    if (!tenantId || !templateIdToGet) return;

    try {
      setIsFetching(true);

      return api
        .get(`/onboarding-builder/templates/${templateIdToGet}?tenantId=${tenantId}`)
        .then((response) => {
          Logger.console("Response from getTemplate ", tenantId, templateIdToGet, response);
          setIsFetching(false);
          return response.data?.template;
        })
        .catch((error) => {
          Logger.error(error, {
            message: "Couldn't get template (inside).",
          });
          setIsFetching(false);
          return null;
        });
    } catch (error) {
      Logger.error(error, {
        message: "Couldn't get template (outside).",
      });
      setIsFetching(false);
      return null;
    }
  };

  return (
    <APIContext.Provider
      value={{
        userId,
        userEmail,
        isFetching,
        templateId,
        setTemplateId,
        tenantId,
        setTenantId,
        language,
        setLanguage,
        isCafUser,
        featureFlags,
        hasWhatsappOnboarding,
        getTemplates,
        createTemplate,
        duplicateTemplate,
        deleteTemplate,
        updateTemplate,
        getTemplate,
      }}
    >
      {children}
    </APIContext.Provider>
  );
};
