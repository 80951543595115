import { useAuth } from "@hooks/useAuth";
import api from "@services/api";
import { authenticationUtils } from "@utils/auth/utils";
import { message } from "antd";
import { useTranslation } from "react-i18next";

const { formatTokenBasedOnPlatform, validateToken } = authenticationUtils;

const HttpInterceptor = () => {
  const { t } = useTranslation();

  const { refreshUserToken } = useAuth();

  api.interceptors.request.use((req) => {
    if (!req.url?.includes("/auth/login") && !req.headers["Authorization"]) {
      const { token } = validateToken();

      if (!!token) {
        req.headers["Authorization"] = token;
      }
    }

    if (!req.url?.includes("/auth/login") && !req.headers["Content-Type"]) {
      req.headers["Content-Type"] = "application/json";
    }

    return req;
  });

  api.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (
        err?.response?.config?.url === `${process.env.REACT_APP_BASE_URL_BACKOFFICE_API}users/refresh` ||
        err?.response?.config?.url ===
          `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API_V1}onboarding-builder/bifrost/refresh`
      ) {
        return null;
      }

      if (!!err?.response) {
        const isAuthenticationError =
          err?.response?.status === 401 ||
          (err?.response?.status === 400 && err?.response?.data?.message?.includes("Invalid token provided"));

        if (isAuthenticationError) {
          try {
            const { platform } = validateToken();
            const res = await refreshUserToken();

            const { idToken: bifrostToken, access_token: authToken } =
              platform === "bifrost" ? res?.response : res || {};
            const token = bifrostToken || authToken;

            if (!platform || !token) {
              throw Error("Platform or token invalid");
            }

            err.config.headers.Authorization = formatTokenBasedOnPlatform(token, platform);
            return api.request(err.config);
          } catch (err) {
            message.error(t("general.message.error.sessionExpired", "Sessão expirada, faça login novamente"));

            setTimeout(() => {
              if (!err?.response?.config?.url?.includes("/auth/login")) {
                window.location = "/auth/login";
              }
              return null;
            }, 450);
          }
        }
      }

      return Promise.reject(err);
    },
  );

  return null;
};

export default HttpInterceptor;
