import ElementEditor from "@components/ElementEditor";
import { ColorInput, OnboardingInput, UploadInput } from "@components/Inputs";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import { Collapse, Form, Select } from "antd";
import get from "lodash.get";
import set from "lodash.set";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import PaneTitle from "../PaneTitle";

const { Panel } = Collapse;

const I18N_BASE_PATH = "src.components.sidebar";

const Appearance = () => {
  const { t } = useTranslation();
  const { onboardingOptions, setOnboardingOptions, isTemplateDefault } = useOnboardingOptionsContext();
  const [backgroundType, setBackgroundType] = useState("color");

  const defaultButtonPopupError = useMemo(() => {
    return get(onboardingOptions, "steps[3].components.nested[0].props.default.buttonBackgroundColor");
  }, [onboardingOptions]);

  useEffect(() => {
    if (onboardingOptions?.background?.length > 0) {
      if (onboardingOptions.background[0] === "#") setBackgroundType("color");
      else setBackgroundType("image");
    }
  }, [onboardingOptions]);

  const changeTemplateBackground = useCallback(
    (newColor) => {
      const newOptions = { ...onboardingOptions };

      newOptions.pageBackground = newColor;

      for (const key of Object.keys(newOptions.specialSteps)) {
        if (!newOptions.specialSteps[key]?.components || newOptions.specialSteps[key].components?.name !== "page")
          continue;

        set(newOptions, `specialSteps[${key}].components.styles.default.backgroundColor`, newColor);
      }

      for (const [i, value] of newOptions.steps.entries()) {
        if (!value?.components || value.components?.name !== "page") continue;

        set(newOptions, `steps[${i}].components.styles.default.backgroundColor`, newColor);
      }

      setOnboardingOptions(newOptions);
    },
    [onboardingOptions],
  );

  // Change the button color on error popup, we need to make the edition of errors dynamically to removed this in the future
  const changeErrorPopupButtonColor = useCallback(
    (newColor) => {
      const newOptions = { ...onboardingOptions };

      [3, 5, 9, 11].forEach((index) => {
        set(newOptions, `steps[${index}].components.nested[0].props.default.buttonBackgroundColor`, newColor);
      });

      setOnboardingOptions(newOptions);
    },
    [onboardingOptions],
  );

  return (
    <>
      <PaneTitle title={t(`${I18N_BASE_PATH}.components.tabs.appearance.title`, "Aparência")} />
      <Form layout="vertical">
        <ElementEditor />
        <Collapse
          defaultActiveKey={["0", "1", "2", "3", "4", "5", "6"]}
          expandIconPosition="right"
          ghost
          className="!bg-transparent !border-0"
        >
          <Panel
            className="text-xl font-bold !border-0"
            header={t(
              `${I18N_BASE_PATH}.components.tabs.appearance.form.progressBar.panelHeader`,
              "Barra de Progresso",
            )}
            key="0"
          >
            <Form.Item
              className="!mb-3"
              label={t(
                `${I18N_BASE_PATH}.components.tabs.appearance.form.removeProgressBar.label`,
                "Remover barra de progresso",
              )}
            >
              <OnboardingInput.Switch property="removeProgressBar" defaultValue={false} />
            </Form.Item>
          </Panel>
          <Panel
            className="text-xl font-bold !border-0"
            header={t(`${I18N_BASE_PATH}.components.tabs.appearance.form.fontName.panelHeader`, "Texto (fonte)")}
            key="1"
          >
            <Form.Item
              className="!mb-3"
              label={t(`${I18N_BASE_PATH}.components.tabs.appearance.form.fontName.label`, "Selecione sua fonte")}
              // tooltip={
              //   <span>
              //     {t(
              //       `${I18N_BASE_PATH}.components.tabs.appearance.form.fontName.onlyAvailableOn`,
              //       "Apenas fontes disponíveis no",
              //     )}{" "}
              //     <a className="text-caf-primary" href="https://fonts.google.com/" rel="noreferrer" target="_blank">
              //       Google Fonts
              //     </a>
              //   </span>
              // }
            >
              <OnboardingInput.Select
                property="font.name"
                labelKey="value"
                valueKey="value"
                options={[
                  "Lato",
                  "Merriweather",
                  "Montserrat",
                  "Noto Sans",
                  "Nunito Sans",
                  "Open Sans",
                  "Oswald",
                  "Poppins",
                  "PT Sans",
                  "Raleway",
                  "Roboto",
                  "Slabo",
                  "Ubuntu",
                  "Anton",
                  "BhuTuka",
                  "Open Sans Extrabold",
                  "Shadows Into Light",
                  "Timwe New Roman",
                  "Arial",
                  "Comics sans",
                  "Gabriela",
                  "Comfortaa",
                  "Comic Neue",
                  "Inter",
                  "Quicksand",
                  "Source Sans Pro",
                  "Times New Roman",
                  "Gotham",
                  "DM Sans",
                ]
                  .sort((a, b) => a.localeCompare(b))
                  .map((font) => ({
                    value: font,
                    style: { fontFamily: font },
                  }))}
              />
            </Form.Item>
          </Panel>
          {isTemplateDefault && (
            <>
              <Panel
                className="text-xl font-bold !border-0"
                header={t(`${I18N_BASE_PATH}.components.tabs.appearance.form.background.panelHeader`, "Fundo")}
                key="2"
              >
                <Form.Item
                  label={t(`${I18N_BASE_PATH}.components.tabs.appearance.form.background.label`, "Estilo")}
                  className="!mb-3"
                >
                  <Select value={backgroundType} onChange={(value) => setBackgroundType(value)}>
                    <Select.Option value="color">
                      {t(
                        `${I18N_BASE_PATH}.components.tabs.appearance.form.background.options.color.value`,
                        "Cor sólida",
                      )}
                    </Select.Option>
                    <Select.Option value="image">
                      {t(`${I18N_BASE_PATH}.components.tabs.appearance.form.background.options.image`, "Imagem")}
                    </Select.Option>
                  </Select>
                </Form.Item>
                {backgroundType === "color" ? (
                  <Form.Item
                    label={t(`${I18N_BASE_PATH}.components.tabs.appearance.form.background.options.color.label`, "Cor")}
                    className="!mb-3"
                  >
                    <OnboardingInput.Color property="background" defaultColor="#FFFFFF" />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label={t(`${I18N_BASE_PATH}.components.tabs.appearance.form.background.image`, "Imagem")}
                    className="!mb-3"
                  >
                    <OnboardingInput.Image property="background" />
                  </Form.Item>
                )}
                <Form.Item
                  label={t(
                    `${I18N_BASE_PATH}.components.tabs.appearance.form.background.options.color.backgroundSteps`,
                    "Cor do card",
                  )}
                  className="!mb-3"
                >
                  <OnboardingInput.Color property="backgroundSteps" defaultColor="#FFFFFF" />
                </Form.Item>
              </Panel>
              <Panel
                className="text-xl font-bold !border-0"
                header={t(`${I18N_BASE_PATH}.components.tabs.appearance.form.colors.label`, "Cores")}
                key="3"
              >
                <Form.Item
                  label={t(
                    `${I18N_BASE_PATH}.components.tabs.appearance.form.colors.options.primary.label`,
                    "Primária",
                  )}
                  className="!mb-3"
                  tooltip={t(
                    `${I18N_BASE_PATH}.components.tabs.appearance.form.colors.options.primary.tooltip`,
                    "Botões principais, loader, e elementos de formulário (inputs, checkboxes, radios...)",
                  )}
                >
                  <OnboardingInput.Color property="colors.primary" />
                </Form.Item>
                <Form.Item
                  label={t(
                    `${I18N_BASE_PATH}.components.tabs.appearance.form.colors.options.secondary.label`,
                    "Secundária",
                  )}
                  className="!mb-3"
                  tooltip={t(
                    `${I18N_BASE_PATH}.components.tabs.appearance.form.colors.options.secondary.tooltip`,
                    "Barra de progresso (se não definida individualmente), ícones das instruções, e links",
                  )}
                >
                  <OnboardingInput.Color property="colors.secondary" />
                </Form.Item>
                <Form.Item
                  label={t(`${I18N_BASE_PATH}.components.tabs.appearance.form.colors.options.headings`, "Títulos")}
                  className="!mb-3"
                >
                  <OnboardingInput.Color property="colors.headings" />
                </Form.Item>
                <Form.Item
                  label={t(
                    `${I18N_BASE_PATH}.components.tabs.appearance.form.colors.options.subHeadings`,
                    "Subtítulos",
                  )}
                  className="!mb-3"
                >
                  <OnboardingInput.Color property="colors.subHeadings" defaultColor="#1f2937" />
                </Form.Item>
                <Form.Item
                  label={t(
                    `${I18N_BASE_PATH}.components.tabs.appearance.form.colors.options.illustrations`,
                    "Ilustrações",
                  )}
                  className="!mb-3"
                >
                  <OnboardingInput.Color property="colors.illustrations" />
                </Form.Item>
              </Panel>
            </>
          )}
          {!isTemplateDefault && (
            <>
              <Panel
                className="text-xl font-bold !border-0"
                header={t(`${I18N_BASE_PATH}.components.tabs.appearance.form.background.panelHeader`, "Fundo")}
                key="4"
              >
                <Form.Item
                  label={t(
                    `${I18N_BASE_PATH}.components.tabs.appearance.form.background.options.color.value`,
                    "Cor sólida",
                  )}
                  className="!mb-3"
                >
                  <ColorInput hexColor={onboardingOptions?.pageBackground} onInputChange={changeTemplateBackground} />
                </Form.Item>
              </Panel>
              <Panel
                className="text-xl font-bold !border-0"
                header={t(`${I18N_BASE_PATH}.components.tabs.appearance.form.loadingPage.label`, "Tela de Loading")}
                key="5"
              >
                <Form.Item
                  label={t(`${I18N_BASE_PATH}.components.tabs.appearance.form.loadingPage.logo`, "Logo")}
                  className="!mb-3"
                >
                  <OnboardingInput.Image property="specialSteps.loading.components.nested[0].props.default.src" />
                </Form.Item>
                <Form.Item
                  label={t(`${I18N_BASE_PATH}.components.tabs.appearance.form.loadingPage.textColor`, "Cor do texto")}
                  className="!mb-3"
                >
                  <OnboardingInput.Color property="specialSteps.loading.components.styles.default.color" />
                </Form.Item>
              </Panel>
              <Panel
                className="text-xl font-bold !border-0"
                header={t(`${I18N_BASE_PATH}.components.tabs.appearance.form.other.label`, "Outros")}
                key="6"
              >
                <Form.Item
                  label={t(
                    `${I18N_BASE_PATH}.components.tabs.appearance.form.other.errorPopupButtonColor`,
                    "Cor botão popup de erro",
                  )}
                  className="!mb-3"
                >
                  <ColorInput hexColor={defaultButtonPopupError} onInputChange={changeErrorPopupButtonColor} />
                </Form.Item>
              </Panel>
            </>
          )}
        </Collapse>
      </Form>
    </>
  );
};

export default Appearance;
