import { Icon } from "@combateafraude/react";

export const tagsInfo = {
  PF: { icon: <Icon icon="person" size="sm" className="mr-1" />, color: "green" },
  PJ: { icon: <Icon icon="company" size="sm" className="mr-1" />, color: "blue" },
  DD: { icon: <Icon icon="onboarding" size="sm" className="mr-1" />, color: "magenta" },
  PFL: { icon: <Icon icon="face" size="sm" className="mr-1" />, color: "gold" },
  Form: { icon: <Icon icon="edit" size="sm" className="mr-1" />, color: "cyan" },
};

export const hasTag = (tagName = "", tags = []) => {
  return tags?.filter((tag) => tag.name === tagName).length > 0;
};
