import { Icon } from "@combateafraude/react";

const Square = ({
  icon,
  title,
  description,
  isSelected = false,
  disabled = false,
  onSelect,
  className,
  customization = {
    size: "52",
    title: {
      fontSize: "lg",
    },
    description: {
      fontSize: "sm",
    },
  },
}) => (
  <div
    className={`flex flex-col transition-all ease-in-out delay-75 items-center justify-center gap-3 w-${
      customization?.size
    } h-${customization?.size} rounded-lg border-solid cursor-pointer ${
      isSelected && !disabled
        ? "border-2 border-caf-primary bg-blue-50 shadow-xl cursor-default pointer-events-none"
        : !disabled && "border border-gray-200 bg-white hover:opacity-80 duration-200"
    } ${disabled && "cursor-not-allowed opacity-50 border border-gray-100"} ${className}`}
    onClick={disabled || isSelected ? null : onSelect}
  >
    <div
      className={`w-13 h-13 rounded-full flex items-center justify-center duration-200 transition-all ease-in-out delay-75 ${
        isSelected ? "bg-caf-primary" : "bg-gray-200"
      }`}
    >
      <Icon size="lg" className={isSelected ? "text-white" : "text-gray-400"} icon={icon} />
    </div>
    <div className="flex flex-col items-center justify-between gap-1 w-full">
      <h4
        className={`text-center !m-0 px-2 !py-0 break-normal text-${customization?.title?.fontSize} font-medium text-black`}
      >
        {title}
      </h4>
      {!!description && (
        <p
          className={`text-center !m-0 px-2 !py-0 break-normal text-${customization?.description?.fontSize} font-normal text-gray-500`}
        >
          {description}
        </p>
      )}
    </div>
  </div>
);

export default Square;
