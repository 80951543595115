import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import { useContext } from "react";
import { createContext, useState } from "react";

import defaultValues from "./defaultValues";

export const OnboardingOptionsFilesContext = createContext(defaultValues);
export const useOnboardingOptionsFilesContext = () => useContext(OnboardingOptionsFilesContext);

/** Stores files that are uploaded, so their names can be retreived */
export const OnboardingOptionsFilesProvider = ({ children }) => {
  const [files, setFiles] = useState({});

  const addFile = (file, property) => {
    if (file && property) {
      let _files = cloneDeep(files);
      set(_files, property, file.name);
      setFiles(_files);
    }
  };

  return (
    <OnboardingOptionsFilesContext.Provider
      value={{
        files,
        addFile,
      }}
    >
      {children}
    </OnboardingOptionsFilesContext.Provider>
  );
};
