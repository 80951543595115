import cloneDeepWith from "lodash.clonedeepwith";
import isEqual from "lodash.isequal";

import i18n from "../i18n";
import i18nEnUs from "../i18n/indexEnUS";
import i18nEsMX from "../i18n/indexEsMX";
import i18nPtBR from "../i18n/indexPtBR";

const checkAndReturnCurrentLanguage = (language) => {
  switch (language) {
    case "en-US":
      return i18nEnUs;

    case "es-MX":
      return i18nEsMX;

    case "pt-BR":
      return i18nPtBR;

    default:
      return i18n;
  }
};

export const saveLanguage = ({ builder = "", onboarding = "" }) => {
  localStorage.setItem("@builder:builderLanguage", builder);
};

export const getLanguageSaved = () => {
  const builderLanguage = localStorage.getItem("@builder:builderLanguage");

  return {
    builderLanguage,
  };
};

export const onboardingPropsParserTranslator = (valuesForTranslate = {}, language) => {
  if (!language) {
    return valuesForTranslate;
  }

  const i18nInstance = checkAndReturnCurrentLanguage(language);

  if (typeof valuesForTranslate === "string") {
    return i18nInstance.t(valuesForTranslate, valuesForTranslate);
  }

  if (typeof valuesForTranslate !== "object" || !valuesForTranslate) {
    return valuesForTranslate;
  }

  const keys = Object.keys(valuesForTranslate);
  const values = Object.values(valuesForTranslate);
  let valuesArrayTranslated = [];

  if (keys?.length > 0) {
    values?.forEach((value) => {
      let valueTranslated = cloneDeepWith(value, (v) => {
        if (typeof v === "string") {
          return i18nInstance.t(v, v);
        }
      });

      valuesArrayTranslated.push(isEqual(value, valueTranslated) ? value : valueTranslated);
    });

    const valuesObjectTranslated = valuesArrayTranslated?.reduce((acc, cur, index) => {
      acc[keys?.[index]] = cur;

      return acc;
    }, {});

    return valuesObjectTranslated || {};
  }

  return null;
};
