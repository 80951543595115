import { getMarkerEnd, getSmoothStepPath } from "react-flow-renderer";

const StepEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  arrowHeadType,
  markerEndId,
  selected,
}) => {
  const edgePath = getSmoothStepPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  return (
    <path
      id={id}
      style={{ stroke: selected ? "rgba(59, 130, 246)" : "var(--color-caf-primary)", strokeWidth: 3 }}
      className="react-flow__edge-path transition"
      d={edgePath}
      markerEnd={markerEnd}
      // https://github.com/wbkd/react-flow/issues/915#issuecomment-782581438
      // markerEnd={
      //   <svg>
      //     <defs>
      //       <marker
      //         className={"react-flow__arrowhead"}
      //         id={id}
      //         markerWidth="15"
      //         markerHeight="15"
      //         viewBox="-10 -10 20 20"
      //         orient="auto"
      //         markerUnits="userSpaceOnUse"
      //         refX="0"
      //         refY="0"
      //       >
      //         <polyline
      //           stroke={"var(--color-caf-primary)"}
      //           strokeLinecap="round"
      //           strokeLinejoin="round"
      //           strokeWidth="1"
      //           fill={"var(--color-caf-primary)"}
      //           points="-12,-6 0,0 -12,6 -12,-6"
      //         />
      //       </marker>
      //     </defs>
      //   </svg>
      // }
    />
  );
};

export default StepEdge;
