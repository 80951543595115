import Loader from "@components/Loader";
import { Logger } from "@utils/logging";
import React, { createContext, useCallback, useContext, useRef, useState } from "react";

import defaultValues from "./defaultValues";

export const LoaderContext = createContext(defaultValues);

export const useLoaderContext = () => useContext(LoaderContext);

export const LoaderProvider = ({ children }) => {
  const [isHidden, setIsHidden] = useState(true);
  const [isLoading, setIsLoading] = useState(defaultValues.isLoading);
  const [text, setText] = useState();

  /** Will cancel a delayed stop if `start` is called in between */
  const isStopping = useRef(false);

  /** Starts the loader */
  const start = useCallback(
    (_text = "") => {
      Logger.console("Starting loader:", _text || "no text");
      isStopping.current = false;
      setText(_text);
      setIsLoading(true);
      setIsHidden(false);
      return true;
    },
    [setText, setIsLoading, setIsHidden],
  );

  /** Stops the loader */
  const stop = useCallback(
    (delay = 0) => {
      isStopping.current = true;
      setTimeout(() => {
        if (isStopping.current) {
          Logger.console("Stopping loader.");
          setIsHidden(true);
          // Animation delay
          setTimeout(() => {
            if (isStopping.current) setIsLoading(false);
            isStopping.current = false;
          }, 300);
          return true;
        } else {
          Logger.console("Loader stop was canceled.");
        }
      }, delay);
    },
    [setIsLoading, setIsHidden],
  );

  return (
    <LoaderContext.Provider
      value={{
        start,
        stop,
        isLoading,
      }}
    >
      {isLoading && <Loader isHidden={isHidden} text={text} />}
      {children}
    </LoaderContext.Provider>
  );
};
