import i18n from "../i18n";
var beautify = require("js-beautify").html;

let labelsTranslated = {};

i18n.on("languageChanged", () => {
  labelsTranslated.and = i18n.t("general.label.and", "e");
});

/** Prettifies HTML code, adding correct indentation */
export const beautifyHTML = (code) => {
  return beautify(code, {
    indent_size: "2",
    indent_char: " ",
    max_preserve_newlines: "-1",
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: "normal",
    brace_style: "collapse",
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: false,
    end_with_newline: false,
    wrap_line_length: "0",
    indent_inner_html: false,
    comma_first: false,
    e4x: false,
    indent_empty_lines: false,
  });
};

/** Converts string to Title Case */
export function toTitleCase(str = "") {
  return str?.replace(/\w\S*/g, function (txt) {
    return txt?.charAt(0)?.toUpperCase() + txt?.substr(1).toLowerCase();
  });
}

export const truncate = (input, length) => (input?.length > length ? `${input?.substring(0, +length)}...` : input);

// format lists that has multiple items, example: "item 1, item 2 e item4"
export const formatMultipleListItems = (arr = []) => {
  if (!!arr?.length && !!arr?.every((item) => typeof item === "string")) {
    return `${arr?.slice(0, -1)?.join(", ")} ${labelsTranslated?.and || "e"} ${arr?.slice(-1)}`;
  } else {
    return arr;
  }
};
