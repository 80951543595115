import { Form, Input, Switch } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const I18N_BASE_PATH = "src.components.inputs.requiredMaxLengthInput";

const RequiredMaxLengthInput = ({ value = 0, onChange, inputTypeSelected, ...rest }) => {
  const { t } = useTranslation();

  const INPUT_TYPE_IS_PHONE = "phone";
  const SHOULD_BE_GREATER_OR_EQUALS_THAN = 0;
  const MESSAGE_INFORMATION = t(`${I18N_BASE_PATH}.constants.messageInformation`, "Quantidade de caracteres");

  const [isChecked, setIsChecked] = useState(false);
  const [inputSelectedIsPhone, setInputSelectedIsPhone] = useState(inputTypeSelected === INPUT_TYPE_IS_PHONE);

  const handleChangeSwitch = () => setIsChecked((previousElement) => !previousElement);

  const handleChangeInput = (event) => {
    const { value } = event.target;

    if (value >= SHOULD_BE_GREATER_OR_EQUALS_THAN) onChange(Number(value));
  };

  useEffect(() => {
    setInputSelectedIsPhone(inputTypeSelected === INPUT_TYPE_IS_PHONE);
  }, [inputTypeSelected]);

  useEffect(() => {
    if (inputSelectedIsPhone) {
      setIsChecked(false);
    }
  }, [inputSelectedIsPhone]);

  useEffect(() => {
    if (!isChecked) onChange(undefined);
  }, [isChecked]);

  useEffect(() => {
    setIsChecked(!!value && value >= SHOULD_BE_GREATER_OR_EQUALS_THAN);
  }, [value]);

  return (
    !inputSelectedIsPhone && (
      <Form.Item
        label={t(`${I18N_BASE_PATH}.components.form.label`, "Limite de caracteres")}
        tooltip={t(
          `${I18N_BASE_PATH}.components.form.tooltip`,
          "Adicionar quantidade máxima de caracteres que podem ser inseridos pelo usuário no campo",
        )}
      >
        <Switch onChange={handleChangeSwitch} checked={isChecked} />
        {isChecked && (
          <div className="pt-3">
            <small>{MESSAGE_INFORMATION}</small>
            <Input type="number" value={value} onChange={handleChangeInput} {...rest} />
          </div>
        )}
      </Form.Item>
    )
  );
};

export default RequiredMaxLengthInput;
