import { Icon } from "@combateafraude/react";
import { useSavedColorsContext } from "@contexts/SavedColors";
import useTimeout from "@hooks/useTimeout";
import { Input, Popover } from "antd";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { useTranslation } from "react-i18next";

const I18N_BASE_PATH = "src.components.inputs.colorInput";

const ColorInput = ({ hexColor, onInputChange, defaultColor, ...rest }) => {
  const { t } = useTranslation();

  const { savedColors, saveColor, unsaveColor } = useSavedColorsContext();

  const initialColor = useMemo(() => hexColor ?? defaultColor, [hexColor, defaultColor]);
  const [color, setColor] = useState(initialColor);
  const { callbackOnTimeout } = useTimeout();

  useEffect(() => {
    callbackOnTimeout(() => onInputChange(color), 300);
  }, [color]);

  return (
    <Popover
      trigger="hover"
      content={() => (
        <>
          <HexColorPicker color={initialColor} onChange={setColor} />
          <div className="flex flex-wrap mt-3 gap-1 w-52">
            {savedColors.map((color, i) => (
              <span
                key={`${color.title}_${i}`}
                className={classNames("relative block w-6 h-6 rounded-full transition-all cursor-pointer transform")}
                title={color.title}
                style={{ background: color.hex }}
                onClick={() => onInputChange(color.hex)}
              >
                <span
                  className="absolute w-6 h-6 -top-1 -right-1 flex items-center justify-center rounded-full bg-red-500 text-white transform scale-50 origin-top-right"
                  onClick={(e) => {
                    e.stopPropagation();
                    unsaveColor(color.hex);
                    return false;
                  }}
                >
                  <Icon icon="close" size="sm" />
                </span>
              </span>
            ))}
            {savedColors?.length < 7 && (
              <span
                className={"block w-6 h-6 rounded-full transition-all cursor-pointer"}
                title={t(`${I18N_BASE_PATH}.components.saveColor`, "Salvar cor")}
                style={{ color: `${initialColor}` }}
                onClick={() => saveColor(initialColor)}
              >
                <Icon icon="add" size="md" />
              </span>
            )}
          </div>
        </>
      )}
    >
      <Input
        value={initialColor?.length > 0 && initialColor[0] === "#" ? initialColor : "#"}
        onChange={(e) => onInputChange(e.target.value)}
        prefix={
          <span className="block w-4 h-4 rounded-full transition-all" style={{ background: initialColor || "#000" }} />
        }
        {...rest}
      />
    </Popover>
  );
};
export default ColorInput;
