import Onboarding from "@package/steps";
import { parseStep } from "@utils/onboarding";
import templateValidations from "@utils/template-validations";
import {
  templateValidationsMessages,
  validateTemplateMessages,
} from "@utils/template-validations/templateValidationsMessages";
import isEqual from "lodash.isequal";

const { steps } = Onboarding;

// Check for identical "OTHER" fields title at UPLOAD_DOCUMENTS step
export const checkIdenticalOTHERFieldsTitleAtUploadDocumentsStep = (
  onboardingSteps = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  let hasDuplicateOTHERFieldTitle;

  if (!onboardingSteps?.length) {
    return;
  }

  onboardingSteps?.forEach((step) => {
    // filtering for only UPLOAD_DOCUMENTS step
    if (isEqual(parseStep(step.name)[0], steps.UPLOAD_DOCUMENTS.name)) {
      // merging all OTHER fields title into just one array
      const otherFields = step?.fields?.filter((field) => isEqual(field?.type, "OTHER"));

      if (otherFields?.length > 0) {
        hasDuplicateOTHERFieldTitle = templateValidations.GENERAL_TEMPLATE_VALIDATIONS.listContainsDuplicatedValues(
          otherFields?.map((field) => field?.title),
        );
      }

      // if the template has duplicate OTHER fields title, add a new validation notification
      if (hasDuplicateOTHERFieldTitle) {
        templateValidationNotificationsToAdd.push(
          validateTemplateMessages(templateValidationsMessages.UPLOAD_DOCUMENTS_OTHER_FIELDS_TITLE_DUPLICATED),
        );
      }
    }
  });
};
