import FloatingMenu, { FloatingMenuButton } from "@components/FloatingMenu";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import { useOnboardingOptionsFilesContext } from "@contexts/OnboardingOptionsFiles";
import { requestMethods } from "@hooks/useAPI";
import useAPICustom from "@hooks/useAPICustom";
import { truncate } from "@utils/formatting";
import { Logger } from "@utils/logging";
import { message, Upload } from "antd";
import get from "lodash.get";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const I18N_BASE_PATH = "src.components.inputs.uploadInput";

/** Input to upload files */
const UploadInput = ({ value, stepData, defaultProperty, property, onInputChange, fileType = "image" }) => {
  const { t } = useTranslation();

  const [uploadRequest, uploadLoading] = useAPICustom();
  const { files, addFile } = useOnboardingOptionsFilesContext();
  const { initialOnboardingOptions, isTemplateDefault } = useOnboardingOptionsContext();

  const [lastImageSourceSaved, setLastImageSourceSaved] = useState(value || null);
  const [fileInputDescription, setFileInputDescription] = useState("");
  const [showDefaultFileInputDescription, setShowDefaultFileInputDescription] = useState(false);
  const [canResetImage, setCanResetImage] = useState(false);

  useEffect(() => {
    // If the illustration property is within a step
    if (property && !!stepData) {
      setLastImageSourceSaved(get(stepData, property) || get(stepData, defaultProperty));
    } else if (!!initialOnboardingOptions) {
      // If the illustration property is out of a step, for example: "footer.background"
      setLastImageSourceSaved(get(initialOnboardingOptions, property));
    }
  }, [stepData, property, defaultProperty, initialOnboardingOptions]);

  useEffect(() => {
    if ((!!lastImageSourceSaved && !value) || (!!value && !!lastImageSourceSaved && value !== lastImageSourceSaved)) {
      return setCanResetImage(true);
    }

    setCanResetImage(false);
  }, [lastImageSourceSaved, value]);

  useEffect(() => {
    if (showDefaultFileInputDescription && !uploadLoading) {
      return setFileInputDescription(t(`${I18N_BASE_PATH}.states.fileInputDescription.edit`, "Edite clicando ao lado"));
    }

    if (uploadLoading) {
      return setFileInputDescription(t("general.label.uploading", "Fazendo upload..."));
    }

    if (!!value && !value?.startsWith("illustration_") && !!get(files, property)) {
      return setFileInputDescription(get(files, property));
    }

    setFileInputDescription(t(`${I18N_BASE_PATH}.states.fileInputDescription.edit`, "Edite clicando ao lado"));
  }, [uploadLoading, value, files]);

  const beforeUpload = useCallback(
    async (file) => {
      setShowDefaultFileInputDescription(false);

      if (!uploadLoading) {
        if (!file.type.includes(fileType)) {
          Logger.console(`${file.name} is not a ${fileType} file: ${file.type}`);
          message.error(t(`${I18N_BASE_PATH}.states.fileInputDescription.invalidFileType`, "Tipo de arquivo inválido"));

          return false;
        }

        if (file.type === "image/svg+xml" || file.type === "text/plain") {
          const readFileContent = async (file) => {
            const reader = new FileReader();

            return new Promise((resolve, reject) => {
              reader.onload = (event) => {
                const svgString = event.target.result;
                resolve(svgString);
              };
              reader.onerror = (error) => {
                reject(error);
              };
              reader.readAsText(file);
            });
          };

          const checkForMaliciousContent = (svgString) => {
            const maliciousPattern =
              /<(?:script|iframe|embed|object|applet|function|base|meta|link|blink|on\w*|javascript:)\b[^>]*>|href=["']?(?:[^"'>]|'[^']*'|"[^"]*")*(?:&#\d+;|javascript:)/gi;
            return maliciousPattern.test(svgString);
          };

          try {
            const svgString = await readFileContent(file);

            if (checkForMaliciousContent(svgString)) {
              message.error(
                t(`${I18N_BASE_PATH}.states.fileInputDescription.maliciousContent`, "Arquivo com conteúdo malicioso"),
              );
              return false;
            }
          } catch (error) {
            console.error("Erro ao ler o arquivo", error);
            return false;
          }
        }

        let res = await uploadRequest(
          `${process.env.REACT_APP_BASE_URL_COMBATEAFRAUDE_API_V1}upload?nocache=${Date.now()}`,
        );

        if (res && res.getUrl && res.uploadUrl) {
          await uploadRequest(res.uploadUrl, requestMethods.PUT, file, {
            headers: {
              "Content-Type": file.type,
            },
          });

          onInputChange(res.getUrl);
          addFile(file, property);
        }
      }

      return false;
    },
    [
      uploadLoading,
      fileType,
      Logger,
      message,
      Upload,
      uploadRequest,
      requestMethods,
      onInputChange,
      setShowDefaultFileInputDescription,
      addFile,
      t,
    ],
  );

  const resetImage = useCallback(() => {
    // If the illustration property is out of a step, for example: "footer.logo", get from stepData, otherwise, get from initialOnboardingOptions
    onInputChange(get(stepData, property) || get(stepData, defaultProperty) || get(initialOnboardingOptions, property));

    setShowDefaultFileInputDescription(true);
  }, [stepData, property, defaultProperty, initialOnboardingOptions]);

  const removeFile = useCallback(() => onInputChange(""), [onInputChange]);

  return (
    <div className="flex w-full flex-col items-stretch relative">
      <div className="flex space-x-2 items-center">
        <div className="flex-1 border border-gray-300 p-3 rounded-md font-normal flex items-center">
          <div className="flex flex-col">
            <span>{truncate(fileInputDescription, 35)}</span>
          </div>
        </div>
        <FloatingMenu>
          {(hide) => (
            <>
              {canResetImage && (
                <>
                  <FloatingMenuButton icon="rotate-ccw" onClick={resetImage}>
                    {t(`${I18N_BASE_PATH}.components.floatingMenu.resetButton`, "Resetar")}
                  </FloatingMenuButton>
                  <hr />
                </>
              )}
              <Upload maxCount={1} showUploadList={false} beforeUpload={beforeUpload} disabled={uploadLoading}>
                <FloatingMenuButton icon="upload">
                  {t(`${I18N_BASE_PATH}.components.floatingMenu.upload`, "Upload")}
                </FloatingMenuButton>
              </Upload>
              {!!value && !!isTemplateDefault && (
                <>
                  <hr />
                  <FloatingMenuButton
                    icon="trash"
                    danger
                    onClick={() => {
                      removeFile();
                      hide();
                    }}
                  >
                    {t("general.label.remove", "Excluir")}
                  </FloatingMenuButton>
                </>
              )}
            </>
          )}
        </FloatingMenu>
      </div>
    </div>
  );
};
export default UploadInput;
