import { useRef, useState } from "react";

/** Utility timeouts */
const useTimeout = () => {
  const [lastUpdate, setLastUpdate] = useState(performance.now());
  // https://github.com/facebook/react/issues/14010#issuecomment-433788147
  const lastUpdateRef = useRef(lastUpdate);
  lastUpdateRef.current = lastUpdate;

  /** Calls a function after a timeout if it was not called again in the mean time */
  const callbackOnTimeout = (callback, timeout) => {
    let timeNow = performance.now();
    setLastUpdate(timeNow);
    setTimeout(() => timeNow === lastUpdateRef.current && callback(), timeout);
  };

  return { callbackOnTimeout };
};

export default useTimeout;
