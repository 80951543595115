import { useAPIContext } from "@contexts/API";
import { useAuth } from "@hooks/useAuth";
import { getQueryVariable } from "@utils/";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";

import Builder, { BuilderProviders } from "./Builder";
import TemplateList, { TemplateListProviders } from "./TemplateList";

const Router = () => {
  const { templateId, setTemplateId, tenantId, setTenantId } = useAPIContext();
  const { isUserAuthenticated } = useAuth();

  useEffect(() => {
    let [_templateId, _tenantId] = ["templateId", "tenantId"].map((param) => getQueryVariable(param));
    setTemplateId(_templateId);
    setTenantId(_tenantId);
  }, []);

  return (
    <>
      {!isUserAuthenticated() ? (
        <Redirect exact from="/email" to="/auth/login" />
      ) : !templateId && tenantId ? (
        <TemplateListProviders>
          <TemplateList tenantId={tenantId} />
        </TemplateListProviders>
      ) : (
        <BuilderProviders>
          <Builder tenantId={tenantId} templateId={templateId} />
        </BuilderProviders>
      )}
    </>
  );
};

export default Router;
