import { Icon } from "@combateafraude/react";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import { onboardingPropsParserTranslator } from "@utils/translator";
import { Button as AntButton } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";

import ObjectPreview from "./ObjectPreview";

const I18N_BASE_PATH = "src.components.objectListEditor.objectListEditorByDefaultFields";

const ObjectListEditorByDefaultFields = ({
  property,
  stepIndex,
  inputValue,
  onInputChange,
  fields,
  objectName = "",
  defaultFields,
  defaultDocuments,
  ...rest
}) => {
  const { t } = useTranslation();

  const { onboardingLanguage } = useOnboardingOptionsContext();

  const valueMustHaveMorePropertiesThan = 1;
  const documentThatCanBeRemoved = ["picture"];
  const defaultNewDocumentToAdd = {
    id: "picture",
    icon: "faceID",
    type: "PICTURE",
    title: `${I18N_BASE_PATH}.constants.defaultDocumentsToAdd.picture.title`,
    // => Tirar foto
    description: `${I18N_BASE_PATH}.constants.defaultDocumentsToAdd.picture.description`,
    // => Tire uma foto do seu documento físico
  };

  const [isFirst, setIsFirst] = useState(true);
  const [inputValueUpdated, setInputValueUpdated] = useState();
  const [lastAddedObjectIndex, setLastAddedObjectIndex] = useState();

  const canShowAddButton = useMemo(
    () => !defaultFields && defaultDocuments && inputValue?.length === 1,
    [defaultFields, defaultDocuments, inputValue],
  );

  const removeAttributeFromSortable = useCallback((value) => {
    delete value["chosen"];
    delete value["selected"];

    return value;
  }, []);

  const removeObject = useCallback(
    (index) => {
      onInputChange([...inputValue.slice(0, index), ...inputValue.slice(index + 1, inputValue.length)]);
    },
    [inputValue, onInputChange],
  );

  const addObject = useCallback(
    (newDocument = defaultNewDocumentToAdd) => {
      const newDocumentTranslated = {
        ...newDocument,
        title: onboardingPropsParserTranslator(newDocument?.title, onboardingLanguage),
        description: onboardingPropsParserTranslator(newDocument?.description, onboardingLanguage),
      };

      setLastAddedObjectIndex(inputValue?.length || 0);
      onInputChange([...(inputValue || []), newDocumentTranslated]);
    },
    [inputValue, onInputChange, t],
  );

  const handleUpdateOnboardingOptionsOnSort = useCallback(
    (values) => {
      const valuesFormatted = values?.map((value) => removeAttributeFromSortable(value));
      onInputChange(valuesFormatted);
    },
    [onInputChange],
  );

  const valuesSavedFromTemplate = useMemo(
    () => inputValue?.filter((value) => Object.keys(value).length > valueMustHaveMorePropertiesThan) ?? [],
    [inputValue],
  );

  const checkIfElementKeyIsEquals = useCallback(
    (value, valueToCompare, key) => value?.[key] === valueToCompare?.[key],
    [],
  );

  const buildObjects = {
    buildDocumentObject: (editedObject, baseObject) => {
      const document = {
        id: baseObject.id,
        icon: editedObject?.icon ?? baseObject.icon,
        title: editedObject?.title ?? baseObject.title,
        description: editedObject?.description ?? baseObject.description,
      };
      return document;
    },
    buildFieldObject: (editedObject, baseObject) => {
      const field = {
        field: editedObject.field,
        icon: editedObject?.icon ?? baseObject.icon,
        label: editedObject?.label ?? baseObject.label,
        placeholder: editedObject?.placeholder ?? baseObject.placeholder,
      };
      return field;
    },
  };

  const mappedElementsToField = () =>
    defaultFields?.map((defaultField) => {
      const fieldFound = valuesSavedFromTemplate.find((value) =>
        checkIfElementKeyIsEquals(value, defaultField, "field"),
      );

      if (fieldFound) return buildObjects.buildFieldObject(fieldFound, defaultField);

      return defaultField;
    });

  const mappedElementsToDocument = () =>
    defaultDocuments?.map((defaultDocument) => {
      const documentFound = valuesSavedFromTemplate.find((value) =>
        checkIfElementKeyIsEquals(value, defaultDocument, "id"),
      );

      if (documentFound) return buildObjects.buildDocumentObject(documentFound, defaultDocument);
      return defaultDocument;
    });

  const mappedElements = useMemo(
    () => (defaultFields ? mappedElementsToField(defaultFields) : mappedElementsToDocument(defaultDocuments)),
    [defaultFields, defaultDocuments, valuesSavedFromTemplate],
  );

  useEffect(() => {
    if (inputValue) {
      setInputValueUpdated(mappedElements);
    } else {
      if (isFirst) onInputChange(defaultFields ?? defaultDocuments);
    }
  }, [inputValue]);

  useEffect(() => {
    if (inputValueUpdated && isFirst) {
      onInputChange(inputValueUpdated);
      setIsFirst(false);
    }
  }, [inputValueUpdated]);

  return (
    <div className="flex flex-col space-y-2">
      <ReactSortable
        list={inputValue ?? []}
        setList={handleUpdateOnboardingOptionsOnSort ?? (() => {})}
        animation={150}
        className={"space-y-2"}
        handle={".dragger-handle"}
      >
        {inputValue?.map((_, i) => {
          return (
            <ObjectPreview
              key={`OBJECT_PREVIEW_${i}`}
              property={property}
              canRemoveField={documentThatCanBeRemoved.includes(inputValue?.[i]?.id)}
              isRootProperty={undefined}
              stepIndex={stepIndex}
              fields={fields}
              object={inputValue?.[i]}
              objectIndex={i}
              objectName={t(objectName, objectName)}
              lastAddedObjectIndex={lastAddedObjectIndex}
              setEditObjectIndex={() => {}}
              clearLastAddedObjectIndex={() => setLastAddedObjectIndex(undefined)}
              clearEditObjectIndex={() => {}}
              removeObject={() => removeObject(i)}
              options={[]}
              small={rest?.smallPreview}
            />
          );
        })}
      </ReactSortable>
      {canShowAddButton && (
        <AntButton
          type="link"
          className="!text-left"
          icon={<Icon icon="add" size="md" className="mr-2" />}
          onClick={addObject}
          block
        >
          {t(`${I18N_BASE_PATH}.components.addSubmissionFormat`, "Adicionar forma de envio")}
        </AntButton>
      )}
    </div>
  );
};

export default ObjectListEditorByDefaultFields;
