import { renderCustomizableComponents, renderCustomizableElements } from "@components/ElementEditor";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import { useOnboardingStepsContext } from "@contexts/OnboardingSteps";
import { Logger } from "@utils/logging";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useStoreActions, useStoreState } from "react-flow-renderer";

import defaultValues from "./defaultValues";

export const SidebarEditorContext = createContext(defaultValues);
export const useSidebarEditorContext = () => useContext(SidebarEditorContext);

/**
 * Controls the sidebar states and currently selected steps or elements.
 * */
export const SidebarEditorProvider = ({ children }) => {
  const nodes = useStoreState((store) => store.nodes);
  const selectedElements = useStoreState((store) => store.selectedElements);
  const setSelectedElements = useStoreActions((actions) => actions.setSelectedElements);

  const { onboardingSteps } = useOnboardingStepsContext();
  const { goTo } = useOnboardingStepsContext();
  const { onboardingLanguage } = useOnboardingOptionsContext();

  const [selectedCustomizableElementName, setSelectedCustomizableElementName] = useState();
  const [selectedCustomizableElementPath, setSelectedCustomizableElementPath] = useState();
  const [selectedCustomizableElement, setSelectedCustomizableElement] = useState();
  const [focusedCustomizableElement, setFocusedCustomizableElement] = useState();

  const [selectedStep, setSelectedStep] = useState();
  const [selectedStepIndex, setSelectedStepIndex] = useState();

  const [selectedError, setSelectedError] = useState();
  const [inputTypeSelected, setInputTypeSelected] = useState();

  const customizableElements = useMemo(() => renderCustomizableElements(onboardingLanguage), [onboardingLanguage]);
  const customizableComponents = useMemo(() => renderCustomizableComponents(onboardingLanguage), [onboardingLanguage]);

  /** Removes all selected elements, makind the editing sidebar disappear */
  const unselectAllElements = useCallback(() => {
    setSelectedElements([]);
    setSelectedStep(undefined);
    setSelectedStepIndex(undefined);
    setSelectedCustomizableElementName(undefined);
    setSelectedCustomizableElement(undefined);
    setFocusedCustomizableElement(undefined);
    setSelectedError(undefined);
  }, [setSelectedElements]);

  /** Select a specific step so its sidebar appear for editing */
  const selectStep = useCallback(
    (stepName = "") => {
      let nodeToSelect = nodes?.find((node) => node?.id === stepName);
      if (nodeToSelect) {
        setSelectedElements([nodeToSelect]);
      } else {
        Logger.warn("Didn't find step to select:", stepName, "Nodes:", nodes);
      }
    },
    [setSelectedElements, nodes],
  );
  const unselectStep = useCallback(() => {
    unselectAllElements();
    setSelectedStep(undefined);
    setSelectedStepIndex(undefined);
  }, [unselectAllElements, setSelectedStep, setSelectedStepIndex]);

  // Whenever selected elements changed, find and select the step
  useEffect(() => {
    if (selectedElements?.length === 1 && selectedElements[0]) {
      let step = selectedElements[0].data?.step;
      if (step) {
        setSelectedStep(step);
        setSelectedStepIndex(onboardingSteps?.findIndex((_step) => _step.name === step.name));
        if (step.isEnabled) goTo(step.name);
      } else {
        setSelectedStep(undefined);
        setSelectedStepIndex(undefined);
      }
    } else {
      setSelectedStep(undefined);
      setSelectedStepIndex(undefined);
    }
  }, [selectedElements]);

  /** Select a specific element */
  const selectCustomizableElement = (customizableElementName = "", customizableElementPath = null) => {
    let parsedElementName = customizableElementName.split("_");
    const isStep = parsedElementName[0] === "STEP";
    const isComponent = parsedElementName[0] === "COMPONENT";
    if (isStep) {
      let specialStepName = "",
        errorName = "",
        stepName = parsedElementName.filter((_, i) => i > 0 && i < parsedElementName.length - 1);

      if (stepName?.includes("ERROR") && stepName.indexOf("ERROR") === stepName.length - 2) {
        errorName = stepName[stepName.length - 1];
        stepName = stepName.splice(0, stepName.length - 2)?.join("_");
      } else {
        stepName = stepName?.join("_");
      }

      // Special cases that require specific customizableElements
      if (stepName?.includes("PREVIEW")) {
        specialStepName = "PREVIEW";
      } else if (stepName?.includes("QSA_NUMBER")) {
        specialStepName = "NUMBER";
      } else if (stepName?.includes("QSA_CONTACT_INFO")) {
        specialStepName = "CONTACT_INFO";
      } else if (stepName?.includes("QSA_CONTACT")) {
        specialStepName = "CONTACT";
      }
      if (specialStepName) {
        stepName = stepName.replace(`_${specialStepName}`, "");
        specialStepName += "_";
      }

      customizableElementName = `${parsedElementName[0]}_${specialStepName}${errorName && "ERROR_"}${
        parsedElementName[parsedElementName.length - 1]
      }`;

      setSelectedError(errorName);
      selectStep(stepName);
    } else if (isComponent) {
      setSelectedCustomizableElementPath(customizableElementPath);
    } else {
      setSelectedError(undefined);
      unselectStep();
    }

    const customizables = isComponent ? customizableComponents : customizableElements;

    if (customizables[customizableElementName]) {
      setSelectedCustomizableElementName(customizableElementName);
      setSelectedCustomizableElement(customizables[customizableElementName]);
      setFocusedCustomizableElement(customizableElementName);
    } else {
      Logger.warn("Tried to find unmapped customizableElement:", customizableElementName);
      setSelectedCustomizableElementName(undefined);
      setSelectedCustomizableElement(undefined);
      setFocusedCustomizableElement(undefined);
    }
  };

  return (
    <SidebarEditorContext.Provider
      value={{
        unselectAllElements,
        selectCustomizableElement,
        selectStep,
        selectedCustomizableElementName,
        selectedCustomizableElementPath,
        selectedCustomizableElement,
        selectedStep,
        selectedStepIndex,
        selectedError,
        focusedCustomizableElement,
        setFocusedCustomizableElement,
        inputTypeSelected,
        setInputTypeSelected,
      }}
    >
      {children}
    </SidebarEditorContext.Provider>
  );
};
