import "./styles/main.less";
import "./i18n";

import { AnalyticsProvider } from "@contexts/Analytics";
import { APIProvider } from "@contexts/API";
import { AuthProvider } from "@hooks/useAuth";
import CustomAuthRouter from "@pages/Auth/CustomAuthRouter";
import SignInRouter from "@pages/Auth/SignIn/Router";
import UnauthorizedRouter from "@pages/Auth/Unauthorized/Router";
import EmailRouter from "@pages/Email/Router";
import OnboardingRouter from "@pages/Onboarding/Router";
import { createBrowserHistory } from "history";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { matchPath, Redirect, Route, Router, Switch } from "react-router-dom";

var pjson = require("../package.json");
const history = createBrowserHistory();

const routes = [{ path: "/" }];

history.listen(() => {
  // Router does not render new component when path change, so this programatically reloads the page whenever the history changes (new query params)
  // TODO: check for a better alternative
  window.location.reload();
});

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Switch>
        <Suspense fallback={<></>}>
          <AnalyticsProvider>
            <Route exact path={["/"]} render={(props) => <Redirect to={`/onboarding${props.location.search}`} />} />
            <Route
              exact
              path={["/onboarding"]}
              render={(props) => {
                return (
                  <CustomAuthRouter pathname="onboarding">
                    <AuthProvider>
                      <APIProvider>
                        <OnboardingRouter history={history} />
                      </APIProvider>
                    </AuthProvider>
                  </CustomAuthRouter>
                );
              }}
            />
            <Route
              exact
              path={["/email"]}
              render={(props) => {
                return (
                  <CustomAuthRouter pathname="email">
                    <AuthProvider>
                      <APIProvider>
                        <EmailRouter history={history} />
                      </APIProvider>
                    </AuthProvider>
                  </CustomAuthRouter>
                );
              }}
            />
            <Route exact path={["/auth/login"]}>
              <CustomAuthRouter pathname="auth/login">
                <AuthProvider>
                  <APIProvider>
                    <SignInRouter history={history} />
                  </APIProvider>
                </AuthProvider>
              </CustomAuthRouter>
            </Route>
            <Route exact path={["/auth/unauthorized"]}>
              <AuthProvider>
                <APIProvider>
                  <UnauthorizedRouter history={history} />
                </APIProvider>
              </AuthProvider>
            </Route>
          </AnalyticsProvider>
        </Suspense>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root"),
);
