import { LoaderProvider } from "@contexts/Loader";
import { ModalProvider } from "@contexts/Modal";
import { OnboardingOptionsProvider } from "@contexts/OnboardingOptions";
import { OnboardingOptionsFilesProvider } from "@contexts/OnboardingOptionsFiles";
import { OnboardingStepsProvider } from "@contexts/OnboardingSteps";
import { SavedColorsProvider } from "@contexts/SavedColors";
import HttpInterceptor from "@utils/auth/httpInterceptor";

const BuilderProviders = ({ children }) => {
  return (
    <OnboardingOptionsProvider>
      <OnboardingOptionsFilesProvider>
        <OnboardingStepsProvider>
          <LoaderProvider>
            <ModalProvider>
              <SavedColorsProvider>
                {children}
                <HttpInterceptor />
              </SavedColorsProvider>
            </ModalProvider>
          </LoaderProvider>
        </OnboardingStepsProvider>
      </OnboardingOptionsFilesProvider>
    </OnboardingOptionsProvider>
  );
};

export default BuilderProviders;
