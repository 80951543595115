import { Icon } from "@combateafraude/react";
import { useTemplateValidationsContext } from "@contexts/TemplateValidations";
import { Badge, Button as AntButton, Tooltip } from "antd";
import classNames from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const I18N_BASE_PATH = "src.components.templateValidationsNotifications";

/* List of onboarding template validations notifications  */
const TemplateValidationsNotifications = () => {
  const { t } = useTranslation();

  const {
    templateValidationsNotifications,
    isValidationListVisible,
    setIsValidationListVisible,
    hasToAnimateButton,
    setHasToAnimateValidationButton,
  } = useTemplateValidationsContext();

  const validationNotificationsTitle = useMemo(() => {
    if (templateValidationsNotifications?.length === 1) {
      return t(
        `${I18N_BASE_PATH}.states.validationNotificationsTitle.oneNotification`,
        "1 validação de template a corrigir",
      );
    }

    return t(
      `${I18N_BASE_PATH}.states.validationNotificationsTitle.multipleNotifications`,
      `${templateValidationsNotifications?.length} validações de template a corrigir`,
      { validationsCount: templateValidationsNotifications?.length },
    );
  }, [templateValidationsNotifications, t]);

  return (
    <div
      id="steps-validation-notifications"
      className={classNames(
        `fixed transition-all duration-300 z-30 max-h-screen top-20 ${
          isValidationListVisible ? "right-3 max-w-lg" : "right-6"
        } ${templateValidationsNotifications?.length > 0 ? "opacity-100" : "opacity-0 h-0 hidden pointer-events-none"}`,
        {
          "animate-heartbeat": hasToAnimateButton,
        },
      )}
      onMouseEnter={() => setHasToAnimateValidationButton(false)}
    >
      {!isValidationListVisible ? (
        <AntButton
          className="hover:opacity-80 transition-all duration-300"
          shape="circle"
          size="large"
          style={{
            backgroundColor: "#fbc048",
          }}
          title={validationNotificationsTitle}
        >
          <Badge
            style={{ top: "-4px" }}
            onClick={() => setIsValidationListVisible((old) => !old)}
            color="red"
            overflowCount={9}
            count={templateValidationsNotifications?.length}
          >
            <Icon className="-mt-1 validation-notifications-icon default" icon="alert-triangle" size="md" />
          </Badge>
        </AntButton>
      ) : (
        <div id="notifications-list">
          <ul className="bg-white p-5 rounded-md relative !mb-0 flex flex-col gap-2">
            <h3 className="pl-1 text-left font-semibold text-base !mb-0">
              {t(`${I18N_BASE_PATH}.components.heading`, "Validações de template pendentes")}
            </h3>
            {templateValidationsNotifications?.map((validation, index) => (
              <li
                key={index}
                className={classNames("flex rounded-md p-2 items-center border", {
                  "bg-red-300 border-red-400": validation?.type === "error",
                  "bg-yellow-200 border-yellow-300": validation?.type === "warning",
                })}
              >
                <Tooltip
                  title={t(
                    `general.templateValidations.${validation?.type === "error" ? "error" : "warning"}`,
                    validation?.type === "error" ? "Erro" : "Aviso",
                  )}
                >
                  <div>
                    <Icon
                      className={`mr-2 validation-notifications-icon ${validation?.type}`}
                      icon={validation?.type === "error" ? "close" : "alert-triangle"}
                      size="sm"
                    />
                  </div>
                </Tooltip>
                {validation?.message}
              </li>
            ))}
            <div className="absolute right-2 top-0">
              <AntButton
                className="hover:opacity-80 ease-in-out"
                style={{ background: "none", boxShadow: "none" }}
                size="small"
                shape="circle"
                type="text"
                icon={<Icon icon="close" size="sm" />}
                onClick={() => setIsValidationListVisible((old) => !old)}
              />
            </div>
          </ul>
        </div>
      )}
    </div>
  );
};

export default TemplateValidationsNotifications;
