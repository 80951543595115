import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-tomorrow";

import AceEditor from "react-ace";

const CodeEditor = ({ value, setValue, wrapLines, fontSize, isLoading = false }) => {
  return (
    <AceEditor
      placeholder=""
      mode="html"
      theme="tomorrow"
      width="100%"
      height="100%"
      value={value}
      readOnly={isLoading}
      onChange={setValue}
      setOptions={{
        highlightActiveLine: true,
        showLineNumbers: false,
        tabSize: 2,
        wrap: wrapLines,
        fontSize,
        scrollPastEnd: true,
      }}
    />
  );
};

export default CodeEditor;
