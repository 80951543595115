import "react-autocomplete-input/dist/bundle.css";

import TextInput from "react-autocomplete-input";

/* * An input that opens options for the user depending on what he/she types */
const AutoCompleteInput = ({
  // lib props
  defaultValue,
  options,
  componentType,
  spacer = "",
  trigger,
  // component props
  size = "medium",
  onChange,
  resetButton,
  extraPaddingOnIcon,
  ...rest
}) => {
  return (
    <>
      <TextInput
        Component={componentType}
        trigger={trigger}
        spacer={spacer}
        options={options}
        defaultValue={defaultValue}
        maxOptions={0}
        offsetY={18}
        onChange={(value) => onChange(value)}
        id="react-autocomplete-text-input"
        className={`focus:outline-none w-full rounded-lg text-sm border border-gray-300 ${
          extraPaddingOnIcon ? "pl-3 pr-7" : "px-3"
        } ${componentType === "textarea" ? "" : size === "medium" ? "!h-8" : "!h-6"}`}
        {...rest}
      />
      {resetButton && resetButton({ isAutoCompleteInput: true, autoCompleteInputSize: size })}
    </>
  );
};

export default AutoCompleteInput;
