import { Input } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Name = ({ onboardingData, setOnboardingData, setCanGoForward }) => {
  const { t } = useTranslation();

  useEffect(() => setCanGoForward(!!onboardingData?.name), [onboardingData]);

  return (
    <div className="w-full items-center justify-center" style={{ width: "563px" }}>
      <span>
        {t(
          `src.components.modal.bodies.templateCreateOnboardingBody.utils.templateCreateOnboardingData.name.title`,
          "Nome",
        )}
      </span>
      <Input
        className="w-full h-10 !text-sm"
        onChange={(e) => {
          setOnboardingData((old) => ({ ...old, name: e?.target?.value }));
        }}
        placeholder={t(
          `src.components.modal.bodies.templateCreateOnboardingBody.utils.templateCreateOnboardingData.name.placeholder`,
          "Digite o nome do template",
        )}
        maxLength={100}
      ></Input>
    </div>
  );
};

export default Name;
