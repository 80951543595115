/* eslint-disable prettier/prettier */
import Onboarding from "@package/steps";

const { defaultOnboardingVariables } = Onboarding;

const defaultValues = {
  modifiers: ["upper", "lower", "first-word", "title"],
  variables: defaultOnboardingVariables,
  setVariables: (newVariable = "") => {},
  preventUserTypingInvalidCharacters: ({ char }) => {},
};
Object.freeze(defaultValues);

export default defaultValues;
