const defaultValues = {
  /** Starts the loader */
  start: () => {},
  /** Stops the loader */
  stop: () => {},
  /** Is the loader active */
  isLoading: false,
  /** Loader component */
  Loader: null,
};
Object.freeze(defaultValues);

export default defaultValues;
