import { Button as AntButton } from "antd";
import isEqual from "lodash.isequal";
import { cloneElement, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ProgressSteps from "./ProgressSteps";
import { templateCreateOnboardingData } from "./utils";

/** Modal body to choose the country, language, model and onboarding theme */
const TemplateCreateOnboardingBody = ({ close, handleFinish }) => {
  const { t } = useTranslation();

  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoForward, setCanGoForward] = useState(true);
  const [nextStepButtonTitle, setNextStepButtonTitle] = useState("");
  const [currentStep, setCurrentStep] = useState("LANGUAGE");
  const [onboardingData, setOnboardingData] = useState({});

  const stepsKeys = useMemo(() => Object.keys(templateCreateOnboardingData), []);

  const currentStepIndex = useMemo(
    () =>
      stepsKeys?.map((step, index) => (isEqual(step, currentStep) ? index : null))?.filter((step) => step !== null)[0],
    [stepsKeys, currentStep],
  );

  const handleGoToNextStep = useCallback(() => {
    const nextStepIndex = currentStepIndex + 1;

    if (isEqual(nextStepIndex, stepsKeys?.length)) {
      return handleFinish(onboardingData) && close();
    }

    setCurrentStep(stepsKeys?.[nextStepIndex]);
  }, [currentStep, stepsKeys, onboardingData]);

  const handleGoToPreviousStep = useCallback(() => {
    const previousStepIndex = currentStepIndex - 1;

    setCurrentStep(stepsKeys?.[previousStepIndex]);
  }, [currentStep, stepsKeys]);

  useEffect(() => setCanGoBack(!isEqual(stepsKeys?.[0], currentStep)), [currentStep]);

  return (
    <div className="flex flex-col items-center">
      <ProgressSteps currentStep={currentStep} />
      <main
        className="flex flex-col items-center justify-center"
        style={{
          minHeight: "30rem",
        }}
      >
        <h3 className="text-3xl leading-9 font-black text-center text-black">
          {templateCreateOnboardingData?.[currentStep]?.title}
        </h3>
        <p className="font-normal text-base leading-5 text-center max-w-md pb-7">
          {templateCreateOnboardingData?.[currentStep]?.description}
        </p>
        {cloneElement(templateCreateOnboardingData?.[currentStep]?.component, {
          onboardingData,
          setOnboardingData,
          setCanGoForward,
          setNextStepButtonTitle,
        })}
      </main>
      <section className="flex flex-row gap-10 self-end">
        {!!canGoBack && (
          <AntButton onClick={handleGoToPreviousStep} type="text">
            {t("general.label.goBack", "Voltar")}
          </AntButton>
        )}
        <AntButton
          title={nextStepButtonTitle || ""}
          disabled={!canGoForward}
          onClick={handleGoToNextStep}
          type="primary"
        >
          {t("general.label.next", "Continuar")}
        </AntButton>
      </section>
    </div>
  );
};

export default TemplateCreateOnboardingBody;
