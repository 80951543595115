import { Icon, Tag } from "@combateafraude/react";
import Onboarding from "@package/steps";
import { removeKey } from "@utils/";
import { Logger } from "@utils/logging";
import { tagsInfo } from "@utils/tags";
import { onboardingPropsParserTranslator } from "@utils/translator";
import { Button as AntButton, Input, Popover, Switch, Tooltip } from "antd";
import Form from "antd/es/form/Form";
import classNames from "classnames";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import set from "lodash.set";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const { rules: onboardingRules } = Onboarding;
Logger.console("ALL RULES", onboardingPropsParserTranslator(onboardingRules));

const I18N_BASE_PATH = "src.components.rulesList";

/** Lists and edits the "Rules" attribute */
const RulesList = ({ inputValue, onInputChange }) => {
  const { t, i18n } = useTranslation();

  const [rulesTranslated, setRulesTranslated] = useState(onboardingRules);
  const [rules, setRules] = useState(
    Object.values(onboardingPropsParserTranslator(onboardingRules, i18n?.language)).map((rule) => ({
      ...rule,
      isEnabled: false,
    })),
  );

  useEffect(
    () => setRulesTranslated(onboardingPropsParserTranslator(onboardingRules, i18n?.language)),
    [i18n?.language],
  );

  useEffect(() => {
    if (inputValue) {
      setRules(
        Object.values(rulesTranslated).map((rule) => {
          let inputValueRule = inputValue.find((_rule) => rule?.id === _rule?.id);
          return {
            ...rule,
            ...inputValueRule,
            isEnabled: !!inputValueRule,
          };
        }),
      );
    }
  }, [inputValue]);

  const editRule = (index, property, value) => {
    let _rules = cloneDeep(rules);
    set(_rules[index], property, value);
    setRules(_rules);
  };

  useEffect(() => {
    let newRules = rules.filter((rule) => rule?.isEnabled).map((rule) => removeKey("isEnabled", rule));
    if (!isEqual(newRules, inputValue)) {
      onInputChange(newRules);
    }
  }, [rules]);

  return (
    <div className="flex flex-col border border-gray-300 bg-gray-300 rounded">
      {rules &&
        Object.values(rules)?.map((rule, i) => (
          <div
            key={t(rule?.name)}
            className={classNames(`px-4 py-3 flex items-center mb-px last:mb-0 transition-all bg-white text-gray-700`, {
              "opacity-70": !rule?.isEnabled,
            })}
          >
            <div className="flex flex-col w-full items-baseline">
              <span className="flex-1 font-bold text-sm">{t(rule?.name)}</span>
              {rule?.tags?.length > 0 && (
                <div className="flex space-x-1 transform scale-75 origin-left">
                  {rule?.tags?.map((tag) => (
                    <Tooltip title={t(tag?.description)} placement="right">
                      <Tag
                        key={`${rule?.id}_TAG_${tag?.name}`}
                        color={tagsInfo?.[tag?.name]?.color}
                        icon={tagsInfo?.[tag?.name]?.icon}
                      />
                    </Tooltip>
                  ))}
                </div>
              )}
            </div>
            <Popover
              key={`${rule?.id}_POPOVER`}
              placement="right"
              trigger="click"
              content={() => (
                <Form size="small" className="max-w-xs">
                  <span>{t(rule?.description)}</span>
                  <h1 className="mt-2 mb-1 text-lg font-bold">
                    {t(`${I18N_BASE_PATH}.components.setting`, "Configuração")}
                  </h1>
                  <Form.Item label={t("general.label.active", "Ativo")} className="!mb-2">
                    <Switch size="small" checked={rule?.isEnabled} onChange={(v) => editRule(i, "isEnabled", v)} />
                  </Form.Item>
                  {rule?.flow && (
                    <>
                      <h1 className="mt-2 mb-1 text-lg font-bold">
                        {t(`${I18N_BASE_PATH}.components.customization`, "Personalização")}
                      </h1>
                      <Form.Item label={("general.label.flow", "Fluxo")} className="!mb-2">
                        <Input
                          size="small"
                          value={t(rule?.flow)}
                          onChange={(e) => editRule(i, "flow", e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item label={("general.label.otherFlow", "Outro fluxo")} className="!mb-2">
                        <Input
                          size="small"
                          value={t(rule?.else_flow)}
                          onChange={(e) => editRule(i, "else_flow", e.target.value)}
                        />
                      </Form.Item>
                    </>
                  )}
                </Form>
              )}
            >
              <AntButton className="text-gray-700" type="text" shape="circle" icon={<Icon icon="more-vertical" />} />
            </Popover>
          </div>
        ))}
    </div>
  );
};

export default RulesList;
