import { OnboardingInput } from "@components/Inputs";
import UploadFieldsFromCSVFile from "@components/UploadFieldsFromCSVFile";
import { useAPIContext } from "@contexts/API";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import Onboarding from "@package/steps";
import { parseStep } from "@utils/onboarding";
import { onboardingPropsParserTranslator } from "@utils/translator";
import { Form } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const { steps } = Onboarding;

const CustomizableElementInput = ({ customizableName, customizablePath, customizable, step, stepIndex, errorName }) => {
  const { t } = useTranslation();
  const { featureFlags } = useAPIContext();

  const { onboardingLanguage } = useOnboardingOptionsContext();

  const [stepOptions, setStepOptions] = useState([]);
  const [stepErrors, setStepErrors] = useState({});

  useEffect(() => {
    if (step?.name) {
      let fullStep = steps[parseStep(step?.name)[0]];

      if (fullStep) {
        setStepOptions(
          fullStep?.fields?.filter((field) =>
            featureFlags?.isWorkflowBuilder ? !field.hideWhenWorkflowIsActive : true,
          ),
        );
        setStepErrors(fullStep?.errors);
      }
    }
  }, [step]);

  return (
    <>
      <Form.Item
        label={t(customizable?.label, customizable?.label)}
        tooltip={t(customizable?.tooltip, customizable?.tooltip)}
      >
        <OnboardingInput
          {...customizable}
          defaultValue={
            typeof customizable?.defaultValue === "string"
              ? onboardingPropsParserTranslator(customizable?.defaultValue, onboardingLanguage)
              : customizable?.defaultValue
          }
          customizableName={customizableName}
          customizablePath={customizablePath}
          stepIndex={stepIndex}
          options={customizable?.options || stepOptions}
          errors={stepErrors}
          errorName={errorName}
        />
      </Form.Item>
      {customizable?.allowImportCSV && (
        <UploadFieldsFromCSVFile stepIndex={stepIndex} objectFieldValueKey={customizable.property} />
      )}
    </>
  );
};

export default CustomizableElementInput;
