import { useAuth } from "@hooks/useAuth";
import { Redirect } from "react-router-dom";

import SignIn from "./SignIn";

const Router = () => {
  const { isUserAuthenticated } = useAuth();

  return !isUserAuthenticated() ? <SignIn /> : <Redirect exact from="/auth/login" to="/onboarding" />;
};

export default Router;
