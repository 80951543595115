import { shouldNotRenderErrorsInBusinessAddressWhenKYBTheme } from "./should_not_render_errors_in_business_address_when_kyb_theme";
import { shouldRenderDocumentAutomaticCaptureSettingsJustInBrazilianFlow } from "./should_render_document_automatic_capture_settings";
import { shouldRenderPreviewPDFSwitchWhenSendDocumentTypeStepIsUsedFlow } from "./should_render_preview_pdf_switch";
import { shouldRenderIfStepSendDocumentTypeIsUsedOnFlow } from "./step_custom-labels-dd-when-send-document-type";

export const customizableElementsEditorValidations = () => {
  return {
    shouldRenderIfStepSendDocumentTypeIsUsedOnFlow,
    shouldNotRenderErrorsInBusinessAddressWhenKYBTheme,
    shouldRenderPreviewPDFSwitchWhenSendDocumentTypeStepIsUsedFlow,
    shouldRenderDocumentAutomaticCaptureSettingsJustInBrazilianFlow,
  };
};
