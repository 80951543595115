import { FlowProvider } from "@contexts/Flow";
import { LoaderProvider } from "@contexts/Loader";
import { ModalProvider } from "@contexts/Modal";
import { OnboardingOptionsProvider } from "@contexts/OnboardingOptions";
import { OnboardingOptionsFilesProvider } from "@contexts/OnboardingOptionsFiles";
import { OnboardingPropsProvider } from "@contexts/OnboardingProps";
import { OnboardingStepsProvider } from "@contexts/OnboardingSteps";
import { OnboardingVariablesProvider } from "@contexts/OnboardingVariables";
import { SavedColorsProvider } from "@contexts/SavedColors";
import { SidebarProvider } from "@contexts/Sidebar";
import { SidebarEditorProvider } from "@contexts/SidebarEditor";
import { TemplateValidationsProvider } from "@contexts/TemplateValidations";
import HttpInterceptor from "@utils/auth/httpInterceptor";
import { ReactFlowProvider } from "react-flow-renderer";

const BuilderProviders = ({ children }) => {
  return (
    <ReactFlowProvider>
      <OnboardingPropsProvider>
        <OnboardingOptionsProvider>
          <OnboardingOptionsFilesProvider>
            <OnboardingStepsProvider>
              <OnboardingVariablesProvider>
                <LoaderProvider>
                  <ModalProvider>
                    <SavedColorsProvider>
                      <FlowProvider>
                        <SidebarProvider>
                          <SidebarEditorProvider>
                            <TemplateValidationsProvider>
                              {children}
                              <HttpInterceptor />
                            </TemplateValidationsProvider>
                          </SidebarEditorProvider>
                        </SidebarProvider>
                      </FlowProvider>
                    </SavedColorsProvider>
                  </ModalProvider>
                </LoaderProvider>
              </OnboardingVariablesProvider>
            </OnboardingStepsProvider>
          </OnboardingOptionsFilesProvider>
        </OnboardingOptionsProvider>
      </OnboardingPropsProvider>
    </ReactFlowProvider>
  );
};

export default BuilderProviders;
