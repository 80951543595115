import { onboardingTemplatesAvailableLanguages } from "@utils/internationalization";
import { Button as AntButton, Select } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const I18N_BASE_PATH = "src.components.modal.bodies.templateCreateOnboardingBody";

const TemplateCreateEmailBody = ({ close, handleFinish }) => {
  const { t } = useTranslation();

  const [language, setLanguage] = useState(null);

  return (
    <div className="flex flex-col items-center">
      <main
        className="flex flex-col items-center justify-center"
        style={{
          minHeight: "30rem",
        }}
      >
        <h3 className="text-3xl leading-9 font-black text-center text-black">
          {t("general.label.language", "Idioma")}
        </h3>
        <p className="font-normal text-base leading-5 text-center max-w-md pb-7">
          {t(
            `${I18N_BASE_PATH}.utils.templateCreateOnboardingData.language.description`,
            "Defina o idioma em que o template deve estar",
          )}
        </p>
        <div className="w-full flex items-center justify-center">
          <Select
            value={language}
            onChange={(e) => setLanguage(() => e)}
            className="w-96 !text-sm"
            placeholder={t(`${I18N_BASE_PATH}.steps.language.form.select.placeholder`, "Selecione um idioma")}
            size="large"
            // prop getPopupContainer for resolve conflict with modal
            getPopupContainer={(node) => node.parentNode}
          >
            {onboardingTemplatesAvailableLanguages?.map((language) => (
              <Select.Option className="!text-gray-700 w-full" key={language?.id} value={language?.id}>
                <img className="w-5 inline mr-2" src={language?.flag} alt={t(language?.name, language?.name)} />
                {t(language?.name, language?.name)}
              </Select.Option>
            ))}
          </Select>
        </div>
      </main>
      <section className="flex flex-row gap-10 self-end">
        <AntButton
          title={!language ? t(`${I18N_BASE_PATH}.steps.language.form.select.placeholder`, "Selecione um idioma") : ""}
          disabled={!language}
          onClick={() => handleFinish(language) && close()}
          type="primary"
        >
          {t("general.label.buttonCreateTemplate", "Criar template")}
        </AntButton>
      </section>
    </div>
  );
};

export default TemplateCreateEmailBody;
