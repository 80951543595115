import { createContext, useContext, useState } from "react";

import defaultValues from "./defaultValues";

export const ModalContext = createContext(defaultValues);

export const useModalContext = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [openModals, setOpenModals] = useState(defaultValues.openModals);

  return (
    <ModalContext.Provider
      value={{
        openModals,
        setOpenModals,
      }}
    >
      {openModals?.map((modal) => modal.component)}
      {children}
    </ModalContext.Provider>
  );
};
