import { Button as AntButton, Form, Input, message } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

const I18N_BASE_PATH = "src.components.modal.bodies.emailTemplatePropertiesBody";

/** Modal body to modify the email template properties */
const EmailTemplatePropertiesBody = ({
  close,
  handleFinish,
  defaultValues = { name: undefined, description: undefined, subjectPart: undefined },
}) => {
  const { t } = useTranslation();

  // Enable the save button only after something is changed
  const [haveChanged, setHaveChanged] = useState(false);
  const onChange = () => setHaveChanged(true);

  const [isLoading, setIsLoading] = useState(false);

  const initialTemplateValues = useMemo(() => {
    return {
      ...defaultValues,
      // If the "subjectPart" attribute has no value, overwrite it with the default value: "Onboarding"
      subjectPart:
        defaultValues?.subjectPart ?? t(`${I18N_BASE_PATH}.states.initialTemplateValues.subjectPart`, "Onboarding"),
    };
  }, [defaultValues, t]);

  return (
    <Form
      layout="vertical"
      onFinish={async (values) => {
        setIsLoading(true);
        let res = await handleFinish({ ...values });
        if (res) {
          close();
          message.success(
            t("general.templatePropertiesBody.templateSuccessfullyUpdated", "Template atualizado com sucesso"),
          );
        } else {
          message.error(
            t("general.templatePropertiesBody.errorOnUpdateTemplate", "Não foi possível atualizar template"),
          );
        }
        setIsLoading(false);
      }}
      initialValues={initialTemplateValues}
    >
      <Form.Item
        label={t("general.templatePropertiesBody.title", "Título")}
        name="name"
        className="font-bold pt-1"
        required
      >
        <Input onChange={onChange} />
      </Form.Item>
      <Form.Item
        label={t("general.templatePropertiesBody.description", "Descrição")}
        name="description"
        className="font-bold"
      >
        <TextArea rows="3" onChange={onChange} />
      </Form.Item>
      <Form.Item
        label={t(`${I18N_BASE_PATH}.components.subjectPart`, "Assunto do email")}
        name="subjectPart"
        className="font-bold pt-1"
      >
        <Input onChange={onChange} />
      </Form.Item>
      <div className="flex justify-end items-center w-full pt-4 pb-1">
        <AntButton className="!mr-2" type="text" onClick={close} htmlType="button">
          {t("general.label.cancel", "Cancelar")}
        </AntButton>
        <AntButton type="primary" htmlType="submit" disabled={!haveChanged} loading={isLoading}>
          {t("general.label.save", "Salvar")}
        </AntButton>
      </div>
    </Form>
  );
};

export default EmailTemplatePropertiesBody;
